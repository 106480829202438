import React, { useEffect, useState } from 'react';
import { AccountFee } from '../../helpers/types';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserAccountFee } from '../../api/gateway';

const Fees = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [fees, setFees] = useState<AccountFee[]>();

  const handleGetUserAccountFee = async () => {
    await getAccessTokenSilently().then(async (token) => {
      await getUserAccountFee(token).then(({ data }) => {
        setFees(data.list);
      });
    });
  };

  useEffect(() => {
    handleGetUserAccountFee();
  }, []);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      {fees?.map((v) => {
        return (
          <div key={v.id} className="settings__container__fee">
            <h3>{capitalizeFirstLetter(v.product_name)}</h3>
            <div className='settings__container__line'></div>

            <div className="settings__container__info">
              <label>Percentage fee</label>
              <span>{v.percentage_fee}%</span>
            </div>
            <div className="settings__container__info">
              <label>Fixed fee</label>
              <span>{v.fixed_fee}%</span>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Fees;


