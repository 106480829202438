import React, { FC, useState, useEffect } from 'react';
import IconStatusCritical from '../../assets/status-critical.png'

import './index.scss';

interface Values {
  id: string | number;
  value: string;
}

type Props = {
  id: string;
  value: string;
  label: string;
  onChange: (value: string) => void;
  placeholder?: string;
  error?: boolean;
  required?: boolean;
  errorMessage?: string;
  options: Values[];
  disabled?: boolean;
};

export const Select: FC<Props> = (props: Props) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const isError = props.error ? 'error' : '';

  return (
    <div className="select">
      <label htmlFor={props.id}>{props.label}</label>
      {props.placeholder && (
        <span>{props.placeholder}</span>
      )}
      <select
        disabled={props.disabled}
        id={props.id}
        value={value}
        className={isError}
        onChange={({ target }) => {
          setValue(target.value);
          props.onChange(target.value);
        }}
      >
        {props.options.map((v) => {
          return <option key={v.id}>{v.value}</option>;
        })}
      </select>
      {props.error && (
        <div className='select__error'>
          <img src={IconStatusCritical} alt="icon status error" />
          <span>{props.errorMessage}</span>
        </div>
      )}
    </div >
  );
};
