import React, { useState } from 'react'
import BasicTemplate from '../BasicTemplate'
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';
import Countries from '../../data/countries';
import { useAuth0 } from '@auth0/auth0-react';
import Banner from '../../components/Banner';

import './index.scss'
import { postSubAccount } from '../../api/gateway';
import { TSubAccountCreate } from '../../helpers/types';

interface registerInterface {
  email: string;
  fullName: string;
  country: string;
  address: string;
  city: string;
  postalCode: string;
  phone: string;
  localIdType: string;
  localIdNumber: string;
  taxIdType: string;
  taxIdNumber: string;
}

const initialRegisterState: registerInterface = {
  email: '',
  fullName: '',
  country: '',
  address: '',
  city: '',
  postalCode: '',
  phone: '',
  localIdType: '',
  localIdNumber: '',
  taxIdType: '',
  taxIdNumber: '',
}

interface banner {
  show: boolean;
  type: 'neutral' | 'warning' | 'error' | 'success';
  title: string;
  text: string;
}

const initialShowBanner: banner = { show: false, type: 'success', title: '', text: '' }

export default function RegisterSubAccount () {
  const { getAccessTokenSilently } = useAuth0();
  const [register, setRegister] = useState<registerInterface>(initialRegisterState);
  const [showBanner, setShowBanner] = useState<banner>(initialShowBanner);

  const updateValue = (key: string, value: string | number | undefined) => {
    setRegister((bank) => ({
      ...bank,
      [key]: value,
    }));
  };

  const handleTaxIdType = () => {
    let taxIdTypes = []
    switch (register.country) {
      case 'Brazil':
        taxIdTypes = [
          { value: 'CPF', id: 'cpf' },
          { value: 'CNPJ', id: 'cnpj' },
        ];
        break
      case 'United States':
        taxIdTypes = [
          { value: 'SSN', id: 'ssn' },
          { value: 'EIN', id: 'ein' },
        ];
        break
      case 'Argentina':
        taxIdTypes = [
          { value: 'CUIT', id: 'cuit' },
        ];
        break
      case 'Mexico':
        taxIdTypes = [
          { value: 'RFC', id: 'rfc' },
        ];
        break
      default:
        taxIdTypes = [
          { value: 'Other', id: 'other' }
        ];
    }

    taxIdTypes.unshift({ value: 'Choose one', id: '' })

    return taxIdTypes
  }

  const handleTaxIdNumberMask = () => {
    switch (register.taxIdType.toLowerCase()) {
      case 'cpf':
        return '999.999.999-99'
      case 'cnpj':
        return '99.999.999/9999-99'
      case 'ssn':
        return '999-99-9999'
      case 'ein':
        return '99-9999999'
      case 'cuit':
        return '99-99999999-9'
      case 'rfc':
        return '****999999***'
      default:
        return ''
    }
  }

  const createSubAccount = () => {
    const payload: TSubAccountCreate = {
      name: register.fullName,
      email: register.email,
      country: register.country,
      city: register.city,
      address: register.address,
      tax_id_type: register.taxIdType,
      tax_id_number: register.taxIdNumber.replaceAll(/[^\w\s]/gi, ''),
      local_id_type: register.localIdType,
      local_id_number: register.localIdNumber,
      phone: register.phone,
      post_code: register.postalCode,
    }

    getAccessTokenSilently().then(async (token) => {
      await postSubAccount(token, payload).then(() => {
        setShowBanner({
          show: true,
          type: 'success',
          title: 'Sub Account created',
          text: 'The sub account was successfully created.'
        })
        setRegister(initialRegisterState)
      }).catch(() => {
        setShowBanner({
          show: true,
          type: 'error',
          title: 'Error',
          text: 'An error occurred while creating the sub account.'
        })
      })
    })
  }

  return (
    <BasicTemplate>
      <section className='registerSubAcc'>
        <div className='registerSubAcc__form'>
          <h1>Register Sub Account</h1>
          <Input
            type="text"
            id="email"
            label={'Type sub account e-mail'}
            message="Insert the recipient's e-mail address."
            value={register.email}
            onChange={(value) => updateValue('email', value)}
            placeholder='email@provider.com'
          />
          <Input
            type="text"
            id="fullName"
            label={'Full name'}
            value={register.fullName}
            onChange={(value) => updateValue('fullName', value)}
          />
          <Select
            id='country'
            value={register.country}
            label='Country'
            options={Countries}
            onChange={(value) => updateValue('country', value)}
          />
          <Input
            type="text"
            id="address"
            label={'Address'}
            value={register.address}
            onChange={(value) => updateValue('address', value)}
          />
          <Input
            type="text"
            id="city"
            label={'City'}
            value={register.city}
            onChange={(value) => updateValue('city', value)}
          />
          <Input
            type="number"
            id="postalCode"
            label={'Postal Code'}
            value={register.postalCode}
            onChange={(value) => updateValue('postalCode', value)}
          />
          <Input
            type="text"
            id="phone"
            label={'Phone'}
            value={register.phone}
            onChange={(value) => updateValue('phone', value)}
          />
          <Select
            id='localIdType'
            value={register.localIdType}
            label='Local ID Type'
            placeholder='Choose which ID Type your would like to inform.'
            options={[
              { value: `Driver's License`, id: 'drivers_license' },
              { value: 'Passport', id: 'passport' },
              { value: 'Personal ID', id: 'personal_id' },
            ]}
            onChange={(value) => updateValue('localIdType', value)}
          />
          <Input
            type="text"
            id="text"
            label={'Local ID Number'}
            value={register.localIdNumber}
            onChange={(value) => updateValue('localIdNumber', value)}
          />
          <Select
            disabled={!register.country}
            id='taxIdType'
            value={register.taxIdType}
            label='Tax ID Type'
            placeholder='Choose which Tax ID Type your would like to inform.'
            options={handleTaxIdType()}
            onChange={(value) => updateValue('taxIdType', value)}
          />
          <Input
            type="text"
            id="text"
            label={'Tax ID Number'}
            message="Insert the recipient's tax ID number"
            mask={handleTaxIdNumberMask()}
            value={register.taxIdNumber}
            onChange={(value) => updateValue('taxIdNumber', value)}
          />

          <button
            disabled={!register.taxIdType}
            onClick={createSubAccount}
            className='registerSubAcc__form--button'>Register Sub Account</button>
          <Banner
            type={showBanner.type}
            title={showBanner.title}
            text={showBanner.text}
            show={showBanner.show}
            onClose={() => setShowBanner(initialShowBanner)}
          />
        </div>
        <div className='registerSubAcc__info'>
          <div className='registerSubAcc__info__card'>
            <h2 className='registerSubAcc__info__card--title'>Onboarding</h2>
            <p className='registerSubAcc__info__card--info'>{"Inform the recipient's e-mail address"}</p>
            <span className='registerSubAcc__info__card--text'>Beta is required by law to perform KYC in all recipients before processing transactions</span>
          </div>
        </div>
      </section>
    </BasicTemplate>
  )
}