import React, { useState, useEffect, useRef } from 'react'
import { UserAccountDocumentType, UploadFile, SnackbarType } from '../../helpers/types';
import { getUserAccountDocumentType, postUserAccountDocument } from '../../api/gateway';
import RemoveFileSVG from '../../assets/remove-file-black.png'
import { useAuth0 } from '@auth0/auth0-react';

import './UploadDocuments.scss'

type FileType = {
  file: File;
  uploaded: boolean;
}

type Props = {
  typeAccount: string;
  handleSnackBar: (value: SnackbarType) => void;
}

const DOCUMENT_TYPE = "Other relevant documents"

const UploadDocuments = ({ typeAccount, handleSnackBar }: Props) => {
  const { user } = useAuth0();

  const [fileTypeID, setFileTypeID] = useState<string>('')
  const [files, setFiles] = useState<FileType[]>([]);
  const [uploadProcess, setUploadProcess] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>();

  const addFile = (f: File) => {
    if (!f) return
    setFiles((old) => [...old, { file: f, uploaded: false }])
  }

  const removeFile = (f: File) => {
    setFiles(files.filter((v) => v.file !== f));
  };

  const setFileUploaded = (f: File) => {
    const filesToUpdate = [...files]
    filesToUpdate.forEach(v => {
      if (v.file.name === f.name) {
        v.uploaded = true
      }
    })

    setFiles(filesToUpdate)
  }

  useEffect(() => {
    if (!typeAccount) return
    (async () => {
      await getUserAccountDocumentType().then(({ data }) => {
        const { list } = data;
        const id = list.find((v: UserAccountDocumentType) => v.account_type_name === typeAccount && v.name === DOCUMENT_TYPE).id
        setFileTypeID(id)
      });
    })();
  }, [typeAccount]);

  const handlerSubmit = async () => {
    if (!user) return
    const { sub } = user
    if (!sub) return

    const totalToUpload = files.length
    let filesUploaded = 0

    files.forEach(async (v) => {
      if (v.uploaded) return
      setUploadProcess(true)

      const reader = new FileReader();
      reader.readAsDataURL(v.file);

      reader.onload = async () => {
        if (reader.result) {
          const extension = v.file.name.split('.');

          const payload: UploadFile = {
            auth0_id: sub,
            file: reader.result.toString().split(';base64,')[1],
            file_extension: extension[extension.length - 1],
            document_type_id: fileTypeID,
          };

          await postUserAccountDocument(payload);
          filesUploaded += 1
          setFileUploaded(v.file)
        }
      };
    });

    const interval = setInterval(() => {
      if (filesUploaded == totalToUpload) {
        clearInterval(interval)
        setUploadProcess(false)
        handleSnackBar({
          open: true,
          title: 'Success to upload new files',
          severity: 'success',
        })
      }
    }, 1000)
  };

  const existDocumentToUpload = () => {
    return files.filter((v) => v.uploaded === false).length > 0
  }

  return (
    <div className='uploadDocuments'>
      <div className='uploadDocuments__upload'>
        <div className='uploadDocuments__upload--icon'></div>
        <label htmlFor="file">Upload files</label>
        <input
          ref={(element) =>
            (inputRef.current = element as HTMLInputElement)
          }
          type="file"
          name=""
          id="file"
          accept='image/png,
          image/jpeg,
          .pdf'
          onChange={({ target }) => {
            if (!target.files) return
            addFile(target.files[0])
          }} />
      </div>
      <ul>
        {files && files.map((f, index) => {
          return (
            <li key={index}>
              <div>
                <span style={f.uploaded ? { color: '#34C759' } : {}}>{f.file.name}</span>
                <button onClick={() => removeFile(f.file)} disabled={uploadProcess || f.uploaded}>
                  <img src={RemoveFileSVG} alt="remove file" />
                </button>
              </div>
            </li>
          )
        })}
      </ul>
      <div className='uploadDocuments__action'>
        <button onClick={handlerSubmit} disabled={!existDocumentToUpload()}>Send documents</button>
      </div>
    </div>
  )
}

export default UploadDocuments;