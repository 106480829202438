import React, { FC, useEffect, useState } from 'react'
import { Select } from '../../components/Select'
import Countries from '../../data/countries';
import { Input } from '../../components/Input';
import { useAuth0 } from '@auth0/auth0-react';
import { getAssetByType, getNetworkByAssetSymbol, postSubAccountBankExternal, postSubAccountWalletAddressExternal } from '../../api/gateway';
import { TAsset, TSubAccountBankAccountExternal, TSubAccountWalletAddressExternal } from '../../helpers/types';

import './RegisterBank.scss'

interface registerInterface {
  sourceAsset: string;
  network: string;
  country: string;
  bankCode: string;
  bankName: string;
  branch: string;
  city: string;
  state: string;
  instantPaymentType: string;
  instantPayment: string;
  bankAccount: string;
  name: string;
  walletAddress: string;
}

const initialRegisterState: registerInterface = {
  sourceAsset: '',
  network: '',
  country: '',
  bankCode: '',
  bankName: '',
  branch: '',
  city: '',
  state: '',
  instantPaymentType: '',
  instantPayment: '',
  name: '',
  walletAddress: '',
  bankAccount: '',
}

interface Values {
  id: string | number;
  value: string;
}

type Props = {
  subAccountId: string;
  onContinue (): void;
}

const RegisterBank: FC<Props> = ({ subAccountId, onContinue }: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [register, setRegister] = useState<registerInterface>(initialRegisterState);
  const [assetsOption, setAssetsOption] = useState<Values[]>([]);
  const [assets, setAssets] = useState<TAsset[]>([]);
  const [networks, setNetworks] = useState<Values[]>([]);

  const updateValue = (key: string, value: string | number | undefined) => {
    setRegister((bank) => ({
      ...bank,
      [key]: value,
    }));
  };

  const instantPaymentTypes = () => {
    const paymentTypes = [{ id: '', value: 'Choose one' }]
    switch (register.sourceAsset) {
      case 'BRL':
        paymentTypes.push({ id: 'PIX', value: 'PIX' })
        break
      case 'MXN':
        paymentTypes.push({ id: 'SPEI', value: 'SPEI' })
        break
      case 'ARS':
        paymentTypes.push({ id: 'CBU', value: 'CBU' }, { id: 'CVU', value: 'CVU' }, { id: 'ALIAS', value: 'ALIAS' })
        break
      default:
        break
    }
    return paymentTypes
  }

  const handleGetAssetByType = () => {
    getAccessTokenSilently().then(async (token) => {
      await getAssetByType(token, '').then(({ data }) => {
        const response = []
        for (let i = 0; i < data.assets.length; i++) {
          if (data.assets[i].type === 'fiat' || data.assets[i].type === 'crypto') {
            response.push({
              id: data.assets[i].symbol,
              value: data.assets[i].symbol,
            })
          }
        }
        response.unshift({ id: '', value: 'Choose one currency' })
        setAssetsOption(response)
        setAssets(data.assets)
      })
    })
  }

  const handleGetNetworkByAsset = (asset: string) => {
    getAccessTokenSilently().then(async (token) => {
      getNetworkByAssetSymbol(token, asset).then(({ data }) => {
        const response = data.networks.map((network: { id: string, name: string }) => {
          return {
            id: network.id,
            value: network.name
          }
        })
        response.unshift({ id: '', value: 'Choose one network' })
        setNetworks(response)
      })
    })
  }

  useEffect(() => {
    handleGetAssetByType()
  }, [])

  const isFiat = () => assets.find((asset) => asset.symbol === register.sourceAsset)?.type === 'fiat'

  const handleCreateAccount = () => {
    const accountBank: TSubAccountBankAccountExternal = {
      bank_name: register.bankName,
      bank_code: register.bankCode,
      branch: register.branch,
      account: register.bankAccount,
      instant_payment_type: register.instantPaymentType,
      instant_payment: register.instantPayment,
      country: register.country,
      asset: register.sourceAsset,
      city: register.city,
      state: register.state,
    }

    const walletAddress: TSubAccountWalletAddressExternal = {
      name: register.name,
      address: register.walletAddress,
      asset: register.sourceAsset,
      network: register.network,
    }

    getAccessTokenSilently().then(async (token) => {
      if (isFiat()) {
        await postSubAccountBankExternal(token, subAccountId, accountBank).then(() => {
          onContinue()
        })
      } else {
        await postSubAccountWalletAddressExternal(token, subAccountId, walletAddress).then(() => {
          onContinue()
        })
      }
    })
  }

  return (
    <section className='registerBank'>
      <Select
        id='sourceAsset'
        value={register.sourceAsset}
        label='Source Asset'
        placeholder='Choose the currency you would like to deposit'
        options={assetsOption}
        onChange={(value) => {
          setNetworks([])
          updateValue('sourceAsset', value)
          const isFiat = assets.find((asset) => asset.symbol === value)?.type === 'fiat'
          if (!isFiat) handleGetNetworkByAsset(value)
        }}
      />
      {!isFiat() && (
        <Select
          id='network'
          value={register.network}
          label='Network'
          placeholder='Choose the preferred currency for the deposit.'
          options={networks}
          onChange={(value) => updateValue('network', value)}
        />
      )}
      {isFiat() ? (
        <>
          <Select
            id='country'
            value={register.country}
            label='Country'
            options={Countries}
            onChange={(value) => updateValue('country', value)}
          />
          <Input
            type="text"
            id="bankCode"
            label={'Bank Code'}
            message='Inform the bank code, if applicable.'
            value={register.bankCode}
            onChange={(value) => updateValue('bankCode', value)}
          />
          <Input
            type="text"
            id="bankName"
            label={'Bank Name'}
            value={register.bankName}
            onChange={(value) => updateValue('bankName', value)}
          />
          <Input
            type="text"
            id="branch"
            label={'Branch'}
            message='Inform the bank branch, if applicable.'
            value={register.branch}
            onChange={(value) => updateValue('branch', value)}
          />
          <Input
            type="text"
            id="bankAccount"
            label={'Bank Account'}
            value={register.bankAccount}
            onChange={(value) => updateValue('bankAccount', value)}
          />
          <Input
            type="text"
            id="city"
            label={'City'}
            value={register.city}
            onChange={(value) => updateValue('city', value)}
          />
          <Input
            type="text"
            id="state"
            label={'State'}
            value={register.state}
            onChange={(value) => updateValue('state', value)}
          />
          <Select
            id='network'
            value={register.instantPaymentType}
            label='Instant Payment Type'
            placeholder="Choose an instant payment type, If supported by recipient's bank."
            options={instantPaymentTypes()}
            onChange={(value) => updateValue('instantPaymentType', value)}
          />
          <Input
            type="text"
            id="state"
            label={'Instant Payment'}
            message='Insert the instant payment hash.'
            value={register.instantPayment}
            onChange={(value) => updateValue('instantPayment', value)}
          />
        </>
      ) : (
        <>
          <Input
            type="text"
            id="address"
            label={'Wallet address'}
            value={register.walletAddress}
            onChange={(value) => updateValue('walletAddress', value)}
          />
          <Input
            type="text"
            id="name"
            label={'Name'}
            value={register.name}
            onChange={(value) => updateValue('name', value)}
          />
        </>
      )}
      <button
        onClick={handleCreateAccount}
        className='registerBank__button'
      >Register Bank Details</button>
    </section>
  )
}

export default RegisterBank