import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

type Props = {
  route: string;
  title: string
};

const ButtonNavigation:FC<Props> = ({route, title}: Props) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        navigate(route);
      }}
      variant="outlined"
      color="primary"
      size="small"
    >
      {title}
    </Button>
  );
};

export default ButtonNavigation;
