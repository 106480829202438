import React, { FC, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';

import './Modal.scss';

type Props = {
  onContinue: () => void;
  openModal: boolean;
  onChange: (value: boolean) => void;
};

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: ${window.screen.width <= 520 ? '#000': 'none'};
  }
`;

export const Modal: FC<Props> = ({ onContinue, onChange, openModal }: Props) => {
  const [open, setOpen] = useState(openModal);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up(520));

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  return (
    <StyledDialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: { borderRadius: 20, width: 375, height: 310 },
      }}
    >
      <div className="modal">
        <p>
          You still can operate with Beta even If you don&apos;t upload the KYC
          documents at this stage. However, your lifetime operating limits will
          be set to USD 500,00 until we have all your personal and/or corporate
          details.
        </p>
        <div className="modal__actions">
          <button
            onClick={() => {
              setOpen(false);
              onChange(false)
            }}
            className="modal__actions--return"
          >
            Return
          </button>
          <button onClick={onContinue} className="modal__actions--continue">
            Continue
          </button>
        </div>
      </div>
    </StyledDialog>
  );
};
