import { useAuth0 } from '@auth0/auth0-react';
import React, { FC } from 'react';
import { UserAccountFull } from '../../helpers/types';
import { patchUserAccount } from '../../api/gateway';

type Props = {
  userAccount: UserAccountFull;
  handleSetUserAccount: (value: UserAccountFull) => void;
};

const Settlement: FC<Props> = ({ userAccount, handleSetUserAccount }: Props) => {
  const { getAccessTokenSilently } = useAuth0();

  const handlePatchUserAccount = async (key: string, value: string, resetSettlementPreference: boolean) => {
    const { id } = userAccount;
    if (!id) return;
    let payload = { ...userAccount, [key]: value };

    if (resetSettlementPreference) {
      payload = { ...payload, settlement_preference: '' };
    }

    await getAccessTokenSilently().then(async (token) => {
      await patchUserAccount(token, id, payload).then(({ data }) => {
        handleSetUserAccount(data);
      });
    });
  };

  return (
    <>
      <div className="settings__container__option">
        <div
          className="settings__container__option--asset"
          style={{ paddingBottom: 20 }}
        >
          <label htmlFor="settlement-asset">Settlement Asset</label>
          <select
            id="settlement-asset"
            value={userAccount.settlement_asset}
            onChange={({ target }) => {
              if (!target.value) return;
              handlePatchUserAccount('settlement_asset', target.value, true);
            }}
          >
            {[
              { value: '', name: 'Asset' },
              { value: 'USDC', name: 'USDC' },
              { value: 'USDT', name: 'USDT' },
              { value: 'BRL', name: 'BRL' },
              { value: 'USD', name: 'USD' },
            ].map((v, index) => {
              return (
                <option key={index} value={v.value}>
                  {v.name}
                </option>
              );
            })}
          </select>
        </div>
        <label htmlFor="settlement" style={{ paddingBottom: 10 }}>
          Settlement preference
        </label>
        {userAccount.settlement_asset === 'BRL' && (
          <div className="settings__container__option--radio">
            <input
              value={'instant payment'}
              checked={userAccount.settlement_preference === 'instant payment'}
              type="radio"
              id="settlement-preference"
              name="settlement-preference"
              onChange={() => {
                handlePatchUserAccount('settlement_preference', 'instant payment', false);
              }}
            />
            <label htmlFor="Settlement">
              Instant Payment <span>(~2 hours)</span>
            </label>
          </div>
        )}
        {['BRL', 'USD'].find(v => v === userAccount.settlement_asset) && (
          <div className="settings__container__option--radio">
            <input
              value={'wire transfer'}
              checked={userAccount.settlement_preference === 'wire transfer'}
              type="radio"
              id="settlement-preference"
              name="settlement-preference"
              onChange={() => {
                handlePatchUserAccount('settlement_preference', 'wire transfer', false);
              }}
            />
            <label htmlFor="Settlement">
              Wire Transfer <span>(D+2)</span>
            </label>
          </div>
        )}
        {['USDT', 'USDC'].find(v => v === userAccount.settlement_asset) && (
          <div className="settings__container__option--radio">
            <input
              value={'stablecoins'}
              checked={userAccount.settlement_preference === 'stablecoins'}
              type="radio"
              id="settlement-preference"
              name="settlement-preference"
              onChange={() => {
                handlePatchUserAccount('settlement_preference', 'stablecoins', false);
              }}
            />
            <label htmlFor="Settlement">
              Stablecoins <span>(~2 hours)</span>
            </label>
          </div>
        )}
      </div>
      <div className="settings__container__option">
        <div className="settings__container__option--asset">
          <label htmlFor="settlement-frequency">Settlement frequency</label>
          <select
            id="settlement-frequency"
            value={userAccount?.settlement_frequency}
            onChange={({ target }) => {
              if (!target.value) return;
              handlePatchUserAccount('settlement_frequency', target.value, false);
            }}
          >
            <option value="">Frequency</option>
            <option value="weekly settlement">Weekly Settlement</option>
            {/* <option value="weekly basis">Weekly basis</option>
            <option value="amount basis">Amount basis</option> */}
          </select>
        </div>
      </div>
    </>
  );
};

export default Settlement;
