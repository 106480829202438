import React, { useEffect, useState } from 'react'
import BasicTemplate from '../BasicTemplate'
import { Select } from '../../components/Select'
import { useAuth0 } from '@auth0/auth0-react';
import { TAsset, TBankAccount, TBannerLocal, TWalletAddress } from '../../helpers/types';
import { getAccountBalancePoolAddressByAsset, getAssetByType, getNetworkByAssetSymbol, postSubAccountBankAccountShared, postSubAccountWalletAddressShared } from '../../api/gateway';
import Switch from '../../components/Switch';
import Tooltip from '../../components/Tooltip';
import { Input } from '../../components/Input';
import Banner from '../../components/Banner';

import './index.scss'

interface depositInterface {
  sourceAsset: string;
  network: string;
  subAccountId: string
}

const initialState: depositInterface = {
  sourceAsset: '',
  network: '',
  subAccountId: ''
}

interface Values {
  id: string | number;
  value: string;
}

const initialShowBanner: TBannerLocal = { show: false, type: 'success', title: '', text: '' }


export default function Deposit () {
  const { getAccessTokenSilently } = useAuth0();
  const [deposit, setDeposit] = useState<depositInterface>(initialState);
  const [assetsOption, setAssetsOption] = useState<Values[]>([]);
  const [networks, setNetworks] = useState<Values[]>([]);
  const [walletAddress, setWalletAddress] = useState<TWalletAddress | null>(null)
  const [bankAccount, setBankAccount] = useState<TBankAccount | null>(null)
  const [assets, setAssets] = useState<TAsset[]>([]);
  const [checked, setChecked] = useState(false)
  const [showBanner, setShowBanner] = useState<TBannerLocal>(initialShowBanner);

  const updateValue = (key: string, value: string | number | undefined) => {
    setDeposit((deposit) => ({
      ...deposit,
      [key]: value,
    }));
  };

  const handleGetNetworkByAsset = (asset: string) => {
    getAccessTokenSilently().then(async (token) => {
      getNetworkByAssetSymbol(token, asset).then(({ data }) => {
        const response = data.networks.map((network: { id: string, name: string }) => {
          return {
            id: network.id,
            value: network.name
          }
        })
        response.unshift({ id: '', value: 'Choose one network' })
        setNetworks(response)
      })
    })
  }

  const handleGetAssetByType = () => {
    getAccessTokenSilently().then(async (token) => {
      await getAssetByType(token, '').then(({ data }) => {
        const response = []
        for (let i = 0; i < data.assets.length; i++) {
          if (data.assets[i].type === 'fiat' || data.assets[i].type === 'crypto') {
            response.push({
              id: data.assets[i].symbol,
              value: data.assets[i].symbol,
            })
          }
        }
        response.unshift({ id: '', value: 'Choose one currency' })
        setAssetsOption(response)
        setAssets(data.assets)
      })
    })
  }

  useEffect(() => {
    handleGetAssetByType()
  }, [])

  const isFiat = () => assets.find((asset) => asset.symbol === deposit.sourceAsset)?.type === 'fiat'

  const handleRetrieveDepositDetails = () => {
    getAccessTokenSilently().then(async (token) => {
      setShowBanner(initialShowBanner)
      if (checked) {
        getAccountBalancePoolAddressByAsset(token, deposit.sourceAsset).then(({ data }) => {
          setShowBanner({ type: 'success', title: 'Success', text: 'Check below the deposit details', show: true })
          setWalletAddress(data)
        })
      } else if (isFiat()) {
        await postSubAccountBankAccountShared(token, deposit.subAccountId, deposit.sourceAsset).then(({ data }) => {
          setShowBanner({ type: 'success', title: 'Success', text: 'Check below the deposit details', show: true })
          setBankAccount(data)
        })
      } else {
        await postSubAccountWalletAddressShared(token, deposit.subAccountId, deposit.sourceAsset, deposit.network).then(({ data }) => {
          setShowBanner({ type: 'success', title: 'Success', text: 'Check below the deposit details', show: true })
          setWalletAddress(data)
        })
      }
    })
  }

  const filterAssetOptionByPoolBalanceDeposit = () => {
    if (checked) {
      return assetsOption.filter((asset) => asset.id === '' || asset.id === 'USDT' || asset.id === 'USDRIF')
    } else {
      return assetsOption
    }
  }

  return (
    <BasicTemplate>
      <div className='deposit'>
        <div className='deposit__left'>
          <h1>Deposit</h1>
          <h3>Asset Details</h3>
          <Select
            id='assets'
            value={deposit.sourceAsset}
            label={'Source Asset'}
            placeholder='Choose the currency you would like to deposit'
            options={filterAssetOptionByPoolBalanceDeposit()}
            onChange={(v) => {
              setShowBanner(initialShowBanner)
              setNetworks([])
              setWalletAddress(null)
              setBankAccount(null)
              const isFiat = assets.find((asset) => asset.symbol === v)?.type === 'fiat'
              updateValue('sourceAsset', v)
              if (!isFiat) handleGetNetworkByAsset(v)
            }}
          />
          {!isFiat() && (
            <Select
              disabled={deposit.sourceAsset === ''}
              id='network'
              value={deposit.network}
              label='Network'
              placeholder='Choose the preferred currency for the deposit.'
              options={networks}
              onChange={(value) => updateValue('network', value)}
            />
          )}
          <div className='deposit__left__switch'>
            <Switch checked={checked} onChange={(value) => {
              setShowBanner(initialShowBanner)
              setChecked(value)
              setWalletAddress(null)
              setBankAccount(null)
              setNetworks([])
              updateValue('sourceAsset', '')
            }} />
            <span>Pool Balance Deposit</span>
            <Tooltip text={'When enabled, Beta will display the deposit details of your Master Account.'} />
          </div>
          {!checked && (
            <Input
              type="text"
              id="branch"
              label={'Sub Account ID'}
              message='Inform the Sub Account IDs'
              value={deposit.subAccountId}
              onChange={(value) => updateValue('subAccountId', value)}
            />
          )}
          <button className='deposit__left__action' onClick={handleRetrieveDepositDetails}>Retrieve Deposit Details</button>
          <Banner
            type={showBanner.type}
            title={showBanner.title}
            text={showBanner.text}
            show={showBanner.show}
            onClose={() => setShowBanner(initialShowBanner)}
          />
          <div className='deposit__left__details'>
            {bankAccount || walletAddress ? (
              <h2>Deposit Details</h2>
            ) : ''}
            <div className='deposit__left__details__grid'>
              {bankAccount ? (
                <>
                  <div className='deposit__left__details__grid__item'>
                    <span>Bank Code / BIC</span>
                    <p>{bankAccount.bank_code}</p>
                  </div>
                  <div className='deposit__left__details__grid__item'>
                    <span>Account Number / IBAN</span>
                    <p>{bankAccount.account}</p>
                  </div>
                  <div className='deposit__left__details__grid__item'>
                    <span>Branch</span>
                    <p>{bankAccount.branch}</p>
                  </div>
                  <div className='deposit__left__details__grid__item'>
                    <span>Street line</span>
                    <p>{bankAccount.street_line}</p>
                  </div>
                  <div className='deposit__left__details__grid__item'>
                    <span>Country</span>
                    <p>{bankAccount.country}</p>
                  </div>
                  <div className='deposit__left__details__grid__item'>
                    <span>Instant Payment Type</span>
                    <p>{bankAccount.instant_payment_type}</p>
                  </div>
                  <div className='deposit__left__details__grid__item'>
                    <span>Instant Payment</span>
                    <p>{bankAccount.instant_payment}</p>
                  </div>
                </>
              ) : walletAddress ? (
                <>
                  <div className='deposit__left__details__grid__item'>
                    <span>Wallet Address</span>
                    <p>{walletAddress.address}</p>
                  </div>
                  {walletAddress.address_tag && (
                    <div className='deposit__left__details__grid__item'>
                      <span>Tag</span>
                      <p>{walletAddress.address_tag}</p>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className='deposit__right'>
          <div className='deposit__right__info'>
            <div className='deposit__right__info__card'>
              <h2 className='deposit__right__info__card--title'>Proceed carefully</h2>
              <p className='deposit__right__info__card--info'>{"Ensure the deposit details are accurate"}</p>
              <span className='deposit__right__info__card--text'>We cannot retrieve funds sent to an incorrect deposit address or bank account.</span>
            </div>
          </div>
        </div>
      </div>
    </BasicTemplate>
  )
}