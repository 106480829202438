import React, { FC } from 'react'
import './index.scss'

type Props = {
  checked: boolean;
  onChange: (value: boolean) => void;
}

const Switch: FC<Props> = ({ checked, onChange }: Props) => {
  return (
    <label className="switch">
      <input
        onChange={() => {
          onChange(!checked)
        }}
        type="checkbox"
        checked={checked} />
      <span className="slider round"></span>
    </label>
  )
}

export default Switch