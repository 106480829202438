import React, { FC } from 'react'
import { TBank } from '../../helpers/types'

import './PayoutBankDetails.scss'
import { useAuth0 } from '@auth0/auth0-react'
import { deleteSubAccountBankExternal } from '../../api/gateway'

type Props = {
  bank: TBank
  subAccountId: string
  onDelete: () => void
  onContinue: () => void
}

const PayoutBankDetails: FC<Props> = ({ bank, subAccountId, onDelete, onContinue }: Props) => {
  const { getAccessTokenSilently } = useAuth0();

  const deleteSubAccountBank = async () => {
    await getAccessTokenSilently().then(async (token) => {
      await deleteSubAccountBankExternal(token, subAccountId, bank.id).then(() => {
        onDelete()
      })
    })
  }

  return (
    <section className='payoutBankDetails'>
      <div className='payoutBankDetails__card'>
        <h3>Bank Details</h3>
        <div className='payoutBankDetails__card__grid'>
          <p>Bank Code</p>
          <span>{bank.bank_code}</span>
        </div>
        <div className='payoutBankDetails__card__grid'>
          <p>Bank Name</p>
          <span>{bank.bank_name}</span>
        </div>
        <div className='payoutBankDetails__card__grid'>
          <p>Branch</p>
          <span>{bank.branch}</span>
        </div>
        <div className='payoutBankDetails__card__grid'>
          <p>City</p>
          <span>{bank.city}</span>
        </div>
        <div className='payoutBankDetails__card__grid'>
          <p>State</p>
          <span>{bank.state}</span>
        </div>
        <div className='payoutBankDetails__card__grid'>
          <p>Instant Payment Type</p>
          <span>{bank.instant_payment_type}</span>
        </div>
        <div className='payoutBankDetails__card__grid'>
          <p>Instant Payment</p>
          <span>{bank.instant_payment}</span>
        </div>
      </div>
      <div className='payoutBankDetails__action'>
        <button onClick={deleteSubAccountBank} className='payoutBankDetails__action--override'>Override</button>
        <button onClick={onContinue} className='payoutBankDetails__action--continue'>Continue</button>
      </div>
    </section>
  )
}

export default PayoutBankDetails