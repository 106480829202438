import React, { FC } from 'react'
import TagStatus from '../../components/TagStatus'
import { TSubAccountDetails } from '../../helpers/types'

import './PayoutRecipientDetails.scss'

type Props = {
  recipient: TSubAccountDetails
  onContinue: () => void
}

const PayoutRecipientDetails: FC<Props> = ({ recipient, onContinue }: Props) => {
  const status = (status: string) => {
    switch (status) {
      case 'open':
        return 'info'
      case 'pending':
        return 'warning'
      case 'completed':
        return 'positive'
      case 'canceled':
        return 'negative'
      default:
        return 'neutral';
    }
  }
  return (
    <section className='payoutRecipientDetails'>
      <div className='payoutRecipientDetails__card'>
        <h3>Recipient Details</h3>
        <div className='payoutRecipientDetails__card__grid'>
          <p>Full Name</p>
          <span>{recipient.name}</span>
        </div>
        <div className='payoutRecipientDetails__card__grid'>
          <p>Country</p>
          <span>{recipient.country}</span>
        </div>
        <div className='payoutRecipientDetails__card__grid'>
          <p>Address</p>
          <span>{recipient.address}</span>
        </div>
        <div className='payoutRecipientDetails__card__grid'>
          <p>City</p>
          <span>{recipient.city}</span>
        </div>
        <div className='payoutRecipientDetails__card__grid'>
          <p>Postal Code</p>
          <span>{recipient.post_code}</span>
        </div>
        <div className='payoutRecipientDetails__card__grid'>
          <p>ID</p>
          <span>{recipient.id}</span>
        </div>
        <div className='payoutRecipientDetails__card__grid'>
          <p>KYC Status</p>
          <TagStatus name={'open'} status={status('open')} showIcon={true} />
        </div>
      </div>
      <div onClick={onContinue} className='payoutRecipientDetails__action'>
        <button className='payoutRecipientDetails__action--continue'>Continue</button>
      </div>
    </section>
  )
}

export default PayoutRecipientDetails