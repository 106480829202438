import React, { useEffect, useState } from 'react';
import { BasicLayout } from './BasicLayout';
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TUserAccountType, UserAccount } from '../../helpers/types';
import Countries from '../../data/countries';
import { getUserAccountType, postUserAccount } from '../../api/gateway';
import { Modal } from './Modal';
import { PageLoader } from '../../components/PageLoader/PageLoader';
import useAuth0Continue from '../../helpers/useAuth0Continue'

import './Form.scss';

const initialState = {
  name: '',
  country: 'BR',
  city: '',
  address: '',
  post_code: '',
  tax_id_number: '',
  local_id_type: `Driver's License`,
  local_id_number: '',
  account_type_id: '',
};

const Form = () => {
  const [account, setAccount] = useState<UserAccount>(initialState);
  const [terms, setTerms] = useState<number>(0);
  const [typesAccount, setTypesAccount] = useState<TUserAccountType>();
  const [haveTry, setHaveTry] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState({
    name: false,
    city: false,
    address: false,
    post_code: false,
    tax_id_number: false,
    local_id_type: false,
    local_id_number: false,
  });
  const [searchParams] = useSearchParams()
  const continueUrl = useAuth0Continue()
  const { typeAccount } = useParams();
  const navigate = useNavigate();

  const auth0ID = searchParams.get("auth0_id")

  const selectedTypeAccount = async () =>
    await getUserAccountType().then(({ data }) => {
      setTypesAccount(data.list);
    })

  useEffect(() => {
    selectedTypeAccount();
  }, []);

  const updateValue = (key: string, value: string) => {
    setAccount((account) => ({
      ...account,
      [key]: value,
    }));
    setIsValid({
      ...isValid,
      [key]: value.length > 0,
    });
  };

  const headerInformation = () => {
    return typeAccount === 'business'
      ? 'Fill out your company details.'
      : 'Fill out your personal details.';
  };

  const handlerValidation = () => {
    setHaveTry(true);

    const typeAccountSelected =
      typesAccount && typesAccount.find((v) => v.name === typeAccount);
    const data = { ...account };
    data.account_type_id = typeAccountSelected?.id;
    data.account_type_name = typeAccountSelected?.name;

    for (const [key, value] of Object.entries(data)) {
      if (typeAccount === 'individual') {
        if (!value) {
          return;
        }
      } else {
        if (key !== 'local_id_type' && key !== 'local_id_number') {
          if (!value) {
            return;
          }
        }
      }
    }

    return data;
  };

  const handlerSubmit = async () => {
    const data = handlerValidation();

    if (!data || !auth0ID) return;

    data.auth0_id = auth0ID

    setIsLoading(true);

    await postUserAccount(data)
      .then(({ data }) => {
        navigate(
          `/register-account/form/${typeAccount}/documents/${data.id}${location.search}`,
        );
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handlerSkipDocuments = async () => {
    const data = handlerValidation();

    if (!data || !auth0ID) return;

    data.auth0_id = auth0ID

    setIsLoading(true);

    await postUserAccount(data)
      .then(() => {
        navigate("/success", { state: { auth0PostLogin: continueUrl } })
      })
      .catch(() => {
        setIsLoading(false);
      });
  };


  const getCountryName = (c: string) => {
    const name = Countries.find(({ id }) => id === c)?.value
    if (!name) return ""

    return name
  }

  return (
    <BasicLayout actualProgress={2}>
      <Modal
        openModal={openModal}
        onChange={(v) => setOpenModal(v)}
        onContinue={handlerSkipDocuments}
      />
      <div className="form">
        <h1>Account Information:</h1>
        <p>{headerInformation()}</p>
        <div className="form__content">
          {typeAccount === 'business' ? (
            <>
              <Input
                type="text"
                id="companyName"
                label={'Company Name:'}
                value={account.name}
                onChange={(value) => updateValue('name', value)}
                placeholder={'type your company name here'}
                error={haveTry && !isValid.name}
              />
              <Select
                id="country"
                label={'Country:'}
                value={getCountryName(account.country)}
                onChange={(v) => {
                  const countryCode = Countries.find(({ value }) => value === v)?.id

                  if (countryCode) updateValue('country', countryCode)
                }}
                options={Countries}
              />
              <Input
                type="text"
                id="tax_id_number"
                label={account.country === 'BR' ? 'CNPJ' : 'Company Registration Number:'}
                value={account.tax_id_number}
                onChange={(value) => updateValue('tax_id_number', value)}
                placeholder={account.country === 'BR' ? 'type your CNPJ' : 'type your company registration number'}
                error={haveTry && !isValid.tax_id_number}
                mask={account.country === 'BR' ? '99.999.999/9999-99' : ''}
              />
              <Input
                type="text"
                id="corporateAddress"
                label={'Corporate Address:'}
                value={account.address}
                onChange={(value) => updateValue('address', value)}
                placeholder={'type the address information'}
                error={haveTry && !isValid.address}
              />
              <Input
                type="text"
                id="city"
                label={'City:'}
                value={account.city}
                onChange={(value) => updateValue('city', value)}
                placeholder={'type the city you company is registered'}
                error={haveTry && !isValid.city}
              />
              <Input
                type="text"
                id="post_code"
                label={'Postal Code:'}
                value={account.post_code}
                onChange={(value) => updateValue('post_code', value)}
                placeholder={'type the postal code'}
                error={haveTry && !isValid.post_code}
                mask={account.country === 'BR' ? '99999-999' : ''}
              />
            </>
          ) : (
            <>
              <Input
                type="text"
                id="fullName"
                label={'Full Name:'}
                value={account.name}
                onChange={(value) => updateValue('name', value)}
                placeholder={'type your company name here'}
                error={haveTry && !isValid.name}
              />
              <Select
                id="country"
                label={'Country:'}
                value={getCountryName(account.country)}
                onChange={(v) => {
                  const countryCode = Countries.find(({ value }) => value === v)?.id

                  if (countryCode) updateValue('country', countryCode)
                }}
                options={Countries}
              />
              <Select
                id="local_id_type"
                label={'ID Type:'}
                value={account.local_id_type}
                onChange={(value) => updateValue('local_id_type', value)}
                options={[
                  { value: `Driver's License`, id: 'drivers_license' },
                  { value: 'Passport', id: 'passport' },
                  { value: 'Personal ID', id: 'personal_id' },
                ]}
              />
              <Input
                type="text"
                id="local_id_number"
                label={`${account.local_id_type} Number:`}
                value={account.local_id_number}
                onChange={(value) => updateValue('local_id_number', value)}
                placeholder={`type here your ${account.local_id_type}`}
                error={haveTry && !isValid.local_id_number}
              />
              <Input
                type="text"
                id="tax_id_number"
                label={account.country === 'BR' ? 'CPF' : 'Tax ID information:'}
                value={account.tax_id_number}
                onChange={(value) => updateValue('tax_id_number', value)}
                placeholder={account.country === 'BR' ? 'type your CPF' : 'type your tax ID'}
                error={haveTry && !isValid.tax_id_number}
                mask={account.country === 'BR' ? '999.999.999-99' : ''}
              />
              <Input
                type="text"
                id="corporateAddress"
                label={'Personal Address:'}
                value={account.address}
                onChange={(value) => updateValue('address', value)}
                placeholder={'type your personal address'}
                error={haveTry && !isValid.address}
              />
              <Input
                type="text"
                id="city"
                label={'City:'}
                value={account.city}
                onChange={(value) => updateValue('city', value)}
                placeholder={'type your city'}
                error={haveTry && !isValid.city}
              />
              <Input
                type="text"
                id="post_code"
                label={'Postal Code:'}
                value={account.post_code}
                onChange={(value) => updateValue('post_code', value)}
                placeholder={'type the postal code'}
                error={haveTry && !isValid.post_code}
                mask={account.country === 'BR' ? '99999-999' : ''}
              />
            </>
          )}
          <div className="form__terms">
            <input
              value={terms}
              onClick={() => setTerms(terms === 0 ? 1 : 0)}
              type="checkbox"
            />
            <span>
              By proceeding you declare that you have read and agree with our{' '}
              <a
                target="__blank"
                href="https://www.betaramps.com/terms-and-conditions"
              >
                Terms and Conditions
              </a>{' '}
              and our{' '}
              <a
                target="__blank"
                href="https://www.betaramps.com/privacy-policy"
              >
                Privacy Policy.
              </a>{' '}
            </span>
          </div>
          <div className="form__actions">
            {isLoading ? (
              <PageLoader />
            ) : (
              <>
                <button
                  onClick={() => setOpenModal(true)}
                  disabled={terms === 0}
                  className="form__actions__blank"
                >
                  Skip upload
                </button>
                <button
                  onClick={handlerSubmit}
                  disabled={terms === 0}
                  type="submit"
                  className="form__actions__black"
                >
                  Upload documents
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default Form;
