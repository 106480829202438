import React, { FC, useState, useEffect } from 'react';
import InputMask from "react-input-mask";

import './index.scss';

type Props = {
  id: string;
  value: string | number;
  type: string;
  label: string;
  message?: string;
  onChange: (value: string) => void;
  placeholder?: string | undefined;
  error?: boolean;
  errorMessage?: string;
  mask?: string;
  disabled?: boolean;
};

export const Input: FC<Props> = (props: Props) => {
  const [value, setValue] = useState(props.value);
  const [maskValue, setMaskValue] = useState<string>('')

  useEffect(() => {
    setValue(props.value);
    if (props.mask) {
      setMaskValue(props.mask)
    } else {
      setMaskValue('')
    }
  }, [props.value, props.mask]);

  return (
    <div className="input">
      <label htmlFor={props.id}>
        {props.label} {props.error && <span>Invalid field</span>}
      </label>
      {props.message && <span className="input__message">{props.message}</span>}
      <InputMask
        disabled={props.disabled}
        mask={maskValue}
        id={props.id}
        value={value}
        onChange={({ target }) => {
          setValue(target.value);
          props.onChange(target.value);
        }}
        type={props.type}
        placeholder={props.placeholder}
        style={props.error ? { border: '1px solid #FF2D55' } : {}}
      />
      {props.error && <span className='input__messageError'>{props.errorMessage}</span>}
    </div>
  );
};
