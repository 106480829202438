import React, { useState, useEffect } from 'react';
import ProgressLine from '../../components/ProgressLine';
import { useNavigate } from 'react-router-dom';
import { TUserAccountType } from '../../helpers/types';
import { PageLoader } from '../../components/PageLoader/PageLoader';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserAccountType, getUserAccount } from '../../api/gateway';

import './RegisterAccount.scss';

const RegisterAccount = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [typesAccount, setTypesAccount] = useState<TUserAccountType>();
  const [accountSelected, setAccountSelected] = useState<string>('');

  const getAccountsType = async () => {
    await getUserAccountType().then(({ data }) => {
      setTypesAccount(data.list);
    });
  };

  const checkUserHaveAccount = async () => {
    await getAccessTokenSilently()
      .then(async (token) => {
        await getUserAccount(token).then(({ data }) => {
          if (data && data.id) {
            navigate('/home');
          }
        });
      })
  };

  useEffect(() => {
    getAccountsType();
    checkUserHaveAccount();

    return () => {
      setTypesAccount([]);
    };
  }, []);

  const goTo = () => {
    navigate(accountSelected + location.search);
  };

  return (
    <div className="registerAccount">
      <div className="registerAccount__image"></div>
      <div className="registerAccount__type">
        <div className="registerAccount__type__header">
          <div className="registerAccount__type__header--img" />
          <div className="header__language"></div>
        </div>
        <div className="registerAccount__type__progress">
          <ProgressLine actual={1} />
        </div>
        <h3>Welcome to Beta</h3>
        <p>Please choose one of the following options to get started:</p>
        <div className="registerAccount__type__cards">
          <div className="registerAccount__type__cards--grid">
            {typesAccount && typesAccount.length > 0 ? (
              typesAccount?.map((v) => {
                return (
                  <div key={v.id} className="cards__style" onClick={() =>
                    setAccountSelected(`/register-account/form/${v.name}`)
                  }>
                    <div className="cards__style__title">
                      <input
                        type="radio"
                        name="radio"
                        id="radio"
                      />
                      <label htmlFor="radio">{v.name}</label>
                    </div>
                    <p>{v.description}</p>
                  </div>
                );
              })
            ) : (
              <PageLoader />
            )}
          </div>
        </div>
        <div className="registerAccount__type__action">
          <button onClick={goTo} disabled={!accountSelected}>
            Next Step
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterAccount;
