import React, { FC, useEffect, useState } from 'react'
import { Select } from '../../components/Select';
import IconPurpleArrowRight from '../../assets/purple-arrow-right.png';
import RegisterBank from './RegisterBank';
import { deleteSubAccountBankExternal, deleteSubAccountWalletAddressExternal, getAssetByType, getNetworkByAssetSymbol, getSubAccountBankAccount, getSubAccountWalletAddress } from '../../api/gateway';
import { useAuth0 } from '@auth0/auth0-react';
import { TAsset, TBankAccount, TWalletAddress } from '../../helpers/types';

import './SubAccountDetailsWithdrawal.scss'

interface withdrawalInterface {
  sourceAsset: string;
  network: string;
}

const initialState: withdrawalInterface = {
  sourceAsset: '',
  network: '',
}

interface Values {
  id: string | number;
  value: string;
}

type Props = {
  subAccountId: string;
}


const SubAccountDetailsDeposit: FC<Props> = ({ subAccountId }: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [withdrawalDetails, setWithdrawalDetails] = useState<withdrawalInterface>(initialState);
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [networks, setNetworks] = useState<Values[]>([]);
  const [assetsOption, setAssetsOption] = useState<Values[]>([]);
  const [assets, setAssets] = useState<TAsset[]>([]);
  const [walletAddress, setWalletAddress] = useState<TWalletAddress | null>(null)
  const [bankAccount, setBankAccount] = useState<TBankAccount | null>(null)
  const [assetError, setAssetError] = useState<boolean>(false)

  const updateValue = (key: string, value: string | number | undefined) => {
    setWithdrawalDetails((deposit) => ({
      ...deposit,
      [key]: value,
    }));
  };

  const handleGetAssetByType = () => {
    getAccessTokenSilently().then(async (token) => {
      await getAssetByType(token, '').then(({ data }) => {
        const response = []
        for (let i = 0; i < data.assets.length; i++) {
          if (data.assets[i].type === 'fiat' || data.assets[i].type === 'crypto') {
            response.push({
              id: data.assets[i].symbol,
              value: data.assets[i].symbol,
            })
          }
        }
        response.unshift({ id: '', value: 'Choose one currency' })
        setAssetsOption(response)
        setAssets(data.assets)
      })
    })
  }

  const handleGetNetworkByAsset = (asset: string) => {
    getAccessTokenSilently().then(async (token) => {
      getNetworkByAssetSymbol(token, asset).then(({ data }) => {
        const response = data.networks.map((network: { id: string, name: string }) => {
          return {
            id: network.id,
            value: network.name
          }
        })
        response.unshift({ id: '', value: 'Choose one network' })
        setNetworks(response)
      })
    })
  }

  useEffect(() => {
    handleGetAssetByType()
  }, [])

  const isFiat = () => assets.find((asset) => asset.symbol === withdrawalDetails.sourceAsset)?.type === 'fiat'

  const handleGenerateDepositDetails = () => {
    getAccessTokenSilently().then(async (token) => {
      if (isFiat()) {
        await getSubAccountBankAccount(token, subAccountId, withdrawalDetails.sourceAsset, 'external').then(({ data }) => {
          if (data.list === null || data.list.length === 0) {
            setAssetError(true)
            setStep(1)
            return
          } else {
            setStep(2)
            setBankAccount(data.list[0])
          }
        })
      } else {
        await getSubAccountWalletAddress(token, subAccountId, withdrawalDetails.sourceAsset, 'external').then(({ data }) => {
          if (data.list === null || data.list.length === 0) {
            setAssetError(true)
            setStep(1)
            return
          } else {
            setStep(2)
            setWalletAddress(data.list[0])
          }
        })
      }
    })
  }

  const disableButton = () => {
    if (isFiat() && withdrawalDetails.sourceAsset !== '') return false
    if (!isFiat() && withdrawalDetails.sourceAsset !== '' && withdrawalDetails.network !== '') return false
    return true
  }

  const handleDeleteSubAccount = () => {
    getAccessTokenSilently().then(async (token) => {
      if (isFiat()) {
        if (!bankAccount) throw new Error("bankAccount is empty");
        await deleteSubAccountBankExternal(token, subAccountId, bankAccount.id).then(() => {
          setStep(3)
        })
      } else {
        if (!walletAddress) throw new Error("walletAddress is empty");
        await deleteSubAccountWalletAddressExternal(token, subAccountId, walletAddress.id).then(() => {
          setStep(3)
        })
      }
    })
  }

  return (
    <div className='subAccWithdrawal'>
      <h2 className='subAccWithdrawal__title'>Withdrawal Details</h2>
      {step === 1 && (
        <section className='subAccWithdrawal__checkWithdrawal'>
          <Select
            id='sourceAsset'
            value={withdrawalDetails.sourceAsset}
            label='Source Asset'
            error={assetError}
            errorMessage="You haven't registered any withdrawal information for this asset."
            placeholder='Choose the currency you would like to deposit'
            options={assetsOption}
            onChange={(v) => {
              setStep(1)
              setAssetError(false)
              setNetworks([])
              setWalletAddress(null)
              setBankAccount(null)
              updateValue('sourceAsset', v)
              if (!isFiat()) handleGetNetworkByAsset(v)
            }}
          />
          {!isFiat() && (
            <Select
              id='network'
              value={withdrawalDetails.network}
              label='Network'
              placeholder='Choose the preferred currency for the deposit.'
              options={networks}
              onChange={(value) => updateValue('network', value)}
            />
          )}
          <div className='subAccWithdrawal__checkWithdrawal__action'>
            {assetError && (
              <button onClick={() => setStep(3)} className='subAccWithdrawal__checkWithdrawal__action--register'>
                Click here to register the withdrawal details
                <img src={IconPurpleArrowRight} alt="icon to register details" />
              </button>
            )}
            <button
              disabled={disableButton()}
              onClick={handleGenerateDepositDetails}
              type='submit'
              className='subAccWithdrawal__checkWithdrawal__action--check'
            >Check Withdrawal Details</button>
          </div>
        </section>
      )}
      {step === 2 && (
        bankAccount ? (
          <section className='subAccWithdrawal__bank'>
            <div className='subAccWithdrawal__bank__card'>
              <h3>Bank Details</h3>
              <div className='subAccWithdrawal__bank__card__grid'>
                <p>Bank Code</p>
                <span>{bankAccount?.bank_code}</span>
              </div>
              <div className='subAccWithdrawal__bank__card__grid'>
                <p>Bank Name</p>
                <span>{bankAccount?.bank_name}</span>
              </div>
              <div className='subAccWithdrawal__bank__card__grid'>
                <p>Branch</p>
                <span>{bankAccount?.branch}</span>
              </div>
              <div className='subAccWithdrawal__bank__card__grid'>
                <p>City</p>
                <span>{bankAccount?.city}</span>
              </div>
              <div className='subAccWithdrawal__bank__card__grid'>
                <p>State</p>
                <span>{bankAccount?.state}</span>
              </div>
              <div className='subAccWithdrawal__bank__card__grid'>
                <p>Instant Payment Type</p>
                <span>{bankAccount?.instant_payment_type}</span>
              </div>
              <div className='subAccWithdrawal__bank__card__grid'>
                <p>Instant Payment</p>
                <span>{bankAccount?.instant_payment}</span>
              </div>
            </div>
            <div className='subAccWithdrawal__bank__action'>
              <button onClick={() => handleDeleteSubAccount()}>Override</button>
            </div>
          </section>
        ) : (
          <section className='subAccWithdrawal__bank'>
            <div className='subAccWithdrawal__bank__card'>
              <h3>Bank Details</h3>
              <div className='subAccWithdrawal__bank__card__grid'>
                <p>Wallet Address</p>
                <span>{walletAddress?.address}</span>
              </div>
              {walletAddress?.address_tag && (
                <div className='subAccWithdrawal__bank__card__grid'>
                  <p>Tag</p>
                  <span>{walletAddress?.address_tag}</span>
                </div>
              )}
            </div>
          </section>
        )
      )}
      {step === 3 && (
        <section className='subAccWithdrawal__register'>
          <RegisterBank
            subAccountId={subAccountId}
            onContinue={() => {
              setStep(1)
              setAssetError(false)
              handleGetAssetByType()
              setNetworks([])
              setWalletAddress(null)
              setBankAccount(null)
            }} />
        </section>
      )}
    </div>
  )
}

export default SubAccountDetailsDeposit;