import React from 'react';
import { useLocation } from 'react-router-dom';

import './Success.scss';

const Success = () => {
  const { auth0PostLogin } = useLocation().state

  const handleButtonClick = () => {
    window.location.href = auth0PostLogin;
  };

  const showHandleButtonClick = () => {
    return auth0PostLogin && auth0PostLogin.length > 0 ? true : false;
  }

  return (
    <div className="success">
      <div className="success__content">
        <div className="success__content__header">
          <div className="success__content__header--img" />
          <div className="header__language"></div>
        </div>
        <div className="success__content__body">
          <div className="success__content__body__icon"></div>
          <h1>Thank you for signing up.</h1>
          {showHandleButtonClick() ?
            <div className="success__content__body__action">
              <button onClick={handleButtonClick}>Finish sign up</button>
            </div> : (
              <>
                <p>
                  Our team will now review the information provided, and you may receive an update in the next business day.
                </p>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default Success;
