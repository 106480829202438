import React, { useState, useEffect } from 'react'
import {useAuth0} from '@auth0/auth0-react'
import { UserAccountFull, orderBalance } from './types';
import {getOrderBalance, getUserAccount} from '../api/gateway'

const emptyBalance: orderBalance = {
  message: "",
  total_amount: 0,
  total_fees: 0,
  number_of_orders_associated: 0
}

export default () => {
  const {getAccessTokenSilently} = useAuth0();
  const [balance, setBalance] = useState<orderBalance>(emptyBalance)
  const [asset, setAsset] = useState<string>('')

  useEffect(() => {
    (async () => {
      await getAccessTokenSilently().then(async (token) => {
        const {data: account}: {data:UserAccountFull} = await getUserAccount(token)

        setAsset(account.settlement_asset)

        await getOrderBalance(token, account.id, account.settlement_asset).then((resp) => {
          setBalance(resp.data)
        })
      })
    })()
  }, [])

  return {
    ...balance,
    asset,
  }
}