import React, { useEffect, useState } from 'react'
import BasicTemplate from '../BasicTemplate'
import TagStatus from '../../components/TagStatus'
import SubAccountDetailsOverview from './SubAccountDetailsOverview'
import SubAccountDetailsDeposit from './SubAccountDetailsDeposit'
import SubAccountDetailsWithdrawal from './SubAccountDetailsWithdrawal'
import { getBalanceAssets, getOrders, getSubAccountDetail, patchSubAccountInactive } from '../../api/gateway'
import { useAuth0 } from '@auth0/auth0-react'
import { useParams } from 'react-router-dom'
import { TBalancesAmount, TOrder, TSubAccountDetails } from '../../helpers/types'
import dayjs from 'dayjs'

import './index.scss'

const overview = "Overview"
const depositDetails = "Deposit Details"
const withdrawalDetails = "Withdrawal Details"

const initialDetails: TSubAccountDetails = {
  id: '',
  account_id: '',
  name: '',
  email: '',
  is_active: false,
  kyc_status: '',
  country: '',
  city: '',
  address: '',
  tax_id_type: '',
  tax_id_number: '',
  local_id_type: '',
  local_id_number: '',
  phone: '',
  post_code: '',
  created_at: '',
}

export default function SubAccountDetails () {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState<string>(overview);
  const [subAccountDetails, setSubAccountDetails] = useState<TSubAccountDetails>(initialDetails);
  const [balanceAssets, setBalanceAssets] = useState<TBalancesAmount[]>([])
  const [orders, setOrders] = useState<TOrder[]>([]);

  const handleStatus = (status: boolean) => {
    if (status) {
      return 'positive'
    } else {
      return 'negative'
    }
  }

  const handleKycStatus = (status: string) => {
    switch (status.toLowerCase()) {
      case 'unchecked':
        return 'info'
      case 'approved':
        return 'positive'
      case 'denied':
        return 'negative'
      default:
        return 'neutral';
    }
  }

  const isSelected = (value: string) => value === currentTab ? 'subAccountDetails__card__menu--selected' : ''

  const handleChange = (newValue: string) => {
    setCurrentTab(newValue)
  }

  const renderCurrentTab = () => {
    switch (currentTab) {
      case overview:
        return <SubAccountDetailsOverview subAccountId={subAccountDetails.id} balanceAssets={balanceAssets} orders={orders} />
      case depositDetails:
        return <SubAccountDetailsDeposit subAccountId={subAccountDetails.id} />
      case withdrawalDetails:
        return <SubAccountDetailsWithdrawal subAccountId={subAccountDetails.id} />
      default:
        break;
    }
  }

  const formatDate = (date: string) => {
    return dayjs(date).format('MMMM D, YYYY')
  };

  const handleGetSubAccountDetails = async () => {
    if (!id) return
    getAccessTokenSilently().then(async (token) => {
      await getSubAccountDetail(token, id).then(({ data }) => {
        setSubAccountDetails(data);
      });
    })
  };

  const handleGetBalanceAssets = async () => {
    if (!id) return
    getAccessTokenSilently().then(async (token) => {
      await getBalanceAssets(token, id).then(({ data }) => {
        setBalanceAssets(data.balances);
      });
    })
  }

  const handleGetOrders = async () => {
    if (!id) return
    await getAccessTokenSilently().then(async (token) => {
      await getOrders(token, id, 'created_at', false, '', '', 1, 20).then(({ data }) => {
        setOrders(data.orders);
      });
    });
  }

  const handleInactivateSubAccount = async () => {
    getAccessTokenSilently().then(async (token) => {
      patchSubAccountInactive(token, subAccountDetails.id).then(() => {
        handleGetSubAccountDetails()
      })
    })
  }

  useEffect(() => {
    handleGetSubAccountDetails()
    handleGetBalanceAssets()
    handleGetOrders()
  }, [])

  return (
    <BasicTemplate>
      <div className='subAccountDetails'>
        <div className='subAccountDetails__card'>
          <section className='subAccountDetails__card--left'>
            <div className='subAccountDetails__card__title'>
              <h1>{subAccountDetails.name}</h1>
              <span className='subAccountDetails__card__title--email'>{subAccountDetails.email}</span>
              <div className='subAccountDetails__card__title__resume'>
                <div className='subAccountDetails__card__title__resume__item'>
                  <p>Created</p>
                  <span>{formatDate(subAccountDetails.created_at)}</span>
                </div>
                <div className='subAccountDetails__card__title__resume__item'>
                  <p>Status</p>
                  <TagStatus name={subAccountDetails?.is_active ? 'active' : 'inactive'} status={handleStatus(subAccountDetails?.is_active)} showIcon={true} />
                </div>
                <div className='subAccountDetails__card__title__resume__item'>
                  <p>KYC</p>
                  <TagStatus name={subAccountDetails?.kyc_status} status={handleKycStatus(subAccountDetails.kyc_status)} showIcon={true} />
                </div>
              </div>
              <div className='subAccountDetails__card__title__details'>
                <h2>Sub Account Details</h2>
                <div className='subAccountDetails__divideHorizontal'></div>
                <div className='subAccountDetails__card__title__details__grid'>
                  <div className='subAccountDetails__card__title__details__grid__item'>
                    <span>ID</span>
                    <span className='subAccountDetails__card__title__details__grid--id'>{subAccountDetails?.id}</span>
                  </div>
                  <div className='subAccountDetails__card__title__details__grid__item'>
                    <span>Country</span>
                    <p>{subAccountDetails?.country}</p>
                  </div>
                  <div className='subAccountDetails__card__title__details__grid__item'>
                    <span>City</span>
                    <p>{subAccountDetails?.city}</p>
                  </div>
                  <div className='subAccountDetails__card__title__details__grid__item'>
                    <span>Address</span>
                    <p>{subAccountDetails?.address}</p>
                  </div>
                  <div className='subAccountDetails__card__title__details__grid__item'>
                    <span>Postcode</span>
                    <p>{subAccountDetails?.post_code}</p>
                  </div>
                  <div className='subAccountDetails__card__title__details__grid__item'>
                    <span>Phone</span>
                    <p>{subAccountDetails?.phone}</p>
                  </div>
                  <div className='subAccountDetails__card__title__details__grid__item'>
                    <span>Tax Type</span>
                    <p>{subAccountDetails?.tax_id_type}</p>
                  </div>
                  <div className='subAccountDetails__card__title__details__grid__item'>
                    <span>Tax Number</span>
                    <p>{subAccountDetails?.tax_id_number}</p>
                  </div>
                  <div className='subAccountDetails__card__title__details__grid__item'>
                    <span>Local Id Type</span>
                    <p>{subAccountDetails?.local_id_type}</p>
                  </div>
                  <div className='subAccountDetails__card__title__details__grid__item'>
                    <span>Local Id Number</span>
                    <p>{subAccountDetails?.local_id_number}</p>
                  </div>
                  <div className='subAccountDetails__card__title__details__grid__item__action'>
                    <button
                      onClick={handleInactivateSubAccount}
                      className='subAccountDetails__card__title__details__grid__item__action--inactive'
                    >Inactivate Sub Account</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {subAccountDetails.is_active && (
            <section className='subAccountDetails__card--right'>
              <div className='subAccountDetails__card__menu'>
                <div className={'subAccountDetails__card__menu__option ' + isSelected(overview)} onClick={() => handleChange(overview)}>
                  <span>{overview}</span>
                </div>
                <div className={'subAccountDetails__card__menu__option ' + isSelected(depositDetails)} onClick={() => handleChange(depositDetails)}>
                  <span>{depositDetails}</span>
                </div>
                <div className={'subAccountDetails__card__menu__option ' + isSelected(withdrawalDetails)} onClick={() => handleChange(withdrawalDetails)}>
                  <span>{withdrawalDetails}</span>
                </div>
                <div className='subAccountDetails__card__menu__option'></div>
              </div>
              <div>
                {renderCurrentTab()}
              </div>
            </section>
          )}
        </div>
      </div>
    </BasicTemplate>
  )
}
