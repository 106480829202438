import React, { FC, ReactNode } from 'react';
import Nav from '../../components/Nav';
import NavHeader from '../../components/NavHeader';

import './index.scss';

type Props = {
  children: ReactNode;
};

const BasicTemplate: FC<Props> = ({ children }: Props) => {

  return (
    <div className="basicTemplate">
      <Nav />
      <div>
        <NavHeader />
        {children}
      </div>
    </div>
  );
};

export default BasicTemplate;
