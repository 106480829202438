export default [
  {
    question: `I'm unable to generate orders`,
    answer: `
    Some of the reasons why you are unable to generate orders are: (1) Your account already exceeded the monthly operational limits, (2) payment request is below USD 5.00, and (3) Your session might have expired and you must log in again. If the error persists, please reach out to your Account manager so we can troubleshoot the root cause.
  `,
  },
  {
    question: `Deposit confirmed on blockchain but still the Dashboard shows as pending.`,
    answer: `Beta runs risk and compliance checks after receiving a deposit, and you should wait for our confirmation before delivering the goods or services to your customer. Payments could be canceled or refunded automatically to your customer in some cases, especially when there are mismatches between the deposit made and the instructions provided by Beta.`,
  },
  {
    question: `How can I issue refunds after the order has been confirmed?`,
    answer: `Refunds with crypto currencies are not available at the moment, and you should negotiate an alternative way to send the funds back to your customer.`,
  },
  {
    question: `My customer is requesting to pay in a different cryptocurrency`,
    answer: `We are constantly including new crypto assets and networks to our platform based on our client's feedbacks.
    Please make a note to your account manager, so we can assess the roadmap for it.`,
  },
  {
    question: `What happens when the customer deposits more or less the expected amount?`,
    answer: `Beta will confirm the transaction and start the refund process for the excess paid in parallel when the customer deposits more than expected. If the customer deposits less than expected, we will give the option to top-up the missing amount or request a full refund.
    Our platform will communicate with your customer in both cases, and you won't have any action to take.`,
  },
];
