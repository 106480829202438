import React, { FC } from 'react'
import IconNeutral from '../../assets/status-neutral.png'
import IconWarning from '../../assets/status-warning.png'
import IconCritical from '../../assets/status-critical.png'
import IconPositive from '../../assets/status-positive-circle.png'
import IconCancel from '../../assets/icon-cancel.png'

import './index.scss'

type Props = {
  type: 'neutral' | 'warning' | 'error' | 'success';
  title: string;
  text: string;
  show: boolean;
  onClose: () => void;
}

const Banner: FC<Props> = (props: Props) => {

  const icon = () => {
    switch (props.type) {
      case 'neutral':
        return IconNeutral;
      case 'warning':
        return IconWarning;
      case 'error':
        return IconCritical;
      case 'success':
        return IconPositive;
      default:
        return IconNeutral;
    }
  }

  const backgroundClass = () => {
    switch (props.type) {
      case 'neutral':
        return 'banner--neutral';
      case 'warning':
        return 'banner--warning';
      case 'error':
        return 'banner--error';
      case 'success':
        return 'banner--success';
      default:
        return 'banner--neutral';
    }
  }

  return props.show && (
    <section className={'banner ' + backgroundClass()}>
      <div className='banner__title'>
        <img src={icon()} alt="status icon" />
        <h2 className='banner__title'>{props.title}</h2>
        <button onClick={() => props.onClose()} className='banner__title__action'>
          <img src={IconCancel} alt="icon close banner" />
        </button>
      </div>
      <p>{props.text}</p>
    </section>
  )
}

export default Banner