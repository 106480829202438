import React, { FC, useEffect, useState } from 'react'
import TagStatus from '../../components/TagStatus'
import dayjs from 'dayjs'
import IconDots from '../../assets/icon-dots.png'
import { Link } from 'react-router-dom'
import { getOrderBalance } from '../../api/gateway'
import { useAuth0 } from '@auth0/auth0-react'

import './SubAccountDetailsOverview.scss'
import { TBalancesAmount, TOrder } from '../../helpers/types'

type Props = {
  subAccountId: string;
  balanceAssets: TBalancesAmount[]
  orders: TOrder[]
}

const SubAccountDetailsOverview: FC<Props> = ({ subAccountId, balanceAssets, orders }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [balances, setBalances] = useState<any[]>();

  const formatterAmount = (amount: number) => { return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount) }

  const formatDate = (date: string) => {
    return (
      dayjs(date).format('MMMM D, YYYY') +
      ', ' +
      dayjs(date).format('hh:mm A')
    );
  };

  const handleStatus = (status: string) => {
    switch (status) {
      case 'open':
        return 'info'
      case 'pending':
        return 'warning'
      case 'confirmed':
        return 'positive'
      case 'canceled':
        return 'negative'
      default:
        return 'neutral';
    }
  }

  const handleGetOrderBalances = async () => {
    if (!subAccountId) return
    getAccessTokenSilently().then(async (token) => {
      await getOrderBalance(token, subAccountId, '').then(({ data }) => {
        setBalances(data);
      });
    })
  }

  useEffect(() => {
    handleGetOrderBalances()
  }, [])


  return (
    <section className='subAccOverview'>
      <h2 className='subAccOverview__title'>Balances</h2>
      <div className='subAccOverview__balances'>
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {balances && balances?.length > 0 && balances.map((balance, index) => (
              <tr key={index}>
                <td>{balance.asset}</td>
                <td>{balance.amount}</td>
                <td>{balance.amountInUSD}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            {balanceAssets.map((balance, index) => {
              return (
                <tr key={index}>
                  <td>{balance.asset}</td>
                  <td>{balance.balance_amount}</td>
                </tr>
              )
            })}
          </tfoot>
        </table>
      </div>
      <div className='subAccOverview__latestOrders'>
        <h2 className='subAccOverview__title'>Latests Orders</h2>
        <table>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Status</th>
              <th>Description</th>
              <th>Created At</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {orders.map((row) => (
              <tr key={row.id}>
                <td>
                  <span className='orders__card__table--amount'>{formatterAmount(row.target_amount)}</span>
                  <span className='orders__card__table--currency'>{row.target_asset}</span>
                </td>
                <td>
                  <TagStatus name={row.status} status={handleStatus(row.status)} showIcon={true} />
                </td>
                <td>{row.description}</td>
                <td>{formatDate(row.created_at)}</td>
                <td>
                  <Link to={`/order-details/${row.id}`}>
                    <img src={IconDots} alt="order details button" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default SubAccountDetailsOverview;