import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getPoolBalance } from "../../api/gateway";
import BasicTemplate from "../BasicTemplate";

import './index.scss'

const PoolBalance = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [balances, setBalances] = useState<any[]>();

  const handleGetPoolBalance = async () => {
    getAccessTokenSilently().then(async (token) => {
      await getPoolBalance(token).then(({ data }) => {
        setBalances(data.balances);
      });
    })
  }

  useEffect(() => {
    handleGetPoolBalance()
  }, [])

  return (
    <BasicTemplate>
      <div className="poolBalance">
        <h1>Pool Balance</h1>

        <div className='poolBalance__balances'>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {balances && balances?.length > 0 && balances.map((balance, index) => (
                <tr key={index}>
                  <td>{balance.asset}</td>
                  <td>{balance.amount}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>

              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </BasicTemplate>
  );
}

export default PoolBalance