import React from 'react';
import BetaLogoWhite from '../../assets/beta-logo-white.png';
import './index.scss';

const PageInternalError = () => {
  return (
    <div className="pageInternalError">
      <div className="pageInternalError__container">
        <div className="pageInternalError__container__header">
          <img src={BetaLogoWhite} alt="beta logo white" />
          <div className="header__language" />
        </div>
        <p>
          We had an error while processing your request. <br />
          If the error persists, please contact our support team.
        </p>
      </div>
    </div>
  );
};

export default PageInternalError;
