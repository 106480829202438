import Axios, { AxiosError, AxiosResponse } from 'axios';

Axios.interceptors.request.use(
  async (config) => {
    return config;
  },
  function (error: AxiosError) {
    error.code
    return Promise.reject(error);
  },
);

Axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  function (error: AxiosError) {
    if (error.response?.status === 401) {
      window.location.replace('/success');
    }
    if (error.response?.status === 500) {
      window.location.replace('/contact-support');
    }
    
    return Promise.reject(error);
  },
);
