import React, { FC, useEffect, useState } from 'react'
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';
import Countries from '../../data/countries';
import { useAuth0 } from '@auth0/auth0-react';
import { getAssetByType, postSubAccountBankExternal } from '../../api/gateway';
import { TAsset, TBank } from '../../helpers/types';

import './PayoutCreateBank.scss'

const assetType = 'fiat'

const allowedAssetToInstantPayment = ['MXN', 'ARS', 'BRL']

const initialRegisterState: TBank = {
  id: '',
  bank_name: '',
  bank_code: '',
  city: '',
  state: '',
  postal_code: '',
  street_line: '',
  branch: '',
  account: '',
  bank_type: '',
  instant_payment: '',
  instant_payment_type: '',
  country: '',
  asset: '',
  created_at: '',
  updated_at: '',
  deleted_at: ''
}

type Asset = {
  id: string;
  value: string;
}

const assetsEmpty: TAsset = {
  id: '',
  name: '',
  symbol: '',
  type: '',
  small_image_url: '',
  medium_image_url: '',
  large_image_url: '',
  networks: '',
  active: false
}

type Props = {
  subAccountId: string;
  onContinue: () => void;
}

const PayoutCreateBank: FC<Props> = ({ subAccountId, onContinue }: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [register, setRegister] = useState<TBank>(initialRegisterState);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [assetsByType, setAssetsByType] = useState<TAsset[]>([]); // fiat, crypto
  const [currency, setCurrency] = useState<TAsset>(assetsEmpty)

  const updateValue = (key: string, value: string | number | undefined) => {
    setRegister((bank) => ({
      ...bank,
      [key]: value,
    }));
  };

  const getAssets = async () => {
    await getAccessTokenSilently().then(async (token) => {
      await getAssetByType(token, assetType).then(({ data }) => {
        const resp = data.assets.map((asset: TAsset) => {
          if (!asset.active) return
          return ({
            id: asset.symbol,
            value: asset.name
          }
          )
        })
        resp.unshift({ id: 'ChooseOne', value: 'Choose one' })
        setAssets(resp)
        setAssetsByType(data.assets)
      });
    });
  }

  useEffect(() => {
    getAssets()
  }, [])

  const createSubAccountBank = async () => {
    await getAccessTokenSilently().then(async (token) => {
      await postSubAccountBankExternal(token, subAccountId, register).then(({ data }) => {
        onContinue()
      })
    })
  }

  const instantPaymentTypes = () => {
    const paymentTypes = [{ id: '', value: 'Choose one' }]
    switch (register.asset) {
      case 'BRL':
        paymentTypes.push({ id: 'PIX', value: 'PIX' })
        break
      case 'MXN':
        paymentTypes.push({ id: 'SPEI', value: 'SPEI' })
        break
      case 'ARS':
        paymentTypes.push({ id: 'CBU', value: 'CBU' }, { id: 'CVU', value: 'CVU' }, { id: 'ALIAS', value: 'ALIAS' })
        break
      default:
        break
    }
    return paymentTypes
  }

  return (
    <div className='payoutCreateBank'>
      <h2>Override recipient bank detail</h2>
      <Select
        id='country'
        value={register.country}
        label='Country'
        options={Countries}
        onChange={(value) => updateValue('country', value)}
      />
      <Select
        id="asset"
        label={'Asset'}
        placeholder='Choose the preferred currency for this bank account'
        value={currency.name}
        onChange={(v) => {
          const resp = assetsByType.find((asset) => asset.name === v)
          if (!resp) return
          setCurrency(resp)
          updateValue('asset', resp.symbol)
        }}
        options={assets}
      />
      <Input
        type="text"
        id="bankCode"
        label={'Bank Code'}
        message="Inform the bank code, if applicable."
        value={register.bank_code}
        onChange={(value) => updateValue('bank_code', value)}
      />
      <Input
        type="text"
        id="bankName"
        label={'Bank Name'}
        value={register.bank_name}
        onChange={(value) => updateValue('bank_name', value)}
      />
      <Input
        type="text"
        id="branch"
        label={'branch'}
        value={register.branch}
        onChange={(value) => updateValue('branch', value)}
        message='Inform the bank branch, if applicable.'
      />
      <Input
        type="text"
        id="city"
        label={'City'}
        value={register.city}
        onChange={(value) => updateValue('city', value)}
      />
      <Input
        type="text"
        id="state"
        label={'State'}
        value={register.state}
        onChange={(value) => updateValue('state', value)}
      />
      {allowedAssetToInstantPayment.includes(register.asset) && (
        <>
          <Select
            id='instantPaymentType'
            value={register.instant_payment_type}
            label='Instant Payment Type'
            placeholder="Choose an instant payment type, If supported by recipient's bank."
            options={instantPaymentTypes()}
            onChange={(value) => updateValue('instant_payment_type', value)}
          />
          <Input
            type="text"
            id="instantPayment"
            label={'Instant Payment'}
            message="Insert the instant payment hash"
            value={register.instant_payment}
            onChange={(value) => updateValue('instant_payment', value)}
          />
        </>
      )}
      <button onClick={createSubAccountBank} className='payoutCreateBank--button'>Save Bank Account</button>
    </div>
  )
}

export default PayoutCreateBank