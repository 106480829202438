import { useSearchParams } from "react-router-dom"

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN as string;

export default () => {
  const [searchParams] = useSearchParams()

  const state = searchParams.get("state")

  const continueUrl = `https://${auth0Domain}/continue?state=${state}`

  return continueUrl
}