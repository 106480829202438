import React, { useState } from 'react'
import BasicTemplate from '../BasicTemplate'
import HomeCurrency from './HomeCurrency';

import { TLocalQuote } from '../../helpers/types';

import './index.scss'

import BrazilFrag from '../../assets/flags/brazil.png';
import ArgentinaFrag from '../../assets/flags/argentina.png';
import MexicanFrag from '../../assets/flags/mexican.png';
import NorwayFrag from '../../assets/flags/norway.png';

import BtcFrag from '../../assets/flags/btc.png';
import EthFrag from '../../assets/flags/eth.png';
import UsdtFrag from '../../assets/flags/usdt.png';
import XrpFrag from '../../assets/flags/xrp.png';
import UsdrifFrag from '../../assets/flags/usdrif.png';

const baseCurrency: TLocalQuote[] = [
  {
    currency: 'BRL',
    name: 'Brazilian Real',
    image: BrazilFrag,
    buyPayload: {
      network: "tron",
      product: "exchange",
      source_amount: 0,
      source_asset: "BRL",
      target_amount: 1,
      target_asset: "USDT"
    },
    sellPayload: {
      network: "tron",
      product: "exchange",
      source_amount: 1,
      source_asset: "USDT",
      target_amount: 0,
      target_asset: "BRL"
    },
  },
  {
    currency: 'MXN',
    name: 'Mexican Peso',
    image: MexicanFrag,
    buyPayload: {
      network: "tron",
      product: "exchange",
      source_amount: 0,
      source_asset: "MXN",
      target_amount: 1,
      target_asset: "USDT"
    },
    sellPayload: {
      network: "tron",
      product: "exchange",
      source_amount: 1,
      source_asset: "USDT",
      target_amount: 0,
      target_asset: "MXN"
    }
  },
  {
    currency: 'ARS',
    name: 'Argentine Peso',
    image: ArgentinaFrag,
    buyPayload: null,
    sellPayload: {
      network: "tron",
      product: "exchange",
      source_amount: 1,
      source_asset: "USDT",
      target_amount: 0,
      target_asset: "ARS"
    }
  },
  {
    currency: 'NOK',
    name: 'Norwegian Krone',
    image: NorwayFrag,
    buyPayload: {
      network: "tron",
      product: "exchange",
      source_amount: 0,
      source_asset: "NOKD",
      target_amount: 1,
      target_asset: "USDT"
    },
    sellPayload: {
      network: "tron",
      product: "exchange",
      source_amount: 1,
      source_asset: "USDT",
      target_amount: 0,
      target_asset: "NOKD"
    }
  },
  {
    currency: 'BTC',
    name: 'Bitcoin',
    image: BtcFrag,
    sellPayload: {
      network: "bitcoin",
      product: "exchange",
      source_amount: 1,
      source_asset: "BTC",
      target_amount: 0,
      target_asset: "USD"
    },
    buyPayload: null
  },
  {
    currency: 'ETH',
    name: 'Ethereum',
    image: EthFrag,
    sellPayload: {
      network: "erc-20",
      product: "exchange",
      source_amount: 1,
      source_asset: "ETH",
      target_amount: 0,
      target_asset: "USD"
    },
    buyPayload: null
  },
  {
    currency: 'USDT',
    name: 'Tether',
    image: UsdtFrag,
    sellPayload: {
      network: "tron",
      product: "exchange",
      source_amount: 1,
      source_asset: "USDT",
      target_amount: 0,
      target_asset: "USD"
    },
    buyPayload: null
  },
  {
    currency: 'USDRIF',
    name: 'USDRIF',
    image: UsdrifFrag,
    sellPayload: {
      network: "rsk",
      product: "exchange",
      source_amount: 1,
      source_asset: "USDRIF",
      target_amount: 0,
      target_asset: "USD"
    },
    buyPayload: null
  },
  {
    currency: 'XRP',
    name: 'Ripple',
    image: XrpFrag,
    sellPayload: {
      network: "ripple",
      product: "exchange",
      source_amount: 1,
      source_asset: "XRP",
      target_amount: 0,
      target_asset: "USD"
    },
    buyPayload: null
  },
];

export default function Home () {
  const [currentTab, setCurrentTab] = useState<number>(1); // 1 = Fiat, 2 = Crypto

  const handleChange = (newValue: number) => {
    setCurrentTab(newValue)
  }

  const isSelected = (value: number) => value === currentTab ? 'home__card__menu--selected' : ''


  const filteredCurrency = (currencyName: string) => {
    return baseCurrency.find((v: TLocalQuote) => v.currency === currencyName) as TLocalQuote
  }

  return (
    <BasicTemplate>
      <div className='home'>
        <div className='home__card'>
          <h1 className='home__card__title'>Live Quotes</h1>
          <div className='home__card__menu'>
            <div className={'home__card__menu__option ' + isSelected(1)} onClick={() => handleChange(1)}>
              <span>Fiat</span>
            </div>
            <div className={'home__card__menu__option ' + isSelected(2)} onClick={() => handleChange(2)}>
              <span>Crypto</span>
            </div>
          </div>
          {currentTab === 1 ? (
            <div className='home__card__option--fiat'>
              <HomeCurrency currentTab={currentTab} currentQuote={filteredCurrency('BRL')} />
              <div className='home__card__option--fiat__divide'></div>
              <HomeCurrency currentTab={currentTab} currentQuote={filteredCurrency('MXN')} />
              <div className='home__card__option--fiat__divide'></div>
              <HomeCurrency currentTab={currentTab} currentQuote={filteredCurrency('ARS')} />
              <div className='home__card__option--fiat__divide'></div>
              <HomeCurrency currentTab={currentTab} currentQuote={filteredCurrency('NOK')} />
            </div>
          ) : (
            <div className='home__card__option--crypto'>
              <HomeCurrency currentTab={currentTab} currentQuote={filteredCurrency('BTC')} />
              <div className='home__card__option--crypto__divide'></div>
              <HomeCurrency currentTab={currentTab} currentQuote={filteredCurrency('ETH')} />
              <div className='home__card__option--crypto__divide'></div>
              <HomeCurrency currentTab={currentTab} currentQuote={filteredCurrency('USDT')} />
              <div className='home__card__option--crypto__divide'></div>
              <HomeCurrency currentTab={currentTab} currentQuote={filteredCurrency('USDRIF')} />
              <div className='home__card__option--crypto__divide'></div>
              <HomeCurrency currentTab={currentTab} currentQuote={filteredCurrency('XRP')} />
            </div>
          )}
        </div>
      </div>
    </BasicTemplate>
  )
}