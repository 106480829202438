import React, { FC } from 'react'
import IconStatusPositive from '../../assets/status-positive.png'
import IconStatusInfo from '../../assets/status-info.png'
import IconStatusNegative from '../../assets/status-negative.png'
import IconStatusNeutral from '../../assets/status-neutral.png'
import IconStatusUrgent from '../../assets/status-urgent.png'
import IconStatusWarning from '../../assets/status-warning.png'

import './index.scss'

type statusColor = 'positive' | 'negative' | 'warning' | 'info' | 'neutral' | 'urgent'

type Props = {
  name: string;
  status: statusColor;
  showIcon?: boolean;
}

const TagStatus: FC<Props> = ({ name, status, showIcon }) => {

  const colorClass = () => {
    switch (status) {
      case 'positive':
        return 'tagStatus--positive'
      case 'info':
        return 'tagStatus--info'
      case 'negative':
        return 'tagStatus--negative'
      case 'neutral':
        return 'tagStatus--neutral'
      case 'urgent':
        return 'tagStatus--urgent'
      case 'warning':
        return 'tagStatus--warning'
      default:
        return 'tagStatus--neutral'
    }
  }

  const icon = () => {
    switch (status) {
      case 'positive':
        return IconStatusPositive
      case 'info':
        return IconStatusInfo
      case 'negative':
        return IconStatusNegative
      case 'neutral':
        return IconStatusNeutral
      case 'urgent':
        return IconStatusUrgent
      case 'warning':
        return IconStatusWarning
      default:
        return IconStatusNeutral;
    }
  }

  return (
    <div className='tagStatus'>
      <div className={'tagStatus__grid ' + colorClass()}>
        <p>{name.charAt(0).toUpperCase() + name.slice(1)}</p>
        {showIcon && (<img src={icon()} alt="icon status" />)}
      </div>
    </div>
  )
}

export default TagStatus;