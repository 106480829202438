import React, { FC, ReactNode } from 'react';
import ProgressLine from '../../components/ProgressLine';

import './BasicLayout.scss';

type Props = {
  actualProgress: number;
  children: ReactNode;
};

export const BasicLayout: FC<Props> = ({ children, actualProgress }: Props) => {
  return (
    <div className="basicLayout">
      <div className="basicLayout__header">
        <div className="basicLayout__header__container">
          <div className="basicLayout__header__container--img" ></div>
          <div className='basicLayout__header__container--language'>
            <div className="header__language"></div>
          </div>
          <div className='basicLayout__header__container--progress'>
            <ProgressLine actual={actualProgress} />
          </div>
        </div>
      </div>
      <div className="basicLayout__body">{children}</div>
    </div>
  );
};
