import React, { useState, useEffect, useRef } from 'react';
import { BasicLayout } from './BasicLayout';
import {
  getUserAccountDocumentType,
  postUserAccountDocument,
} from '../../api/gateway';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { TUserAccountDocumentType, UploadFile } from '../../helpers/types';
import { PageLoader } from '../../components/PageLoader/PageLoader';
import useAuth0Continue from '../../helpers/useAuth0Continue'

import './FormDocuments.scss';

type DocumentType = {
  fileTypeID: string;
  fileList: FileList[];
};

const FormDocuments = () => {
  const [accountDocuments, setAccountDocuments] =
    useState<TUserAccountDocumentType>([]);
  const [uploadFiles, setUploadFiles] = useState<DocumentType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams()
  const inputRef = useRef<HTMLInputElement[]>([]);
  const navigate = useNavigate();
  const continueUrl = useAuth0Continue()

  const auth0ID = searchParams.get("auth0_id")

  const { typeAccount, accountID } = useParams();

  useEffect(() => {
    (async () => {
      await getUserAccountDocumentType().then(({ data }) => {
        const { list } = data;
        const provData = list.filter(({ account_type_name }: { account_type_name: string }) => account_type_name === typeAccount) as TUserAccountDocumentType;

        const dataPrev: DocumentType[] = [];
        provData.map((v) => {
          dataPrev.push({ fileTypeID: v.id, fileList: [] as FileList[] });
        });

        setAccountDocuments(provData);
        setUploadFiles(dataPrev);
      });
    })();
  }, []);

  const description = (name: string) => {
    return name == 'Shareholders IDs:'
      ? 'We require this document to be provided for all shareholders holding at least 25%'
      : '';
  };

  const fileAccept = (name: string) => {
    name = name.toLowerCase()
    if (name === 'brand logo') {
      return "image/png, image/jpeg"
    }
    return "image/png, image/jpeg, .pdf"
  }

  const handlerSubmit = async () => {
    if (!auth0ID) return

    setIsLoading(true);
    if (!accountID) return;

    let totalToUpload = 0
    let filesUploaded = 0

    uploadFiles.forEach(async (v) => {
      totalToUpload += v.fileList.length
      Array.from(v.fileList).forEach(async (f) => {
        const reader = new FileReader();
        reader.readAsDataURL(f[0]);

        reader.onload = async () => {
          if (reader.result) {
            const extension = f[0].name.split('.');

            const payload: UploadFile = {
              auth0_id: auth0ID,
              file: reader.result.toString().split(';base64,')[1],
              file_extension: extension[extension.length - 1],
              document_type_id: v.fileTypeID,
            };

            await postUserAccountDocument(payload);
            filesUploaded += 1
          }
        };
      });
    });

    const interval = setInterval(() => {
      if (filesUploaded == totalToUpload) {
        clearInterval(interval)
        setIsLoading(false);
        navigate("/success", { state: { auth0PostLogin: continueUrl } })
      }
    }, 1000)
  };

  const filterFiles = (id: string) => {
    return uploadFiles.filter((v) => v.fileTypeID === id)[0].fileList;
  };

  const handleRemoveItem = (id: string, index: number) => {
    const updatedFile = [...uploadFiles];
    updatedFile.map((uf) => {
      if (id === uf.fileTypeID) {
        return uf.fileList.splice(index, 1);
      }
    });
    setUploadFiles(updatedFile);
  };

  return (
    <BasicLayout actualProgress={3}>
      <div className="formDocuments">
        <h1>Corporate Documents</h1>
        <p>Please upload the following documents:</p>
        <div className="formDocuments--separate"></div>
        <div className="formDocuments__list">
          {accountDocuments.map((v, index1) => {
            return (
              <div key={index1}>
                <div className="uploadFile">
                  <label htmlFor={v.id}>{v.name}</label>
                  <p>{description(v.name)}</p>

                  {filterFiles(v.id).map((f, index2) => {
                    return (
                      <div key={index2} className="uploadFile__file">
                        <span>{f[0].name}</span>
                        <button
                          onClick={() => {
                            handleRemoveItem(v.id, index2);
                          }}
                        ></button>
                      </div>
                    );
                  })}
                  <div
                    onClick={() => inputRef.current[index1].click()}
                    className="uploadFile__icon"
                  >
                    <div className="uploadFile__icon--svg"></div>
                    <span>{v.description}</span>
                  </div>
                  <input
                    accept={fileAccept(v.name)}
                    disabled={isLoading}
                    ref={(element) =>
                      (inputRef.current[index1] = element as HTMLInputElement)
                    }
                    id={v.id}
                    type="file"
                    onChange={(e) => {
                      const updatedFile = [...uploadFiles];
                      updatedFile.map((uf) => {
                        if (v.id === uf.fileTypeID) {
                          return (
                            e.target.files && uf.fileList.push(e.target.files)
                          );
                        }
                      });
                      setUploadFiles(updatedFile);
                    }}
                  />
                </div>
                <div className="formDocuments--separate"></div>
              </div>
            );
          })}
          <div className="formDocuments__action">
            {isLoading ? (
              <PageLoader />
            ) : (
              <button
                disabled={uploadFiles.length === 0}
                onClick={handlerSubmit}
              >
                Upload documents
              </button>
            )}
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default FormDocuments;
