import React, { FC, useEffect, useState } from 'react'
import { Select } from '../../components/Select';
import Banner from '../../components/Banner';
import { getAssetByType, getNetworkByAssetSymbol, postSubAccountBankAccountShared, postSubAccountWalletAddressShared } from '../../api/gateway';
import { useAuth0 } from '@auth0/auth0-react';
import { TAsset, TBankAccount, TBannerLocal, TWalletAddress } from '../../helpers/types';

import './SubAccountDetailsDeposit.scss'

interface depositInterface {
  sourceAsset: string;
  network: string;
}

const initialState: depositInterface = {
  sourceAsset: '',
  network: '',
}

interface Values {
  id: string | number;
  value: string;
}

type Props = {
  subAccountId: string;
}

const initialShowBanner: TBannerLocal = { show: false, type: 'success', title: '', text: '' }

const SubAccountDetailsDeposit: FC<Props> = ({ subAccountId }: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [depositDetails, setDepositDetails] = useState<depositInterface>(initialState);
  const [assets, setAssets] = useState<TAsset[]>([]);
  const [assetsOption, setAssetsOption] = useState<Values[]>([]);
  const [networks, setNetworks] = useState<Values[]>([]);
  const [showBanner, setShowBanner] = useState<TBannerLocal>(initialShowBanner);
  const [walletAddress, setWalletAddress] = useState<TWalletAddress | null>(null)
  const [bankAccount, setBankAccount] = useState<TBankAccount | null>(null)

  const updateValue = (key: string, value: string | number | undefined) => {
    setDepositDetails((deposit) => ({
      ...deposit,
      [key]: value,
    }));
  };

  const handleGetAssetByType = () => {
    getAccessTokenSilently().then(async (token) => {
      await getAssetByType(token, '').then(({ data }) => {
        const response = []
        for (let i = 0; i < data.assets.length; i++) {
          if (data.assets[i].type === 'fiat' || data.assets[i].type === 'crypto') {
            response.push({
              id: data.assets[i].symbol,
              value: data.assets[i].symbol,
            })
          }
        }
        response.unshift({ id: '', value: 'Choose one currency' })
        setAssetsOption(response)
        setAssets(data.assets)
      })
    })
  }

  const handleGetNetworkByAsset = (asset: string) => {
    getAccessTokenSilently().then(async (token) => {
      getNetworkByAssetSymbol(token, asset).then(({ data }) => {
        const response = data.networks.map((network: { id: string, name: string }) => {
          return {
            id: network.id,
            value: network.name
          }
        })
        response.unshift({ id: '', value: 'Choose one network' })
        setNetworks(response)
      })
    })
  }

  useEffect(() => {
    handleGetAssetByType()
  }, [])

  const handleGenerateDepositDetails = () => {
    getAccessTokenSilently().then(async (token) => {
      setShowBanner(initialShowBanner)
      if (isFiat()) {
        await postSubAccountBankAccountShared(token, subAccountId, depositDetails.sourceAsset).then(({ data }) => {
          setBankAccount(data)
        }).catch(() => {
          setShowBanner({ show: true, type: 'error', title: 'No bank account found', text: 'Unable to retrieve deposit details. Please reach out to our team' })
        })
      } else {
        await postSubAccountWalletAddressShared(token, subAccountId, depositDetails.sourceAsset, depositDetails.network).then(({ data }) => {
          setWalletAddress(data)
        }).catch(() => {
          setShowBanner({ show: true, type: 'error', title: 'No bank account found', text: 'Unable to retrieve deposit details. Please reach out to our team' })
        })
      }
    })
  }

  const isFiat = () => assets.find((asset) => asset.symbol === depositDetails.sourceAsset)?.type === 'fiat'

  const disableButton = () => {
    const isFiat = assets.find((asset) => asset.symbol === depositDetails.sourceAsset)?.type === 'fiat'

    if (isFiat && depositDetails.sourceAsset !== '') return false
    if (!isFiat && depositDetails.sourceAsset !== '' && depositDetails.network !== '') return false
    return true
  }

  return (
    <div className='subAccDeposit'>
      <h2 className='subAccDeposit__title'>Deposit Details</h2>
      <div className='subAccDeposit__form'>
        <Select
          id='assets'
          value={depositDetails.sourceAsset}
          label={'Source Asset'}
          placeholder='Choose the currency you would like to deposit'
          options={assetsOption}
          onChange={(v) => {
            setNetworks([])
            setWalletAddress(null)
            setBankAccount(null)
            const isFiat = assets.find((asset) => asset.symbol === v)?.type === 'fiat'
            updateValue('sourceAsset', v)
            if (!isFiat) handleGetNetworkByAsset(v)
          }}
        />
        {!isFiat() && (
          <Select
            disabled={depositDetails.sourceAsset === ''}
            id='network'
            value={depositDetails.network}
            label='Network'
            placeholder='Choose the preferred currency for the deposit.'
            options={networks}
            onChange={(value) => updateValue('network', value)}
          />
        )}
        <div className='subAccDeposit__action'>
          <button
            disabled={disableButton()}
            onClick={handleGenerateDepositDetails}
            className='subAccDeposit__action--submit'
          >Generate Deposit Details</button>
        </div>
      </div>
      <div className='subAccDeposit__result'>
        <h2>Deposit Details</h2>
        {bankAccount ? (
          <>
            <div className='subAccDeposit__result--item'>
              <span>Bank Code / BIC</span>
              <p>{bankAccount.bank_code}</p>
            </div>
            <div className='subAccDeposit__result--item'>
              <span>Account Number / IBAN</span>
              <p>{bankAccount.account}</p>
            </div>
            <div className='subAccDeposit__result--item'>
              <span>Branch</span>
              <p>{bankAccount.branch}</p>
            </div>
            <div className='subAccDeposit__result--item'>
              <span>Street line</span>
              <p>{bankAccount.street_line}</p>
            </div>
            <div className='subAccDeposit__result--item'>
              <span>Country</span>
              <p>{bankAccount.country}</p>
            </div>
            <div className='subAccDeposit__result--item'>
              <span>Instant Payment Type</span>
              <p>{bankAccount.instant_payment_type}</p>
            </div>
            <div className='subAccDeposit__result--item'>
              <span>Instant Payment</span>
              <p>{bankAccount.instant_payment}</p>
            </div>
          </>
        ) : walletAddress ? (
          <>
            <div className='subAccDeposit__result--item'>
              <span>Wallet Address</span>
              <p>{walletAddress.address}</p>
            </div>
            {walletAddress.address_tag && (
              <div className='subAccDeposit__result--item'>
                <span>Tag</span>
                <p>{walletAddress.address_tag}</p>
              </div>
            )}
          </>
        ) : null}
        <Banner
          type={showBanner.type}
          title={showBanner.title}
          text={showBanner.text}
          show={showBanner.show}
          onClose={() => setShowBanner(initialShowBanner)}
        />
      </div>
    </div>
  )
}

export default SubAccountDetailsDeposit;