import React, { FC } from 'react'
import './index.scss'

import StatusNeutral from '../../assets/status-neutral.png'

type Props = {
  text: string
}

const Tooltip: FC<Props> = ({ text }) => {
  return (
    <div className="tooltip">
      <img src={StatusNeutral} alt="icon" />
      <span className="tooltiptext">{text}</span>
    </div>
  )
}

export default Tooltip