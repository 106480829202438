import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getOrdersDetails } from '../../api/gateway';
import { useAuth0 } from '@auth0/auth0-react';
import { IOrderDetails } from '../../helpers/types';
import dayjs from 'dayjs';
import HourglassIcon from 'remixicon/icons/System/hourglass-2-fill.svg';
import CheckLineIcon from 'remixicon/icons/System/check-line.svg';
import { PageLoader } from '../../components/PageLoader/PageLoader';
import BasicTemplate from '../BasicTemplate';
import useBgStatus from '../../helpers/useBgStatus';

import './TransactionDetails.scss';

const emptyOrder: IOrderDetails = {
  id: '',
  target_asset: '',
  target_amount: 0,
  source_amount: 0,
  source_asset: '',
  total_fee: 0,
  description: '',
  message: '',
  recipient_email: '',
  status: '',
  settlement_status: '',
  created_at: '',
  timelines: [],
};

const TransactionDetails = () => {
  const [order, setOrder] = useState<IOrderDetails>(emptyOrder);
  const { transactionId } = useParams();
  const bgStatus = useBgStatus(order.status)

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      if (!transactionId) return;
      await getAccessTokenSilently()
        .then(async (token) => {
          await getOrdersDetails(token, transactionId).then(({ data }) => {
            setOrder(data);
          });
        })
        .catch(console.error);
    })();

    return () => {
      setOrder(emptyOrder);
    };
  }, []);

  const formatDate = (date: string) => {
    return (
      dayjs(date).format('D MMMM, YYYY') +
      ' at ' +
      dayjs(date).format('hh:mm a')
    );
  };

  if (!order.id) {
    return (
      <div className="loading">
        <PageLoader></PageLoader>
      </div>
    );
  }

  return (
    <BasicTemplate>
      <div className="transactionDetails">
        <div className="transactionDetails__header">Order Details</div>
        <div className="transactionDetails__container">
          <section className="transactionDetails__amount-box">
            <h3 className="transactionDetails__amount-box-title">Order Details</h3>
            <div className="transactionDetails__amount">
              Amount:{' '}
              <span>
                {order.target_asset} {order.target_amount.toFixed(2)}
              </span>
            </div>
            <div className="transactionDetails__personal-details">
              <div className="transactionDetails__detail">
                Your internal reference:
                <span style={{ fontStyle: 'italic' }}>{order.description}</span>
              </div>
              <div className="transactionDetails__detail">
                Recipient&apos;s e-mail address:{' '}
                <span>{order.recipient_email}</span>
              </div>
              <div className="transactionDetails__detail">
                Created at:
                <span>{formatDate(order.created_at)}</span>
              </div>
              <div className="transactionDetails__detail">
                Status:
                <div style={{ backgroundColor: bgStatus, width: 100 }}>
                  {order.status}
                </div>
              </div>
              <div className="transactionDetails__detail">
                Settlement status:
                <span>
                  {order.settlement_status
                    ? order.settlement_status
                    : 'Not yet settled'}
                </span>
              </div>
            </div>
          </section>
          <section className="transactionDetails__timeline-container">
            <div className="transactionDetails__timeline-text">
              Timeline
            </div>
            <div className="transactionDetails__timeline-box">
              <div className="transactionDetails__timeline-details">
                {order.timelines.map((v, index) => {
                  return (
                    <div
                      key={index}
                      className="transactionDetails__timeline-grid"
                      style={{ opacity: !v.executed ? 0.4 : 1 }}
                    >
                      {v.executed ? (
                        <img src={CheckLineIcon} alt="done icon" />
                      ) : (
                        <img src={HourglassIcon} alt="hourglass icon" />
                      )}
                      <div className="transactionDetails__timeline-detail">
                        {v.order_step}
                        {v.executed ? (
                          <span>{formatDate(v.executed_at)}</span>
                        ) : (
                          <span>awaiting</span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      </div>
    </BasicTemplate>
  );
};

export default TransactionDetails;
