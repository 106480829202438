import React, { useState, useEffect } from 'react';
import {
  GridCellParams,
  GridColDef,
} from '@mui/x-data-grid';
import BasicTemplate from '../BasicTemplate';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { clsx } from 'clsx';
import BoxGrid from '../../components/BoxGrid';
import BoxGridAction from '../../components/BoxGridAction';
import Balance from '../../components/Balance';
import { useAuth0 } from '@auth0/auth0-react';
import { getSettlement } from '../../api/gateway';
import { TSettlementHistory } from '../../helpers/types';

import './index.scss';
import useGetBalance from '../../helpers/useGetBalance';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 100, headerAlign: 'left' },
  {
    field: `amount`,
    headerName: 'Amount',
    minWidth: 80,
    headerAlign: 'left',
    sortable: true,
    renderCell: (params) => {
      return (
        <span style={{ fontWeight: 500, fontSize: 12, lineHeight: 16 }}>
          {params.row.target_asset} {params.row.target_amount}
        </span>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    headerAlign: 'left',
    align: 'center',
    cellClassName: (params: GridCellParams) => {
      if (params.value == null) {
        return '';
      }

      return clsx('super-app', {
        confirmed: params.value === 'confirmed',
        pending: params.value === 'pending',
        canceled: params.value === 'canceled',
        open: params.value === 'status',
      });
    },
  },
  {
    field: 'number_of_orders_associated',
    headerName: 'Orders Associated',
    width: 150,
    headerAlign: 'left',
    align: 'center'
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 120,
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
  },
  {
    field: 'updated_at',
    headerName: 'Processed At',
    width: 120,
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
  },
  {
    field: 'action',
    headerName: '',
    sortable: false,
    renderCell: (params) => {
      return <BoxGridAction title='Details' route={`/settlement-details/${params.id.toString()}`}/>;
    },
  },
];

const columnsDesktop: GridColDef[] = [
  ...columns,
  {
    field: 'action',
    headerName: '',
    sortable: false,
    renderCell: (params) => {
      return <BoxGridAction title='Details' route={`/settlement-details/${params.id.toString()}`}/>;
    },
  },
];

const columnsMobile: GridColDef[] = [
  ...columns,
];

type SearchStatus = 'open' | 'pending' | 'confirmed' | 'canceled' | '';

const SettlementHistory = () => {
  const {getAccessTokenSilently} = useAuth0();
  const [search, setSearch] = useState('');
  const [searchStatus, setSearchStatus] = useState<SearchStatus>('');
  const [history, setHistory] = useState<TSettlementHistory[]>([])
  const {number_of_orders_associated: numberOfOrderAssociated} = useGetBalance()

  const navigate = useNavigate();

  useEffect(() => {
  (async () => {
    await getAccessTokenSilently().then(async token => {
      await getSettlement(token).then(({data}) => {
        setHistory(data.list)
      })
    })
  })()
  }, [])
  
  return (
    <BasicTemplate>
      <div className="settlementHistory">
        <div className="settlementHistory__header">Settlement Overview</div>
        <div className="settlementHistory__details-container">
          <h3>Settlement</h3>
          <section>
            <div className="settlementHistory__details-title">
              You have <b>{numberOfOrderAssociated}</b> orders not settled yet.
            </div>
            <div className="settlementHistory__details-title">
              The total balance available for settlement is <b><Balance/></b>
            </div>
          </section>
          <section>
            <button onClick={() => navigate('/settlement')}>More info</button>
          </section>
        </div>
        <div className="settlementHistory__table-container">
          <div className="settlementHistory__table-header">
            <div className="settlementHistory__table-title">
              Settlement History
            </div>
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search for settlement ID"
            />
            <div className="settlementHistory__table-dropdown">
              <select
                value={searchStatus}
                onChange={({ target }) =>
                  setSearchStatus(target.value as SearchStatus)
                }
                name="status"
                id="status"
              >
                <option value="">All status</option>
                <option value="open">Open</option>
                <option value="pending">Pending</option>
                <option value="confirmed">Confirmed</option>
                <option value="canceled">Canceled</option>
              </select>
            </div>
          </div>
          <BoxGrid
            rows={history}
            columnsDesktop={columnsDesktop}
            columnsMobile={columnsMobile}
            routeToDetails='/settlement-details/'
          />
        </div>
      </div>
    </BasicTemplate>
  );
};

export default SettlementHistory;
