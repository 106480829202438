import React, { useEffect, useState } from 'react';
import { createAuthCredentials, getAuthCredentials, getCallback, postCallback, putCallback } from '../../api/gateway';
import { useAuth0 } from '@auth0/auth0-react';
import { SnackbarType, TCallback, TCredential } from '../../helpers/types';
import { Input } from '../../components/Input';
import SnackBar from '../../components/SnackBar';
import './index.scss';

const emptyCredential: TCredential = {
  id: null,
  client_id: null,
  client_secret: null,
};

type callbackType = {
  url: string
  key: string
  value: string
}

const emptyCallback = {
  url: '',
  key: '',
  value: ''
}

const emptySnackBar: SnackbarType = {
  open: false,
  title: '',
  severity: 'success',
};

const Integrations = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [credential, setCredential] = useState<TCredential>(emptyCredential);
  const [sync, setSync] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [callback, setCallback] = useState<callbackType>(emptyCallback);
  const [snackBar, setSnackBar] = useState<SnackbarType>(emptySnackBar);

  const getCredentials = async () => {
    await getAccessTokenSilently().then(async (token) => {
      await getAuthCredentials(token).then(({ data }) => {
        if (data) {
          setCredential(data);
        }
        setSync(true);
      });
    });
  };

  const getCallbacks = async () => {
    await getAccessTokenSilently().then(async (token) => {
      await getCallback(token).then(({ data }) => {
        if (data) {
          setCallback(callbackDTO(data));
        }
      });
    });
  };

  const updateCallbackValue = (key: string, value: string) => {
    setCallback(() => ({
      ...callback,
      [key]: value,
    }));
  };

  const createCredentials = async () => {
    await getAccessTokenSilently().then(async (token) => {
      setLoading(true);
      await createAuthCredentials(token).then(({ data }) => {
        setCredential(data);
        setSync(true);
        setSnackBar({
          open: true,
          title: 'Credentials created successfully!',
          severity: 'success',
        });
      });
    });
    setLoading(false);
  };

  const handleSaveCallback = async () => {
    const data: TCallback = {
      enable_event: ['*'],
      is_active: true,
      metadata: [
        {
          key: callback.key,
          value: callback.value,
        }
      ],
      url: callback.url
    }

    await getAccessTokenSilently().then(async (token) => {
      if (callback.url === '') {
        await postCallback(token, data).then(({ data }) => {
          setCallback(callbackDTO(data));
          setSnackBar({
            open: true,
            title: 'Token created successfully!',
            severity: 'success',
          });
        });
      } else {
        await putCallback(token, data).then(({ data }) => {
          setCallback(callbackDTO(data));
          setSnackBar({
            open: true,
            title: 'Token updated successfully!',
            severity: 'success',
          });
        });
      }
    });
  };

  const callbackDTO = (payload: TCallback): callbackType => {
    return {
      key: payload.metadata ? payload.metadata[0].key : '',
      value: payload.metadata ? payload.metadata[0].value : '',
      url: payload.url
    }
  }

  useEffect(() => {
    getCredentials();
    getCallbacks()
  }, []);

  return (
    <>
      <SnackBar {...snackBar} clear={() => setSnackBar(emptySnackBar)} />
      <div className="integrations">
        <h3 className="integrations__title" style={{ padding: 0 }}>Credentials</h3>
        <div className='integrations__line'></div>
        {credential.client_id ? (
          <div className="integrations__card">
            <p>
              Please use the following credentials to obtain Access Tokens that
              must be used in the header of your API requests:
            </p>
            <div className="integrations__card__info">
              <div className="integrations__card__info__grid">
                <span className="integrations__card__info__grid--title">Client ID:</span><br />
                <span className="integrations__card__info__grid--text">{credential.client_id}</span>
              </div>
              <div className="integrations__card__info__grid">
                <span className="integrations__card__info__grid--title">Secret:</span><br />
                <span className="integrations__card__info__grid--text">{credential.client_secret}</span>
              </div>
            </div>
            <p>
              Store your Client ID and Secret safely. In the event of any suspicion regarding unauthorized access to these credentials, we urge you to contact our dedicated team to revoke your credentials.
            </p>
            <p>
              For technical support, please consult our Authentication section in our documentation or reach out to our support team.
            </p>
          </div>
        ) : (
          <div className="integrations__card">
            <p>
              To initiate your usage of our APIs, you must generate a{' '}
              <b>Client ID</b> and <b>Secret</b>. With these credentials you
              will be able to get Access Tokens, which must be included in the
              header of your API requests for authentication purposes.
            </p>
            <p>
              Please store your Client ID and Secret safely. In the event of any
              suspicion regarding unauthorized access to these credentials, we
              urge you to contact our dedicated team to revoke your credentials.
            </p>
            <p>
              For technical support, please consult our Authentication section
              at{' '}
              <a href="https://docs.betaramps.com">
                https://docs.betaramps.com
              </a>{' '}
              or reach out to our support team.
            </p>
            <div className="integrations__card__actions">
              <button
                onClick={() => createCredentials()}
                disabled={!sync || loading}
              >
                Generate Credentials
              </button>
            </div>
          </div>
        )}
        <div className="integrations__card">
          <h3 className="integrations__title">Webhooks</h3>
          <div className='integrations__line' style={{ margin: '20px 0 20px 0' }}></div>
          <p>
            <a
              href="https://docs.betaramps.com/documentation-beta-ramps/api-guide/webhooks"
              target="_blank"
              rel="noreferrer"
            >
              Click here
            </a>{' '}
            to access our documentation for Webhooks.
          </p>
          <div className='integrations__card__url'>
            <Input
              type="text"
              id="url"
              label={'Callback URL'}
              value={callback.url}
              onChange={(value) => updateCallbackValue('url', value)}
              placeholder={'Type here your URL'}
            />
            <Input
              type="text"
              id="header-key"
              label={'Header Key'}
              value={callback.key}
              onChange={(value) => updateCallbackValue('key', value)}
              placeholder={'Authorization'}
            />
            <Input
              type="text"
              id="header-value"
              label={'Header Value'}
              value={callback.value}
              onChange={(value) => updateCallbackValue('value', value)}
              placeholder={'Type the value here'}
            />
          </div>
          <div className='integrations__card__header'>
            <div className='integrations__card__header__grid'>

            </div>
          </div>
          <div className="integrations__card__actions" style={{ paddingTop: 30 }}>
            <button
              onClick={() => handleSaveCallback()}
              disabled={loading}
              style={{ padding: '10px 70px' }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Integrations;
