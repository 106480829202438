import React, { FC, useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SnackbarType } from '../../helpers/types';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
  open: boolean;
  title: string;
  severity: 'success' | 'error';
  clear: () => void;
};

const emptySnackBar: SnackbarType = {
  open: false,
  title: '',
  severity: 'success',
};

const SnackBar: FC<Props> = (data: Props) => {
  const [snackBar, setSnackBar] = useState<SnackbarType>(data);

  const vertical = 'top';
  const horizontal = 'center';

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar({...emptySnackBar, severity: data.severity});
    data.clear()
  };

  useEffect(() => {
    setSnackBar(data);
  }, [data.open]);

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={snackBar.open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={snackBar.severity}
        sx={{ width: '100%' }}
      >
        {snackBar.title}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
