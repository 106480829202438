import React from 'react'
import useGetBalance from '../../helpers/useGetBalance'
import useFormatAmount from '../../helpers/useFormatAmount'

const Balance = () => {
  const balance = useGetBalance()
  const amount = useFormatAmount(balance.total_amount, balance.asset)

  return <>{amount}</>

}

export default Balance;