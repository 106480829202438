export default [ 
  {"value": "Afghanistan", "id": "AF"}, 
  {"value": "land Islands", "id": "AX"}, 
  {"value": "Albania", "id": "AL"}, 
  {"value": "Algeria", "id": "DZ"}, 
  {"value": "American Samoa", "id": "AS"}, 
  {"value": "AndorrA", "id": "AD"}, 
  {"value": "Angola", "id": "AO"}, 
  {"value": "Anguilla", "id": "AI"}, 
  {"value": "Antarctica", "id": "AQ"}, 
  {"value": "Antigua and Barbuda", "id": "AG"}, 
  {"value": "Argentina", "id": "AR"}, 
  {"value": "Armenia", "id": "AM"}, 
  {"value": "Aruba", "id": "AW"}, 
  {"value": "Australia", "id": "AU"}, 
  {"value": "Austria", "id": "AT"}, 
  {"value": "Azerbaijan", "id": "AZ"}, 
  {"value": "Bahamas", "id": "BS"}, 
  {"value": "Bahrain", "id": "BH"}, 
  {"value": "Bangladesh", "id": "BD"}, 
  {"value": "Barbados", "id": "BB"}, 
  {"value": "Belarus", "id": "BY"}, 
  {"value": "Belgium", "id": "BE"}, 
  {"value": "Belize", "id": "BZ"}, 
  {"value": "Benin", "id": "BJ"}, 
  {"value": "Bermuda", "id": "BM"}, 
  {"value": "Bhutan", "id": "BT"}, 
  {"value": "Bolivia", "id": "BO"}, 
  {"value": "Bosnia and Herzegovina", "id": "BA"}, 
  {"value": "Botswana", "id": "BW"}, 
  {"value": "Bouvet Island", "id": "BV"}, 
  {"value": "Brazil", "id": "BR"}, 
  {"value": "British Indian Ocean Territory", "id": "IO"}, 
  {"value": "Brunei Darussalam", "id": "BN"}, 
  {"value": "Bulgaria", "id": "BG"}, 
  {"value": "Burkina Faso", "id": "BF"}, 
  {"value": "Burundi", "id": "BI"}, 
  {"value": "Cambodia", "id": "KH"}, 
  {"value": "Cameroon", "id": "CM"}, 
  {"value": "Canada", "id": "CA"}, 
  {"value": "Cape Verde", "id": "CV"}, 
  {"value": "Cayman Islands", "id": "KY"}, 
  {"value": "Central African Republic", "id": "CF"}, 
  {"value": "Chad", "id": "TD"}, 
  {"value": "Chile", "id": "CL"}, 
  {"value": "China", "id": "CN"}, 
  {"value": "Christmas Island", "id": "CX"}, 
  {"value": "Cocos (Keeling) Islands", "id": "CC"}, 
  {"value": "Colombia", "id": "CO"}, 
  {"value": "Comoros", "id": "KM"}, 
  {"value": "Congo", "id": "CG"}, 
  {"value": "Congo, The Democratic Republic of the", "id": "CD"}, 
  {"value": "Cook Islands", "id": "CK"}, 
  {"value": "Costa Rica", "id": "CR"}, 
  {"value": "Cote D\"Ivoire", "id": "CI"}, 
  {"value": "Croatia", "id": "HR"}, 
  {"value": "Cuba", "id": "CU"}, 
  {"value": "Cyprus", "id": "CY"}, 
  {"value": "Czech Republic", "id": "CZ"}, 
  {"value": "Denmark", "id": "DK"}, 
  {"value": "Djibouti", "id": "DJ"}, 
  {"value": "Dominica", "id": "DM"}, 
  {"value": "Dominican Republic", "id": "DO"}, 
  {"value": "Ecuador", "id": "EC"}, 
  {"value": "Egypt", "id": "EG"}, 
  {"value": "El Salvador", "id": "SV"}, 
  {"value": "Equatorial Guinea", "id": "GQ"}, 
  {"value": "Eritrea", "id": "ER"}, 
  {"value": "Estonia", "id": "EE"}, 
  {"value": "Ethiopia", "id": "ET"}, 
  {"value": "Falkland Islands (Malvinas)", "id": "FK"}, 
  {"value": "Faroe Islands", "id": "FO"}, 
  {"value": "Fiji", "id": "FJ"}, 
  {"value": "Finland", "id": "FI"}, 
  {"value": "France", "id": "FR"}, 
  {"value": "French Guiana", "id": "GF"}, 
  {"value": "French Polynesia", "id": "PF"}, 
  {"value": "French Southern Territories", "id": "TF"}, 
  {"value": "Gabon", "id": "GA"}, 
  {"value": "Gambia", "id": "GM"}, 
  {"value": "Georgia", "id": "GE"}, 
  {"value": "Germany", "id": "DE"}, 
  {"value": "Ghana", "id": "GH"}, 
  {"value": "Gibraltar", "id": "GI"}, 
  {"value": "Greece", "id": "GR"}, 
  {"value": "Greenland", "id": "GL"}, 
  {"value": "Grenada", "id": "GD"}, 
  {"value": "Guadeloupe", "id": "GP"}, 
  {"value": "Guam", "id": "GU"}, 
  {"value": "Guatemala", "id": "GT"}, 
  {"value": "Guernsey", "id": "GG"}, 
  {"value": "Guinea", "id": "GN"}, 
  {"value": "Guinea-Bissau", "id": "GW"}, 
  {"value": "Guyana", "id": "GY"}, 
  {"value": "Haiti", "id": "HT"}, 
  {"value": "Heard Island and Mcdonald Islands", "id": "HM"}, 
  {"value": "Holy See (Vatican City State)", "id": "VA"}, 
  {"value": "Honduras", "id": "HN"}, 
  {"value": "Hong Kong", "id": "HK"}, 
  {"value": "Hungary", "id": "HU"}, 
  {"value": "Iceland", "id": "IS"}, 
  {"value": "India", "id": "IN"}, 
  {"value": "Indonesia", "id": "ID"}, 
  {"value": "Iran, Islamic Republic Of", "id": "IR"}, 
  {"value": "Iraq", "id": "IQ"}, 
  {"value": "Ireland", "id": "IE"}, 
  {"value": "Isle of Man", "id": "IM"}, 
  {"value": "Israel", "id": "IL"}, 
  {"value": "Italy", "id": "IT"}, 
  {"value": "Jamaica", "id": "JM"}, 
  {"value": "Japan", "id": "JP"}, 
  {"value": "Jersey", "id": "JE"}, 
  {"value": "Jordan", "id": "JO"}, 
  {"value": "Kazakhstan", "id": "KZ"}, 
  {"value": "Kenya", "id": "KE"}, 
  {"value": "Kiribati", "id": "KI"}, 
  {"value": "Korea, Democratic People\"S Republic of", "id": "KP"}, 
  {"value": "Korea, Republic of", "id": "KR"}, 
  {"value": "Kuwait", "id": "KW"}, 
  {"value": "Kyrgyzstan", "id": "KG"}, 
  {"value": "Lao People\"S Democratic Republic", "id": "LA"}, 
  {"value": "Latvia", "id": "LV"}, 
  {"value": "Lebanon", "id": "LB"}, 
  {"value": "Lesotho", "id": "LS"}, 
  {"value": "Liberia", "id": "LR"}, 
  {"value": "Libyan Arab Jamahiriya", "id": "LY"}, 
  {"value": "Liechtenstein", "id": "LI"}, 
  {"value": "Lithuania", "id": "LT"}, 
  {"value": "Luxembourg", "id": "LU"}, 
  {"value": "Macao", "id": "MO"}, 
  {"value": "Macedonia, The Former Yugoslav Republic of", "id": "MK"}, 
  {"value": "Madagascar", "id": "MG"}, 
  {"value": "Malawi", "id": "MW"}, 
  {"value": "Malaysia", "id": "MY"}, 
  {"value": "Maldives", "id": "MV"}, 
  {"value": "Mali", "id": "ML"}, 
  {"value": "Malta", "id": "MT"}, 
  {"value": "Marshall Islands", "id": "MH"}, 
  {"value": "Martinique", "id": "MQ"}, 
  {"value": "Mauritania", "id": "MR"}, 
  {"value": "Mauritius", "id": "MU"}, 
  {"value": "Mayotte", "id": "YT"}, 
  {"value": "Mexico", "id": "MX"}, 
  {"value": "Micronesia, Federated States of", "id": "FM"}, 
  {"value": "Moldova, Republic of", "id": "MD"}, 
  {"value": "Monaco", "id": "MC"}, 
  {"value": "Mongolia", "id": "MN"}, 
  {"value": "Montenegro", "id": "ME"},
  {"value": "Montserrat", "id": "MS"},
  {"value": "Morocco", "id": "MA"}, 
  {"value": "Mozambique", "id": "MZ"}, 
  {"value": "Myanmar", "id": "MM"}, 
  {"value": "Namibia", "id": "NA"}, 
  {"value": "Nauru", "id": "NR"}, 
  {"value": "Nepal", "id": "NP"}, 
  {"value": "Netherlands", "id": "NL"}, 
  {"value": "Netherlands Antilles", "id": "AN"}, 
  {"value": "New Caledonia", "id": "NC"}, 
  {"value": "New Zealand", "id": "NZ"}, 
  {"value": "Nicaragua", "id": "NI"}, 
  {"value": "Niger", "id": "NE"}, 
  {"value": "Nigeria", "id": "NG"}, 
  {"value": "Niue", "id": "NU"}, 
  {"value": "Norfolk Island", "id": "NF"}, 
  {"value": "Northern Mariana Islands", "id": "MP"}, 
  {"value": "Norway", "id": "NO"}, 
  {"value": "Oman", "id": "OM"}, 
  {"value": "Pakistan", "id": "PK"}, 
  {"value": "Palau", "id": "PW"}, 
  {"value": "Palestinian Territory, Occupied", "id": "PS"}, 
  {"value": "Panama", "id": "PA"}, 
  {"value": "Papua New Guinea", "id": "PG"}, 
  {"value": "Paraguay", "id": "PY"}, 
  {"value": "Peru", "id": "PE"}, 
  {"value": "Philippines", "id": "PH"}, 
  {"value": "Pitcairn", "id": "PN"}, 
  {"value": "Poland", "id": "PL"}, 
  {"value": "Portugal", "id": "PT"}, 
  {"value": "Puerto Rico", "id": "PR"}, 
  {"value": "Qatar", "id": "QA"}, 
  {"value": "Reunion", "id": "RE"}, 
  {"value": "Romania", "id": "RO"}, 
  {"value": "Russian Federation", "id": "RU"}, 
  {"value": "RWANDA", "id": "RW"}, 
  {"value": "Saint Helena", "id": "SH"}, 
  {"value": "Saint Kitts and Nevis", "id": "KN"}, 
  {"value": "Saint Lucia", "id": "LC"}, 
  {"value": "Saint Pierre and Miquelon", "id": "PM"}, 
  {"value": "Saint Vincent and the Grenadines", "id": "VC"}, 
  {"value": "Samoa", "id": "WS"}, 
  {"value": "San Marino", "id": "SM"}, 
  {"value": "Sao Tome and Principe", "id": "ST"}, 
  {"value": "Saudi Arabia", "id": "SA"}, 
  {"value": "Senegal", "id": "SN"}, 
  {"value": "Serbia", "id": "RS"}, 
  {"value": "Seychelles", "id": "SC"}, 
  {"value": "Sierra Leone", "id": "SL"}, 
  {"value": "Singapore", "id": "SG"}, 
  {"value": "Slovakia", "id": "SK"}, 
  {"value": "Slovenia", "id": "SI"}, 
  {"value": "Solomon Islands", "id": "SB"}, 
  {"value": "Somalia", "id": "SO"}, 
  {"value": "South Africa", "id": "ZA"}, 
  {"value": "South Georgia and the South Sandwich Islands", "id": "GS"}, 
  {"value": "Spain", "id": "ES"}, 
  {"value": "Sri Lanka", "id": "LK"}, 
  {"value": "Sudan", "id": "SD"}, 
  {"value": "Surivalue", "id": "SR"}, 
  {"value": "Svalbard and Jan Mayen", "id": "SJ"}, 
  {"value": "Swaziland", "id": "SZ"}, 
  {"value": "Sweden", "id": "SE"}, 
  {"value": "Switzerland", "id": "CH"}, 
  {"value": "Syrian Arab Republic", "id": "SY"}, 
  {"value": "Taiwan, Province of China", "id": "TW"}, 
  {"value": "Tajikistan", "id": "TJ"}, 
  {"value": "Tanzania, United Republic of", "id": "TZ"}, 
  {"value": "Thailand", "id": "TH"}, 
  {"value": "Timor-Leste", "id": "TL"}, 
  {"value": "Togo", "id": "TG"}, 
  {"value": "Tokelau", "id": "TK"}, 
  {"value": "Tonga", "id": "TO"}, 
  {"value": "Trinidad and Tobago", "id": "TT"}, 
  {"value": "Tunisia", "id": "TN"}, 
  {"value": "Turkey", "id": "TR"}, 
  {"value": "Turkmenistan", "id": "TM"}, 
  {"value": "Turks and Caicos Islands", "id": "TC"}, 
  {"value": "Tuvalu", "id": "TV"}, 
  {"value": "Uganda", "id": "UG"}, 
  {"value": "Ukraine", "id": "UA"}, 
  {"value": "United Arab Emirates", "id": "AE"}, 
  {"value": "United Kingdom", "id": "GB"}, 
  {"value": "United States", "id": "US"}, 
  {"value": "United States Minor Outlying Islands", "id": "UM"}, 
  {"value": "Uruguay", "id": "UY"}, 
  {"value": "Uzbekistan", "id": "UZ"}, 
  {"value": "Vanuatu", "id": "VU"}, 
  {"value": "Venezuela", "id": "VE"}, 
  {"value": "Viet Nam", "id": "VN"}, 
  {"value": "Virgin Islands, British", "id": "VG"}, 
  {"value": "Virgin Islands, U.S.", "id": "VI"}, 
  {"value": "Wallis and Futuna", "id": "WF"}, 
  {"value": "Western Sahara", "id": "EH"}, 
  {"value": "Yemen", "id": "YE"}, 
  {"value": "Zambia", "id": "ZM"}, 
  {"value": "Zimbabwe", "id": "ZW"} 
  ]