import React, { useEffect, useState } from 'react'
import BasicTemplate from '../BasicTemplate'
import TagStatus from '../../components/TagStatus'
import IconDots from '../../assets/icon-dots.png'
import dayjs from 'dayjs'
import SelectFilter from '../../components/SelectFilter'
import { Link } from 'react-router-dom'
import { getOrders } from '../../api/gateway'
import { useAuth0 } from '@auth0/auth0-react'
import { OrderBy as OrderByType, PaginationType, TOrder } from '../../helpers/types'

import './index.scss'

const allOption = "all"
const completedOption = "completed"
const pendingOption = "pending"
const openOption = "open"

export default function Orders () {
  const { getAccessTokenSilently } = useAuth0();
  const [currentTab, setCurrentTab] = useState<string>(allOption);
  const [orders, setOrders] = useState<TOrder[]>([]);
  const [sortBy, setSortBy] = useState<OrderByType>('created_at');
  const [pagination, setPagination] = useState<PaginationType>({ page: 1, size: 20, total_row: 0, total_page: 0 });

  const isSelected = (value: string) => value === currentTab ? 'orders__card__menu--selected' : ''

  const formatterAmount = (amount: number) => { return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount) }

  const handleStatus = (status: string) => {
    switch (status) {
      case 'open':
        return 'info'
      case 'pending':
        return 'warning'
      case 'confirmed':
        return 'positive'
      case 'canceled':
        return 'negative'
      default:
        return 'neutral';
    }
  }

  const formatDate = (date: string) => {
    return (
      dayjs(date).format('MMMM D, YYYY') +
      ', ' +
      dayjs(date).format('hh:mm A')
    );
  };

  const handleGetOrders = async () => {
    await getAccessTokenSilently().then(async (token) => {
      await getOrders(token, '', sortBy, false, convertStatus(currentTab), '', pagination.page, pagination.size).then(({ data }) => {
        setOrders(data.orders);
        setPagination({ page: data.page, size: data.size, total_row: data.total_row, total_page: data.total_page });
      });
    });
  };

  useEffect(() => {
    handleGetOrders()
  }, [sortBy, currentTab, pagination.page, pagination.size])

  const handleChange = async (newValue: string) => {
    setCurrentTab(newValue)
  }

  const convertStatus = (status: string) => {
    switch (status) {
      case allOption:
        return ''
      case openOption:
        return 'open'
      case pendingOption:
        return 'pending'
      case completedOption:
        return 'confirmed'
      default:
        return '';
    }
  }

  return (
    <BasicTemplate>
      <div className='orders'>
        <div className='orders__card'>
          <div className='orders__card__title'>
            <h1>Orders</h1>
          </div>
          <div className='orders__card__menu'>
            <div className={'orders__card__menu__option ' + isSelected(allOption)} onClick={() => handleChange(allOption)}>
              <span>All</span>
            </div>
            <div className={'orders__card__menu__option ' + isSelected(completedOption)} onClick={() => handleChange(completedOption)}>
              <span>Completed</span>
            </div>
            <div className={'orders__card__menu__option ' + isSelected(pendingOption)} onClick={() => handleChange(pendingOption)}>
              <span>Pending</span>
            </div>
            <div className={'orders__card__menu__option ' + isSelected(openOption)} onClick={() => handleChange(openOption)}>
              <span>Open</span>
            </div>
            <div className='orders__card__menu__filter'>
              <SelectFilter
                id='sortBy'
                title={sortBy}
                value={sortBy}
                onChange={(v) => setSortBy(v as OrderByType)}
                options={[
                  { id: 1, value: 'id', name: 'ID' },
                  { id: 2, value: 'recipient_email', name: 'Recipient email' },
                  { id: 3, value: 'description', name: 'Description' },
                  { id: 4, value: 'created_at', name: 'Created at' },
                ]}
              />
            </div>
          </div>
          <div className='orders__card__table'>
            <table>
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Description</th>
                  <th>Created At</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orders.map((row) => (
                  <tr key={row.id}>
                    <td>{row.sub_account_name}</td>
                    <td>
                      <span className='orders__card__table--amount'>{formatterAmount(row.target_amount)}</span>
                      <span className='orders__card__table--currency'>{row.target_asset}</span>
                    </td>
                    <td>
                      <TagStatus name={row.status} status={handleStatus(row.status)} showIcon={true} />
                    </td>
                    <td>{row.description}</td>
                    <td>{formatDate(row.created_at)}</td>
                    <td>
                      <Link to={`/order-details/${row.id}`}>
                        <img src={IconDots} alt="order details button" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='orders__card__action'>
            <span>{pagination.total_row} results</span>
            <div className='orders__card__action--button'>
              <button
                disabled={pagination.page === pagination.total_page}
                onClick={() => {
                  if (pagination.page < pagination.total_page) {
                    setPagination({ ...pagination, page: pagination.page + 1 })
                  }
                }} >Next</button>
              <button
                disabled={pagination.page === 1}
                onClick={() => {
                  if (pagination.page > 1) {
                    setPagination({ ...pagination, page: pagination.page - 1 })
                  }
                }}
              >Previous</button>
            </div>
          </div>
        </div>
      </div>
    </BasicTemplate>
  )
}