import React, { FC } from 'react'
import IconFinished from '../../assets/icon-finished-white.png'

import './PayoutStep.scss'

type Props = {
  currentStep: 1 | 2 | 3 | 4
}

const PayoutStep: FC<Props> = ({ currentStep }) => {

  const handleNextStepNumber = (step: number) => {
    if (currentStep === step) {
      return 'payoutStep__item__number--active'
    } else if (currentStep > step) {
      return 'payoutStep__item__number--finished'
    } else {
      return 'payoutStep__item__number'
    }
  }

  const handleNextStepLine = (step: number) => {
    if (currentStep > step) {
      return 'payoutStep__item__line--finished'
    } else {
      return 'payoutStep__item__line'
    }
  }

  return (
    <section className='payoutStep'>
      <div className='payoutStep__item'>
        <span className={'payoutStep__item__number ' + handleNextStepNumber(1)}>
          {currentStep > 1 ? <img src={IconFinished} alt='icon-finished' /> : 1}
        </span>
        <div className={'payoutStep__item__line ' + handleNextStepLine(1)}></div>
        <span className='payoutStep__item__text'>Search for recipient</span>
      </div>
      <div className='payoutStep__item'>
        <span className={'payoutStep__item__number ' + handleNextStepNumber(2)}>
          {currentStep > 2 ? <img src={IconFinished} alt='icon-finished' /> : 2}
        </span>
        <div className={'payoutStep__item__line ' + handleNextStepLine(2)}></div>
        <span className='payoutStep__item__text'>Choose currency</span>
      </div>
      <div className='payoutStep__item'>
        <span className={'payoutStep__item__number ' + handleNextStepNumber(3)}>
          {currentStep > 3 ? <img src={IconFinished} alt='icon-finished' /> : 3}
        </span>
        <div className={'payoutStep__item__line ' + handleNextStepLine(3)}></div>
        <span className='payoutStep__item__text'>Create request</span>
      </div>
    </section>
  )
}

export default PayoutStep;