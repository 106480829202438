import React, { useEffect, useState } from 'react'
import BasicTemplate from '../BasicTemplate'
import TagStatus from '../../components/TagStatus'
import IconDots from '../../assets/icon-dots.png'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { getSubAccounts } from '../../api/gateway'
import { useAuth0 } from '@auth0/auth0-react'
import { PaginationType, TSubAccount } from '../../helpers/types'

import './index.scss'

const allOption = "all"

export default function SubAccounts () {
  const { getAccessTokenSilently } = useAuth0();
  const [currentTab, setCurrentTab] = useState<string>(allOption);
  const [subAccounts, setSubAccounts] = useState<TSubAccount[]>([]);
  const [pagination, setPagination] = useState<PaginationType>({ page: 1, size: 20, total_row: 0, total_page: 0 });

  const handleChange = (newValue: string) => {
    setCurrentTab(newValue)
  }

  const isSelected = (value: string) => value === currentTab ? 'subAccounts__card__menu--selected' : ''

  const handleStatus = (status: boolean) => {
    if (status) {
      return 'positive'
    } else {
      return 'negative'
    }
  }

  const handleKycStatus = (status: string) => {
    switch (status.toLowerCase()) {
      case 'unchecked':
        return 'info'
      case 'approved':
        return 'positive'
      case 'denied':
        return 'negative'
      default:
        return 'neutral';
    }
  }

  const formatDate = (date: string) => {
    return (
      dayjs(date).format('MMMM D, YYYY') +
      ', ' +
      dayjs(date).format('hh:mm A')
    );
  };

  const handleGetSubAccounts = async () => {
    getAccessTokenSilently().then(async (token) => {
      await getSubAccounts(token, pagination.page, pagination.size).then(({ data }) => {
        setSubAccounts(data.list);
        setPagination({
          page: data.page,
          size: data.size,
          total_row: data.total_row,
          total_page: data.total_page,
        });
      });
    })
  };

  useEffect(() => {
    handleGetSubAccounts()
  }, [pagination.page, pagination.size])

  return (
    <BasicTemplate>
      <div className='subAccounts'>
        <div className='subAccounts__card'>
          <div className='subAccounts__card__title'>
            <h1>Sub Accounts</h1>
          </div>
          <div className='subAccounts__card__menu'>
            <div className={'subAccounts__card__menu__option ' + isSelected(allOption)} onClick={() => handleChange(allOption)}>
              <span>All</span>
            </div>
          </div>
          <div className='subAccounts__card__table'>
            <table>
              <thead>
                <tr>
                  <th>NAME</th>
                  <th>EMAIL</th>
                  <th>STATUS</th>
                  <th>KYC STATUS</th>
                  <th>TAX ID</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {subAccounts.map((row) => (
                  <tr key={row.id}>
                    <td>{row.name}</td>
                    <td>{row.email}</td>
                    <td>
                      <TagStatus name={row.is_active ? 'active' : 'inactive'} status={handleStatus(row.is_active)} showIcon={true} />
                    </td>
                    <td>
                      <TagStatus name={row.kyc_status} status={handleKycStatus(row.kyc_status)} showIcon={true} />
                    </td>
                    <td>{row.tax_id_number}</td>
                    <td>
                      <Link to={`/sub-account/details/${row.id}`}>
                        <img src={IconDots} alt="order details button" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='subAccounts__card__action'>
            <span>{pagination.total_row} results</span>
            <div className='subAccounts__card__action--button'>
              <button
                disabled={pagination.page === pagination.total_page}
                onClick={() => {
                  if (pagination.page < pagination.total_page) {
                    setPagination({ ...pagination, page: pagination.page + 1 })
                  }
                }}
              >Next</button>
              <button
                disabled={pagination.page === 1}
                onClick={() => {
                  if (pagination.page > 1) {
                    setPagination({ ...pagination, page: pagination.page - 1 })
                  }
                }}
              >Previous</button>
            </div>
          </div>
        </div>
      </div>
    </BasicTemplate>
  )
}