import React, { useState, useEffect } from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import BasicTemplate from '../BasicTemplate';
import BoxGrid from '../../components/BoxGrid';
import BoxGridAction from '../../components/BoxGridAction';
import dayjs from 'dayjs';
import { clsx } from 'clsx';
import { useAuth0 } from '@auth0/auth0-react';
import { getSettlementDetails, getSettlementOrderBalance } from '../../api/gateway';
import { useParams } from 'react-router-dom';
import { TSettlementHistory, TSettlementOrder } from '../../helpers/types';
import useFormatAmount from '../../helpers/useFormatAmount';
import useFormatDateTime from '../../helpers/useFormatDateTime';
import useBgStatus from '../../helpers/useBgStatus';

import './index.scss';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 100, headerAlign: 'left' },
  {
    field: 'recipient_email',
    headerName: 'E-mail address',
    width: 220,
    headerAlign: 'left',
  },
  {
    field: 'description',
    headerName: 'Reference',
    width: 255,
    headerAlign: 'left',
    sortable: true,
  },
  {
    field: `amount`,
    headerName: 'Amount',
    minWidth: 80,
    headerAlign: 'left',
    sortable: true,
    renderCell: (params) => {
      return (
        <span style={{ fontWeight: 500, fontSize: 12, lineHeight: 16 }}>
          {params.row.target_asset} {params.row.target_amount}
        </span>
      );
    },
  },
  {
    field: 'created_at',
    headerName: 'Date',
    width: 120,
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    headerAlign: 'left',
    align: 'center',
    cellClassName: (params: GridCellParams) => {
      if (params.value == null) {
        return '';
      }

      return clsx('super-app', {
        confirmed: params.value === 'confirmed',
        pending: params.value === 'pending',
        canceled: params.value === 'canceled',
        open: params.value === 'not settled',
      });
    },
  },
];

const columnsDesktop: GridColDef[] = [
  ...columns,
  {
    field: 'action',
    headerName: '',
    sortable: false,
    renderCell: (params) => {
      return (
        <BoxGridAction
          title="Details"
          route={`/order-details/${params.id.toString()}`}
        />
      );
    },
  },
]

const columnsMobile: GridColDef[] = [
  ...columns
];

const emptySettlementDetails: TSettlementHistory = {
  id: '',
  account_id: '',
  target_asset: '',
  target_amount: 0,
  total_fees: 0,
  target_destination: '',
  comments: '',
  status: '',
  target_asset_network: '',
  number_of_orders_associated: 0,
  external_hash: '',
  processed_at: '',
  created_at: '',
  updated_at: '',
};

const SettlementDetails = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams();
  const [settlementDetails, setSettlementDetails] =
    useState<TSettlementHistory>(emptySettlementDetails);
  const [settlementOrders, setSettlementOrders] = useState<TSettlementOrder[]>([]);

  const balance = useFormatAmount(settlementDetails.target_amount, settlementDetails.target_asset)
  const dateTime = useFormatDateTime(settlementDetails.created_at)
  const bgStatus = useBgStatus(settlementDetails.status)

  const handleGetSettlementDetails = async (settlementId: string) => {
    await getAccessTokenSilently().then(async (token) => {
      const { data } = await getSettlementDetails(token, settlementId)
      setSettlementDetails(data)

      await getSettlementOrderBalance(token, data.id).then(({ data }) => {
        setSettlementOrders(data.list)
      })
    });
  };

  useEffect(() => {
    if (id) handleGetSettlementDetails(id);
  }, []);

  return (
    <BasicTemplate>
      <div className="settlementDetails">
        <div className="settlementDetails__header">Settlement Details</div>
        <div className="settlementDetails__details-container">
          <h3>Settlement Details</h3>
          <section>
            <div className="settlementDetails__details-title">
              Settlement amount:
            </div>
            <div className="settlementDetails__details-amount">
              {balance}
            </div>
            <p>
              {settlementDetails.number_of_orders_associated} orders are
              included in this settlement.
            </p>
          </section>
          <section>
            <span>Settlement ID:</span>
            <p>
              {settlementDetails.id}
            </p>
          </section>
          <section>
            <span>Created at:</span>
            <p>{dateTime}</p>
          </section>
          <section>
            <span>Status:</span>
            <div
              className="status"
              style={{ backgroundColor: bgStatus }}
            >
              {settlementDetails.status}
            </div>{' '}
          </section>
        </div>
        <div className="settlementDetails__table-container">
          <div className="settlementDetails__table-header">
            Orders from this settlement
          </div>
          <BoxGrid
            rows={settlementOrders}
            columnsDesktop={columnsDesktop}
            columnsMobile={columnsMobile}
            routeToDetails='/order-details/'
          />
        </div>
      </div>
    </BasicTemplate>
  );
};

export default SettlementDetails;
