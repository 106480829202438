
import { FC, useState } from 'react'
import './index.scss'

type Props = {
  options: string[]
  currentTabSelected: string
  selectedOption: (value: string) => void
}

const MenuBar: FC<Props> = ({ options, currentTabSelected, selectedOption }: Props) => {
  const [currentTab, setCurrentTab] = useState<string>(currentTabSelected)

  const isSelected = (value: string) => value === currentTab ? 'menuBar--selected' : ''

  return (
    <div className='menuBar'>
      {options.map((option, index) => (
        <div key={index} className={'menuBar__option ' + isSelected(option)} onClick={() => {
          setCurrentTab(option)
          selectedOption(option)
        }}>
          <span>{option}</span>
        </div>
      ))}
    </div>
  )
}

export default MenuBar