export default (amount: number, asset: string) => {
  const formatBalance = () => {
    switch (asset.toUpperCase()) {
      case 'USD':
        return 'en-US'
      default:
        return 'pt-BR'
    }
  }

  return `${asset} ${new Intl.NumberFormat(formatBalance(), {minimumFractionDigits: 2}).format(amount)}`
}