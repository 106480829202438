import React, { useState, useEffect } from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import BasicTemplate from '../BasicTemplate';
import { clsx } from 'clsx';
import BoxGrid from '../../components/BoxGrid';
import { useAuth0 } from '@auth0/auth0-react';
import { getSettlementAvailableOrder, getUserAccount, postSettlement } from '../../api/gateway';
import { SnackbarType, TSettlement, UserAccountFull } from '../../helpers/types';
import dayjs from 'dayjs';
import useGetBalance from '../../helpers/useGetBalance';
import useFormatAmount from '../../helpers/useFormatAmount';
import SnackBar from '../../components/SnackBar';

import './Settlement.scss';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70, headerAlign: 'left' },
  {
    field: 'recipient_email',
    headerName: 'E-mail address',
    width: 182,
    headerAlign: 'left',
  },
  {
    field: 'description',
    headerName: 'Reference',
    width: 255,
    headerAlign: 'left',
  },
  {
    field: `amount`,
    headerName: 'Amount',
    minWidth: 80,
    headerAlign: 'left',
    sortable: true,
    renderCell: (params) => {
      return (
        <span style={{ fontWeight: 500, fontSize: 12, lineHeight: 16 }}>
          {params.row.target_asset} {params.row.target_amount}
        </span>
      );
    },
  },
  {
    field: 'created_at',
    headerName: 'Date',
    width: 120,
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY HH:mm'),
  },
  {
    field: 'status',
    headerName: '',
    width: 140,
    headerAlign: 'left',
    align: 'left',
    cellClassName: (params: GridCellParams) => {
      if (params.value == null) {
        return '';
      }

      return clsx('super-app', {
        confirmed: params.value === 'confirmed',
        pending: params.value === 'pending',
        canceled: params.value === 'canceled',
        open: params.value === 'not settled',
      });
    },
  },
];

const columnsDesktop: GridColDef[] = [
  ...columns,
];

const columnsMobile: GridColDef[] = [
  ...columns,
];

const emptySnackBar: SnackbarType = {
  open: false,
  title: '',
  severity: 'success',
};

const Settlement = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [settlements, setSettlements] = useState<TSettlement[]>([]);
  const [snackBar, setSnackBar] = useState<SnackbarType>(emptySnackBar);

  const balance = useGetBalance()
  const amount = useFormatAmount(balance.total_amount, balance.asset)

  const handleGetSettlement = async () => {
    await getAccessTokenSilently().then(async token => {
      const { data: account }: { data: UserAccountFull } = await getUserAccount(token)

      await getSettlementAvailableOrder(token, account.settlement_asset).then(({ data }) => {
        setSettlements(data.list)
      })
    })
  }

  const handlePostSettlement = async () => {
    await getAccessTokenSilently().then(async token => {
      await postSettlement(token, balance.asset).then(async ({ data }) => {
        setSnackBar(data.message)
        await handleGetSettlement()
      }).catch(({ response }) => {
        setSnackBar({
          open: true,
          title: response.data.message,
          severity: 'error',
        });
      });
    })
  }

  useEffect(() => {
    handleGetSettlement()
  }, [])

  return (
    <BasicTemplate>
      <div className="settlement">
        <SnackBar {...snackBar} clear={() => setSnackBar(emptySnackBar)} />
        <div className="settlement__header">Request Settlement</div>
        <div className="settlement__details-container">
          <h3>Settlement</h3>
          <section>
            <div className="settlement__details-title">Amount Available</div>
            <div className="settlement__details-amount">
              {amount}
            </div>
            <p>{balance.number_of_orders_associated} orders are included in this settlement.</p>
          </section>
          <section>
            <button onClick={handlePostSettlement}>Request Settlement</button>
          </section>
        </div>
        <div className="settlement__table-container">
          <div className="settlement__table-header">Orders to be settled</div>
          <BoxGrid
            rows={settlements}
            columnsDesktop={columnsDesktop}
            columnsMobile={columnsMobile}
          />
        </div>
      </div>
    </BasicTemplate>
  );
};

export default Settlement;
