import React, { ReactElement } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageLoader } from '../PageLoader/PageLoader'

import './AuthenticationGuard.scss'

type Props = {
  children: ReactElement
}

const AuthenticationGuard = ({ children }:Props) => {
  return children
}

export default withAuthenticationRequired(AuthenticationGuard, {
  onRedirecting: () => (
    <div className="page-layout">
      <PageLoader />
    </div>
  ),
})
