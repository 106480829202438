import React, { FC, useState } from 'react';
import { CreateOrder, SnackbarType } from '../../helpers/types';
import { useAuth0 } from '@auth0/auth0-react';
import { createOrder } from '../../api/gateway';
import { FormControlLabel, FormGroup, Switch, useMediaQuery } from '@mui/material';
import CurrencyInput from 'react-currency-input-field';
import { PageLoader } from '../PageLoader/PageLoader';
import SnackBar from '../SnackBar';
import ArrowBottom from '../../assets/arrow-bottom.png';

import './index.scss';

const initialState: CreateOrder = {
  account_id: '',
  comments: '',
  description: '',
  link_exp: 72,
  recipient_language_code: 'pt-BR',
  product: 'payments',
  recipient_email: '',
  target_amount: 0,
  target_asset: 'BRL',
};

const expirationLinkData = [
  { name: '24 h', value: 24 },
  { name: '48 h', value: 48 },
  { name: '72 h', value: 72 },
];

const languages = [
  { name: 'Portuguese', value: 'pt' },
  { name: 'English', value: 'en' },
];

const emptySnackBar: SnackbarType = {
  open: false,
  title: '',
  severity: 'success',
};

type Props = {
  accountId?: string;
  handleSearchOrders: () => void;
};

const CreatePayment: FC<Props> = ({ accountId, handleSearchOrders }: Props) => {
  const { getAccessTokenSilently } = useAuth0();

  const [paymentRequest, setPaymentRequest] =
    useState<CreateOrder>(initialState);
  const [loading, setLoading] = useState<boolean>(false);
  const [advance, setAdvance] = useState(false);
  const [snackBar, setSnackBar] = useState<SnackbarType>(emptySnackBar);
  const [rotateChevron, setRotateChevron] = useState(false);

  const updateValue = (key: string, value: string | number | undefined) => {
    setPaymentRequest((account) => ({
      ...account,
      [key]: value,
    }));
  };

  const createPayment = async () => {
    const body = { ...paymentRequest };
    if (accountId) body.account_id = accountId;

    body.target_amount = parseFloat(body.target_amount.toString());

    await getAccessTokenSilently().then(async (token) => {
      setLoading(true);
      await createOrder(token, body)
        .then(() => {
          setSnackBar({
            open: true,
            title: 'Payment request generated',
            severity: 'success',
          });
          setPaymentRequest(initialState);
          handleSearchOrders();
          setLoading(false);
        })
        .catch((e) => {
          setSnackBar({
            open: true,
            title: e.response.data.message,
            severity: 'error',
          });
          setLoading(false);
        });
    });
  };

  const handleRotate = () => {
    setRotateChevron(!rotateChevron);
  };

  const rotate = rotateChevron ? 'rotate(0)' : 'rotate(-180deg)';
  const matches = useMediaQuery('(max-width:1023px)');

  return (
    <>
      <SnackBar {...snackBar} clear={() => setSnackBar(emptySnackBar)} />
      <div className="createPayment" style={{gap: matches ? rotateChevron ? '16px':'0' : ''}}>
        <div className="createPayment--title" onClick={handleRotate}>
          <h3>Create Order</h3>
          <img
            src={ArrowBottom}
            alt="menu icon"
            style={{ transform: rotate, transition: 'all 0.2s linear' }}
          />
        </div>
        <div
          className="createPayment--amount"
          style={{ display: matches ? rotateChevron ? '' : 'none' : '' }}
        >
          Amount:
          <div>
            <select
              value={paymentRequest.target_asset}
              onChange={({ target }) =>
                updateValue('target_asset', target.value)
              }
              name="currency"
              id="currency"
            >
              <option value="BRL">BRL</option>
              <option value="USD">USD</option>
            </select>
            <CurrencyInput
              id="input-amount"
              name="input-amount"
              placeholder="Please enter a number"
              allowDecimals={true}
              decimalsLimit={2}
              allowNegativeValue={false}
              value={paymentRequest.target_amount}
              onValueChange={(value) => {
                if (value) {
                  updateValue('target_amount', value);
                } else {
                  updateValue('target_amount', undefined);
                }
              }}
            />
          </div>
        </div>
        <div
          className="createPayment--reference"
          style={{ display: matches ? rotateChevron ? '' : 'none' : '' }}
        >
          <div>
            Your internal reference:{' '}
            {paymentRequest.description.length >= 100 && (
              <span>You exceeded the number of characters</span>
            )}
          </div>
          <textarea
            value={paymentRequest.description}
            onChange={({ target }) => updateValue('description', target.value)}
            draggable={false}
            maxLength={100}
            placeholder={'Type here your internal reference'}
          />
          <span>{paymentRequest.description.length}/100</span>
        </div>
        <div
          className="createPayment--email"
          style={{ display: matches ? rotateChevron ? '' : 'none' : '' }}
        >
          <label>
            Recipient&apos;s e-mail address:
            <input
              type="email"
              placeholder="e-mail address"
              value={paymentRequest.recipient_email}
              onChange={({ target }) =>
                updateValue('recipient_email', target.value)
              }
            />
          </label>
        </div>
        <div
          className="createPayment--options"
          style={{ display: matches ? rotateChevron ? '' : 'none' : '' }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  value={advance}
                  onChange={(e) => setAdvance(e.target.checked)}
                />
              }
              label="Advanced Options"
            />
          </FormGroup>
          {advance && (
            <>
            <div className="advance__options">
              Link expiration{' '}
              <span>
                <select
                  name="linkExpiration"
                  id="linkExpiration"
                  value={paymentRequest.link_exp}
                  onChange={({ target }) => {
                    updateValue('link_exp', parseInt(target.value));
                  }}
                >
                  {expirationLinkData.map((v, index) => {
                    return (
                      <option key={index} value={v.value}>
                        {v.name}
                      </option>
                    );
                  })}
                </select>
              </span>
            </div>
            <div className="advance__options">
              Language{' '}
              <span>
                <select
                  name="language"
                  id="language"
                  value={paymentRequest.recipient_language_code}
                  onChange={({ target }) => {
                    updateValue('recipient_language_code', target.value);
                  }}
                >
                  {languages.map((v, index) => {
                    return (
                      <option key={index} value={v.value}>
                        {v.name}
                      </option>
                    );
                  })}
                </select>
              </span>
            </div>
            </>
          )}
        </div>
        <div
          className="createPayment--action"
          style={{ display: matches ? rotateChevron ? '' : 'none' : '' }}
        >
          {loading ? (
            <div className="loading-button">
              <PageLoader></PageLoader>
            </div>
          ) : (
            <button
              onClick={createPayment}
              disabled={
                !paymentRequest.recipient_email ||
                !paymentRequest.description ||
                !paymentRequest.target_amount
              }
            >
              Generate Payment
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default CreatePayment;
