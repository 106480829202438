import React, { useState, useEffect } from 'react';
import BasicTemplate from '../BasicTemplate';
import { useAuth0 } from '@auth0/auth0-react';
import { SnackbarType, UserAccountFull } from '../../helpers/types';
import SnackBar from '../../components/SnackBar';
import { getUserAccount } from '../../api/gateway';
import AccountSettings from './AccountSettings';
import Settlement from './Settlement';
import Fees from './Fees';
import BankAccount from './BankAccount';
import AccountWallet from './AccountWallet';
import UploadDocuments from './UploadDocuments';

import './index.scss';
import MenuBar from '../../components/MenuBar';
import Integrations from '../Integrations';

const emptySnackBar: SnackbarType = {
  open: false,
  title: '',
  severity: 'success',
};

const emptyUserAccount: UserAccountFull = {
  id: '',
  account_type_id: '',
  account_type_name: '',
  name: '',
  country: '',
  city: '',
  address: '',
  tax_id_type: '',
  tax_id_number: '',
  local_id_type: '',
  local_id_number: '',
  post_code: '',
  is_active: false,
  settlement_asset: '',
  settlement_frequency: '',
  settlement_preference: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
};

const Settings = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [snackBar, setSnackBar] = useState<SnackbarType>(emptySnackBar);
  const [userAccount, setUserAccount] =
    useState<UserAccountFull>(emptyUserAccount);
  const [currentTab, setCurrentTab] = useState<string>('Settings');

  useEffect(() => {
    (async () => {
      await getAccessTokenSilently().then(async (token) => {
        await getUserAccount(token).then(({ data }) => {
          setUserAccount(data);
        });
      });
    })()
  }, []);

  return (
    <BasicTemplate>
      <SnackBar {...snackBar} clear={() => setSnackBar(emptySnackBar)} />
      <div className="settings">
        <h3 className="settings__title">Settings</h3>
        <MenuBar selectedOption={setCurrentTab} currentTabSelected={currentTab} options={['Settings', 'Fees', 'Api']} />

        {currentTab === 'Settings' && (
          <>
            <div className="settings__container">
              <h3>Bank Account</h3>
              <div className='settings__container__line'></div>
              <BankAccount
                handleSnackBar={setSnackBar}
                accountID={userAccount.id}
              />
            </div>
            <AccountSettings
              handleSnackBar={setSnackBar}
              accountName={userAccount.name}
              accountTypeName={userAccount.account_type_name}
            />
            <div className="settings__container">
              <h3 style={{ paddingTop: 24 }}>Stablecoin wallet</h3>
              <div className='settings__container__line'></div>
              <AccountWallet accountID={userAccount.id} />
            </div>
            <div className="settings__container">
              <h3 style={{ paddingTop: 24 }}>Settlement</h3>
              <div className='settings__container__line'></div>
              <Settlement
                userAccount={userAccount}
                handleSetUserAccount={setUserAccount}
              />
            </div>
            <div className="settings__container">
              <h3 style={{ paddingTop: 24 }}>Additional Documents</h3>
              <div className='settings__container__line'></div>
              <UploadDocuments
                handleSnackBar={setSnackBar}
                typeAccount={userAccount.account_type_name}
              />
            </div>
          </>
        )}

        {currentTab === 'Fees' && (
          <div className="settings__container">
            <Fees />
          </div>
        )}

        {currentTab === 'Api' && (
          <div className="settings__container">
            <Integrations />
          </div>
        )}
      </div>
    </BasicTemplate>
  );
};

export default Settings;
