import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import Helper from '../../assets/helper.png'
import Settings from '../../assets/settings.png'
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom'

import './index.scss'

export default function NavHeader () {
  const { logout } = useAuth0();

  const handlerLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };


  return (
    <div className='navHeader'>
      <div></div>
      <Link to='/customer-support'>
        <img src={Helper} alt='helper' />
        <span>Help</span>
      </Link>
      <Link to='/settings'>
        <img src={Settings} alt='settings' />
        <span>Settings</span>
      </Link>
      <a onClick={() => handlerLogout()}>
        <LogoutIcon />
        <span>Logout</span>
      </a>
    </div>
  )
}