import React, { FC, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  createUserAccountBank,
  getUserAccountBank,
  updateUserAccountBank,
} from '../../api/gateway';
import { SnackbarType, UserAccountBank } from '../../helpers/types';
import { Input } from '../../components/Input';
import Countries from '../../data/countries';

type optionsUpdate = {
  email: boolean;
  password: boolean;
  bank_name: boolean;
  branch: boolean;
  account_number: boolean;
  country: boolean;
  currency: boolean;
  payment_method: boolean;
  payment_id: boolean;
};

const emptyOptions: optionsUpdate = {
  email: false,
  password: false,
  bank_name: false,
  branch: false,
  account_number: false,
  country: false,
  currency: false,
  payment_method: false,
  payment_id: false,
};

const emptyBank: UserAccountBank = {
  id: '',
  account_id: '',
  bank_name: '',
  branch: '',
  account: '',
  instant_payment: '',
  instant_payment_type: '',
  country: 'BR',
  asset: '',
  created_at: '',
  updated_at: '',
  deleted_at: null,
};

type Props = {
  accountID: string;
  handleSnackBar: (value: SnackbarType) => void;
};

const BankAccount: FC<Props> = ({ accountID, handleSnackBar }: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [options, setOptions] = useState<optionsUpdate>(emptyOptions);
  const [bank, setBank] = useState<UserAccountBank>(emptyBank);

  const updateOption = (key: string, value: boolean) => {
    setOptions((o) => ({
      ...o,
      [key]: value,
    }));
  };

  const updateValue = (key: string, value: string) => {
    const b = { ...bank }
    if (key === 'asset' && value === 'USD') {
      b.instant_payment_type = ''
      b.instant_payment = ''
    }
    setBank(() => ({
      ...b,
      [key]: value,
    }));
  };

  const handleGetAccountBank = async () => {
    await getAccessTokenSilently().then(async (token) => {
      await getUserAccountBank(token).then(({ data }) => {
        setBank(data);
      });
    });
  };

  const handleCreateAccountBank = async () => {
    if (!bank) return;

    await getAccessTokenSilently().then(async (token) => {
      await createUserAccountBank(token, bank)
        .then(({ data }) => {
          setBank(data);
          handleSnackBar({
            open: true,
            title: 'Success create account bank',
            severity: 'success',
          });
        })
        .catch(({ response }) => {
          handleSnackBar({
            open: true,
            title: response.data.message,
            severity: 'error',
          });
        });
    });
  };

  const handleUpdateAccountBank = async (field: string) => {
    if (!bank || !accountID) return;

    await getAccessTokenSilently().then(async (token) => {
      await updateUserAccountBank(token, accountID, bank)
        .then(({ data }) => {
          setBank(data);
          handleSnackBar({
            open: true,
            title: 'Success update account bank',
            severity: 'success',
          });
          updateOption(field, false);
        })
        .catch(({ response }) => {
          handleSnackBar({
            open: true,
            title: response.data.message,
            severity: 'error',
          });
        });
    });
  };

  useEffect(() => {
    handleGetAccountBank();
  }, []);

  const getCountryName = (c: string) => {
    const name = Countries.find(({ id }) => id === c)?.value
    if (!name) return ""

    return name
  }

  return (
    <>
      {bank.id ? (
        <>
          <div className="settings__container__option">
            <label htmlFor="bank_name">Bank Name</label>
            {!options.bank_name ? (
              <div className="settings__container__option--info">
                <span>{bank.bank_name}</span>
                <button
                  onClick={() => {
                    updateOption('bank_name', true);
                  }}
                >
                  Edit
                </button>
              </div>
            ) : (
              <div className="settings__container__option--change">
                <input
                  onChange={({ target }) =>
                    updateValue('bank_name', target.value)
                  }
                  type="text"
                  id="bank_name"
                />
                <button onClick={() => { handleUpdateAccountBank('bank_name') }}>OK</button>
              </div>
            )}
          </div>
          <div className="settings__container__option">
            <label htmlFor="branch">Branch</label>
            {!options.branch ? (
              <div className="settings__container__option--info">
                <span>{bank.branch}</span>
                <button
                  onClick={() => {
                    updateOption('branch', true);
                  }}
                >
                  Edit
                </button>
              </div>
            ) : (
              <div className="settings__container__option--change">
                <input
                  onChange={({ target }) => updateValue('branch', target.value)}
                  type="text"
                  id="branch"
                />
                <button onClick={() => { handleUpdateAccountBank('branch') }}>OK</button>
              </div>
            )}
          </div>
          <div className="settings__container__option">
            <label htmlFor="accountNumber">Account Number</label>
            {!options.account_number ? (
              <div className="settings__container__option--info">
                <span>{bank.account}</span>
                <button
                  onClick={() => {
                    updateOption('account_number', true);
                  }}
                >
                  Edit
                </button>
              </div>
            ) : (
              <div className="settings__container__option--change">
                <input
                  onChange={({ target }) =>
                    updateValue('account', target.value)
                  }
                  type="text"
                  id="accountNumber"
                />
                <button onClick={() => { handleUpdateAccountBank('account_number') }}>OK</button>
              </div>
            )}
          </div>
          <div className="settings__container__option">
            <label htmlFor="country">Country</label>
            {!options.country ? (
              <div className="settings__container__option--info">
                <span>{getCountryName(bank.country)}</span>
                <button
                  onClick={() => {
                    updateOption('country', true);
                  }}
                >
                  Edit
                </button>
              </div>
            ) : (
              <div className="settings__container__option--change">
                <select
                  value={bank.country}
                  style={{ width: '100%' }}
                  onChange={({ target }) => {
                    updateValue('country', target.value)
                  }
                  }
                >
                  {Countries.map((v, index) => {
                    return (
                      <option key={index} value={v.id}>
                        {v.value}
                      </option>
                    );
                  })}
                </select>
                <button onClick={() => { handleUpdateAccountBank('country') }}>OK</button>
              </div>
            )}
          </div>
          <div className="settings__container__option">
            <label htmlFor="currency">Currency</label>
            {!options.currency ? (
              <div className="settings__container__option--info">
                <span>{bank.asset}</span>
                <button
                  onClick={() => {
                    updateOption('currency', true);
                  }}
                >
                  Edit
                </button>
              </div>
            ) : (
              <div className="settings__container__option--change">
                <select
                  onChange={({ target }) => updateValue('asset', target.value)}
                >
                  <option value="">Currency</option>
                  <option value="BRL">BRL</option>
                  <option value="USD">USD</option>
                </select>
                <button onClick={() => { handleUpdateAccountBank('currency') }}>OK</button>
              </div>
            )}
          </div>
          {bank.asset === 'BRL' && (
            <>
              <div className="settings__container__option">
                <label htmlFor="paymentMethod">Instant Payment Method</label>
                {!options.payment_method ? (
                  <div className="settings__container__option--info">
                    <span>{bank.instant_payment_type}</span>
                    <button
                      onClick={() => {
                        updateOption('payment_method', true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                ) : (
                  <div className="settings__container__option--change">
                    <select
                      onChange={({ target }) => updateValue('instant_payment_type', target.value)}
                    >
                      <option value="">Type</option>
                      <option value="PIX">PIX</option>
                    </select>
                    <button onClick={() => { handleUpdateAccountBank('payment_method') }}>OK</button>
                  </div>
                )}
              </div>
              <div className="settings__container__option">
                <label htmlFor="paymentID">Instant Payment ID</label>
                {!options.payment_id ? (
                  <div className="settings__container__option--info">
                    <span>{bank.instant_payment}</span>
                    <button
                      onClick={() => {
                        updateOption('payment_id', true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                ) : (
                  <div className="settings__container__option--change">
                    <input
                      onChange={({ target }) =>
                        updateValue('instant_payment', target.value)
                      }
                      type="text"
                      id="paymentID"
                    />
                    <button onClick={() => { handleUpdateAccountBank('payment_id') }}>OK</button>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="settings__container__option">
            <label>Country:</label>
            <select
              value={bank.country}
              style={{ width: '100%' }}
              onChange={({ target }) => {
                updateValue('country', target.value)
              }}
            >
              {Countries.map((v, index) => {
                return (
                  <option key={index} value={v.id}>
                    {v.value}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="settings__container__option">
            <label>Currency</label>
            <select
              onChange={({ target }) => updateValue('asset', target.value)}
            >
              <option value="">Currency</option>
              <option value="BRL">BRL</option>
              <option value="USD">USD</option>
            </select>
          </div>
          <div className="settings__container__option">
            <Input
              type="text"
              id="bank_name"
              label={'Bank name:'}
              value={bank.bank_name}
              onChange={(value) => updateValue('bank_name', value)}
              placeholder={'type bank name'}
            />
          </div>
          <div className="settings__container__option">
            <Input
              type="text"
              id="branch"
              label={'Branch:'}
              value={bank.branch}
              onChange={(value) => updateValue('branch', value)}
              placeholder={'type branch'}
            />
          </div>
          <div className="settings__container__option">
            <Input
              type="text"
              id="accountNumber"
              label={'Bank Account Number:'}
              value={bank.account}
              onChange={(value) => updateValue('account', value)}
              placeholder={'type account'}
            />
          </div>
          {bank.asset === 'BRL' && (
            <>
              <div className="settings__container__option">
                <label>Instant Payment Type:</label>
                <select
                  onChange={({ target }) => updateValue('instant_payment_type', target.value)}
                >
                  <option value="">Type</option>
                  <option value="PIX">PIX</option>
                </select>
              </div>
              <div className="settings__container__option">
                <Input
                  type="text"
                  id="instant_payment"
                  label={'Instant Payment ID:'}
                  value={bank.instant_payment}
                  onChange={(value) => updateValue('instant_payment', value)}
                  placeholder={'type instant payment id'}
                />
              </div>
            </>
          )}
          <div className="settings__container__option--action">
            <button onClick={handleCreateAccountBank}>
              Create bank account
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default BankAccount;
