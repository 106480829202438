import React, { useState, useEffect } from 'react';
import './PaymentMenu.scss';
import {
  GridColDef,
  GridCellParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserAccount, getOrders } from '../../api/gateway';
import clsx from 'clsx';
import { Order, UserAccountFull } from '../../helpers/types';
import dayjs from 'dayjs';
import CreatePayment from '../../components/CreatePayment';
import BasicTemplate from '../BasicTemplate';
import BoxGrid from '../../components/BoxGrid';
import BoxGridAction from '../../components/BoxGridAction';

const columnsDesktop: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 300, headerAlign: 'left' },
  {
    field: 'recipient_email',
    headerName: 'E-mail address',
    width: 220,
    headerAlign: 'left',
  },
  {
    field: 'description',
    headerName: 'Reference',
    width: 255,
    headerAlign: 'left',
    sortable: true,
  },
  {
    field: `amount`,
    headerName: 'Amount',
    width: 150,
    headerAlign: 'left',
    sortable: true,
    renderCell: (params) => {
      return (
        <span style={{ fontWeight: 500, fontSize: 12, lineHeight: 16 }}>
          {params.row.target_asset} {params.row.target_amount}
        </span>
      );
    },
  },
  {
    field: 'created_at',
    headerName: 'Date',
    width: 150,
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY HH:mm'),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    headerAlign: 'left',
    align: 'center',
    cellClassName: (params: GridCellParams) => {
      if (params.value == null) {
        return '';
      }

      return clsx('super-app', {
        confirmed: params.value === 'confirmed',
        pending: params.value === 'pending',
        canceled: params.value === 'canceled',
        open: params.value === 'open',
      });
    },
  },
  {
    field: 'action',
    headerName: '',
    sortable: false,
    renderCell: (params) => {
      return (
        <BoxGridAction
          title="Details"
          route={`/order-details/${params.id.toString()}`}
        />
      );
    },
  },
];

const columnsMobile: GridColDef[] = [
  {
    field: 'recipient_email',
    headerName: 'E-mail address',
    headerAlign: 'left',
    width: 124,
  },
  {
    field: `amount`,
    headerName: 'Amount',
    minWidth: 80,
    headerAlign: 'left',
    sortable: true,
    renderCell: (params) => {
      return (
        <span style={{ fontWeight: 500, fontSize: 12, lineHeight: 16 }}>
          {params.row.target_asset} {params.row.target_amount}
        </span>
      );
    },
  },
  {
    field: 'created_at',
    headerName: 'Date',
    headerAlign: 'left',
    align: 'left',
    width: 85,
    valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'left',
    align: 'center',
    width: 80,
    cellClassName: (params: GridCellParams) => {
      if (params.value == null) {
        return '';
      }

      return clsx('super-app', {
        confirmed: params.value === 'confirmed',
        pending: params.value === 'pending',
        canceled: params.value === 'canceled',
        open: params.value === 'open',
      });
    },
  },
];

type SearchStatus = 'open' | 'pending' | 'confirmed' | 'canceled' | '';

type OrderBy =
  | 'id'
  | 'recipient_email'
  | 'description'
  | 'created_at'
  | 'status';

const PaymentMenu = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [account, setAccount] = useState<UserAccountFull>();

  const [orders, setOrders] = useState<Order[]>([]);

  const [search, setSearch] = useState('');

  const [searchStatus, setSearchStatus] = useState<SearchStatus>('');

  const [searchOrderBy, setSearchOrderBy] = useState<OrderBy>('created_at');

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await getAccessTokenSilently()
        .then(async (token) => {
          const { data } = await getUserAccount(token);
          if (!data) {
            return navigate('/register-account');
          } else if (!data.is_active) {
            return navigate('/success');
          }

          setAccount(data);

          getOrders(token, data.id, 'created_at', false, '', '').then(
            ({ data }) => {
              setOrders(data.orders);
            },
          );
        })
        .catch(console.error);
    })();
  }, []);

  const filterOrders = async () => {
    if (!account) return;
    await getAccessTokenSilently().then(async (token) => {
      getOrders(
        token,
        account?.id,
        searchOrderBy,
        false,
        searchStatus,
        search,
      ).then(({ data }) => {
        setOrders(data.orders);
      });
    });
  };

  useEffect(() => {
    filterOrders();
  }, [searchStatus, search, searchOrderBy]);

  return (
    <BasicTemplate>
      <div className="paymentMenu">
        <div className="paymentMenu__header">
          <h3>Create a new order</h3>
          <div className="header__language"></div>
        </div>
        <CreatePayment
          accountId={account?.id}
          handleSearchOrders={filterOrders}
        />
        <div className="paymentMenu__table-container">
          <div className="paymentMenu__table-header">
            <div className="paymentMenu__table-title">Order List</div>
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search for anything.."
            />
            {/* <div className="paymentMenu__table-dropdown">
              <select
                value={searchOrderBy}
                onChange={({ target }) =>
                  setSearchOrderBy(target.value as OrderBy)
                }
                name="orderBy"
                id="orderBy"
              >
                <option value="created_at">Created At</option>
                <option value="id">ID</option>
                <option value="recipient_email">Recipient email</option>
                <option value="description">Description</option>
                <option value="status">Status</option>
              </select>
            </div> */}
            <div className="paymentMenu__table-dropdown">
              <select
                value={searchStatus}
                onChange={({ target }) =>
                  setSearchStatus(target.value as SearchStatus)
                }
                name="status"
                id="status"
              >
                <option value="">All payments</option>
                <option value="open">Open</option>
                <option value="pending">Pending</option>
                <option value="confirmed">Confirmed</option>
                <option value="canceled">Canceled</option>
              </select>
            </div>
          </div>
          <BoxGrid
            rows={orders}
            columnsDesktop={columnsDesktop}
            columnsMobile={columnsMobile}
            routeToDetails='/order-details/'
          />
        </div>
      </div>
    </BasicTemplate>
  );
};

export default PaymentMenu;
