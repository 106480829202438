import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BetaLogo from '../../assets/beta-logo.png';
import ArrowUp from '../../assets/arrow-up.png';
import ArrowRight from '../../assets/arrow-right.png';
import { Dialog, DialogContent, Divider, IconButton } from '@mui/material';
import { AccountBalance, Cancel, Start, SwapVert, Sync, Wallet } from '@mui/icons-material';

import './index.scss'

type Menu = {
  id: number,
  subOption: boolean,
  name: string,
  route: string
}

const menuOrders: Menu[] = [
  {
    "id": 1,
    "name": "All Orders",
    "subOption": false,
    "route": "/orders"
  },
]

const menuBalances: Menu[] = [
  {
    "id": 1,
    "name": "Pool Balance",
    "subOption": false,
    "route": "/pool-balance"
  },
  {
    "id": 2,
    "name": "Settlements",
    "subOption": false,
    "route": "/settlement-history"
  },
  {
    "id": 3,
    "name": "Request Settlement",
    "subOption": true,
    "route": "/settlement"
  }
]

const menuSubAccounts: Menu[] = [
  {
    "id": 1,
    "name": "Sub Account List",
    "subOption": false,
    "route": "/sub-accounts"
  },
  {
    "id": 2,
    "name": "Register Sub Account",
    "subOption": true,
    "route": "/sub-account/register"
  }
]

const menuSubAccountsCreate: Menu[] = []

export default function Nav () {
  const location = useLocation();

  const menu = (isButton: boolean, title: string, menu: Menu[]) => {
    const [active, setActive] = useState(false)
    const [open, setOpen] = useState(false)

    const rotate = active ? 'rotate(0)' : 'rotate(-180deg)';

    const handleActive = () => {
      setActive(!active);
    };

    const handleSetOpen = () => {
      setOpen(!open);
    }

    const isCurrentRoute = (route: string) => {
      return location.pathname === route ? 'nav__submenu__option--active' : '';
    }

    const isSubOption = (subOption: boolean) => {
      return subOption ? 'nav__submenu__option--sub' : '';
    }

    return (
      <div className='nav__menu'>
        {isButton ? (
          <button onClick={handleSetOpen} className='nav__menu__button'>
            <span>Create</span>
            <img src={ArrowRight} alt="right arrow" />
          </button>
        ) : (
          <div className='nav__menu__option' onClick={handleActive} style={{ background: active ? 'var(--color-primary-200)' : '' }}>
            <span>{title}</span>
            <img src={ArrowUp} alt="arrow up" style={{ transform: rotate, transition: 'all 0.2s linear' }} />
          </div>
        )}
        {active && (
          <div className='nav__submenu'>
            {menu.map((option) => (
              <Link
                key={option.id}
                to={option.route}
                className={'nav__submenu__option ' + isCurrentRoute(option.route) + ' ' + isSubOption(option.subOption)}
              >
                {option.name}
              </Link>
            ))}
          </div>
        )}

        <Dialog
          onClose={handleSetOpen}
          open={open}
          className='nav__submenu__dialog'
        >
          <IconButton
            aria-label="close"
            onClick={handleSetOpen}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[600],
            })}
          >
            <Cancel />
          </IconButton>
          <DialogContent style={{ padding: 60 }}>
            <div className='nav__submenu__dialog__content'>
              <Divider />
              <Link to={'/payments'}>
                <Wallet />
                Request a Payment
              </Link>
              <Divider />
              <Link to={'/payout'}>
                <Start />
                Send a Payout
              </Link>
              <Divider />
              <Link to={'/exchange'}>
                <Sync />
                Exchange Assets
              </Link>
              <Divider />
              <Link to={'/deposit'}>
                <AccountBalance />
                Make a Deposit
              </Link>
              <Divider />
              <Link to={'/'}>
                <SwapVert />
                Request Withdrawal
              </Link>
              <Divider />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  return (
    <div className='nav'>
      <div className='nav__logo'>
        <img src={BetaLogo} alt="beta logo" />
      </div>
      <Link className='nav__option' to="/home">Home</Link>
      {menu(false, "Orders", menuOrders)}
      {menu(false, "Balances", menuBalances)}
      {menu(false, "SubAccounts", menuSubAccounts)}
      {menu(true, "Create", menuSubAccountsCreate)}
    </div>
  );
}