import React, { FC, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  createUserAccountWallet,
  getUserAccountWallet,
  updateUserAccountWallet,
} from '../../api/gateway';
import { UserAccountWallet } from '../../helpers/types';
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';

type Props = {
  accountID: string;
};

const emptyWallet: UserAccountWallet = {
  id: '',
  account_id: '',
  name: '',
  network: '',
  asset: '',
  address: '',
  created_at: '',
  updated_at: '',
  deleted_at: null,
};

const AccountWallet: FC<Props> = ({ accountID }: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [wallet, setWallet] = useState<UserAccountWallet>(emptyWallet);
  const [currentWallet, setCurrentWallet] = useState<UserAccountWallet>(emptyWallet);

  const updateValue = (key: string, value: string) => {
    setWallet((wallet) => ({
      ...wallet,
      [key]: value,
    }));
  };

  const handleGetAccountWallet = async () => {
    await getAccessTokenSilently().then(async (token) => {
      await getUserAccountWallet(token).then(({ data }) => {
        setCurrentWallet(data);
      });
    });
  };

  const handleCreateAccountWallet = async () => {
    if (!wallet) return;

    await getAccessTokenSilently().then(async (token) => {
      await createUserAccountWallet(token, wallet).then(({ data }) => {
        setCurrentWallet(data);
      });
    });
  };

  const handleUpdateAccountWallet = async () => {
    if (!wallet || !accountID) return;

    await getAccessTokenSilently().then(async (token) => {
      await updateUserAccountWallet(token, accountID, wallet).then(
        ({ data }) => {
          setCurrentWallet(data);
        },
      );
    });
  };

  const handle = async () => {
    if (currentWallet.id) {
      await handleUpdateAccountWallet();
    } else {
      await handleCreateAccountWallet();
    }
  };

  const buttonTitle = () => {
    if (currentWallet.id) {
      return 'Update wallet';
    } else {
      return 'Add new wallet';
    }
  };

  useEffect(() => {
    handleGetAccountWallet();
  }, []);

  return (
    <>
      {currentWallet.id && (
        <div className="settings__container__option">
          <div className="settings__container__option--texts">
            <p className="settings__container__option--texts-title">
              Your current wallet registered:
            </p>
            <p className="settings__container__option--texts-title">
              Asset / Network
            </p>
            <p className="settings__container__option--texts-value">
              {currentWallet?.asset} / {currentWallet?.network}
            </p>
            <p className="settings__container__option--texts-title">
              Wallet Address
            </p>
            <p className="settings__container__option--texts-value">
              {currentWallet?.address}
            </p>
          </div>
        </div>
      )}
      <div className="settings__container__option">
        <Select
          id="asset"
          label={'Asset'}
          value={wallet.asset}
          onChange={(v) => {
            updateValue('asset', v)
          }}
          options={[
            { id: '', value: '' },
            { id: 'USDC', value: 'USDC' },
            { id: 'USDT', value: 'USDT' },
          ]}
        />
      </div>
      <div className="settings__container__option">

        <Select
          id="asset"
          label={'Network'}
          value={wallet.network}
          onChange={(v) => {
            updateValue('network', v)
          }}
          options={[
            { id: '', value: '' },
            { id: 'ERC-20', value: 'ERC-20' },
          ]}
        />
      </div>
      <div className="settings__container__option">
        <Input
          type="text"
          id="walletAddress"
          label={'Wallet Address'}
          value={wallet.address}
          onChange={(value) => updateValue('address', value)}
          placeholder={'Type the wallet address'}
        />
      </div>
      <div className="settings__container__option--action">
        <button onClick={handle}>{buttonTitle()}</button>
      </div>
    </>
  );
};
export default AccountWallet;
