import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AuthenticationGuard from './components/AuthenticationGuard/AuthenticationGuard';
import Login from './containers/Login/Login';
import PaymentMenu from './containers/PaymentMenu/PaymentMenu';
import CustomerService from './containers/CustomerService/CustomerService';
import TransactionDetails from './containers/TransactionDetails/TransactionDetails';
import Settings from './containers/Settings';
import Settlement from './containers/Settlement/Settlement';
import RegisterAccount from './containers/RegisterAccount/RegisterAccount';
import Form from './containers/RegisterAccount/Form';
import FormDocuments from './containers/RegisterAccount/FormDocuments';
import Success from './containers/RegisterAccount/Success';
import SettlementHistory from './containers/SettlementHistory';
import { useNavigate } from 'react-router-dom';
import { singInUser, getUserAccount } from './api/gateway';
import SettlementDetails from './containers/SettlementDetails';
import PageInternalError from './containers/PageInternalError';
import Home from './containers/Home';
import OrderDetails from './containers/OrderDetails';
import Orders from './containers/Orders';
import SubAccounts from './containers/SubAccounts';
import SubAccountDetails from './containers/SubAccountDetails';
import RegisterSubAccount from './containers/RegisterSubAccount';
import Payout from './containers/Payout';
import Deposit from './containers/Deposit';
import Exchange from './containers/Exchange';
import PoolBalance from './containers/PoolBalance';

import './plugins/axios';
import './App.scss';

function App () {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) validUserRegistered();
  }, [isAuthenticated, getAccessTokenSilently, isLoading]);

  const validUserRegistered = async () => {
    const timeInterval = setInterval(async () => {
      await getAccessTokenSilently().then(async (token) => {
        clearInterval(timeInterval);
        await singInUser(token).then(async () => {
          await getUserAccount(token)
            .then(({ data }) => {
              if (!data.is_active) {
                return navigate('/success');
              } else {
                return navigate('/home');
              }
            })
            .catch((e) => {
              if (e.response.data.message === 'record not found') {
                return navigate('/register-account');
              }
            });
        });
      });
    }, 500);
  };

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/register-account"
        element={<RegisterAccount />}
      />
      <Route
        path="/register-account/form/:typeAccount"
        element={<Form />}
      />
      <Route
        path="/register-account/form/:typeAccount/documents/:accountID"
        element={<FormDocuments />}
      />
      <Route
        path="/success"
        element={<Success />}
      />
      <Route
        path="/home"
        element={
          <AuthenticationGuard>
            <Home />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/payments"
        element={
          <AuthenticationGuard>
            <PaymentMenu />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/customer-support"
        element={
          <AuthenticationGuard>
            <CustomerService />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/transaction-details/:transactionId"
        element={
          <AuthenticationGuard>
            <TransactionDetails />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/orders"
        element={
          <AuthenticationGuard>
            <Orders />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/order-details/:id"
        element={
          <AuthenticationGuard>
            <OrderDetails />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/settlement"
        element={
          <AuthenticationGuard>
            <Settlement />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/settlement-details/:id"
        element={
          <AuthenticationGuard>
            <SettlementDetails />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/settlement-history"
        element={
          <AuthenticationGuard>
            <SettlementHistory />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/sub-accounts"
        element={
          <AuthenticationGuard>
            <SubAccounts />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/sub-account/details/:id"
        element={
          <AuthenticationGuard>
            <SubAccountDetails />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/sub-account/register"
        element={
          <AuthenticationGuard>
            <RegisterSubAccount />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/settings"
        element={
          <AuthenticationGuard>
            <Settings />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/contact-support"
        element={
          <AuthenticationGuard>
            <PageInternalError />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/payout"
        element={
          <AuthenticationGuard>
            <Payout />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/deposit"
        element={
          <AuthenticationGuard>
            <Deposit />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/exchange"
        element={
          <AuthenticationGuard>
            <Exchange />
          </AuthenticationGuard>
        }
      />
      <Route
        path="/pool-balance"
        element={
          <AuthenticationGuard>
            <PoolBalance />
          </AuthenticationGuard>
        }
      />
    </Routes>
  );
}

export default App;
