import React, { useEffect, useState } from 'react'
import BasicTemplate from '../BasicTemplate'
import PayoutStep from './PayoutStep'
import { Select } from '../../components/Select'
import IconPurpleArrowRight from '../../assets/purple-arrow-right.png';
import { Input } from '../../components/Input';
import PayoutBankDetails from './PayoutBankDetails';
import PayoutRecipientDetails from './PayoutRecipientDetails';
import PayoutCreateBank from './PayoutCreateBank';
import PayoutCreateRequest from './PayoutCreateRequest';
import { useAuth0 } from '@auth0/auth0-react';
import { getSubAccountByTaxIdNumber, getAssetByType, getSubAccountBankByAccountID } from '../../api/gateway';
import { TAsset, TBank, TSubAccountDetails } from '../../helpers/types';
import { Link } from 'react-router-dom';

import './index.scss'

const emptyID = "00000000-0000-0000-0000-000000000000"

const assetType = 'fiat'

const recipientEmpty: TSubAccountDetails = {
  id: "",
  account_id: "",
  name: "",
  email: "",
  is_active: false,
  country: "",
  city: "",
  address: "",
  tax_id_type: "",
  tax_id_number: "",
  local_id_type: "",
  local_id_number: "",
  phone: "",
  post_code: "",
  kyc_status: '',
  created_at: ""
}

type Asset = {
  id: string;
  value: string;
}

const assetsEmpty: TAsset = {
  id: '',
  name: '',
  symbol: '',
  type: '',
  small_image_url: '',
  medium_image_url: '',
  large_image_url: '',
  networks: '',
  active: false
}

const bankEmpty: TBank = {
  id: '',
  bank_name: '',
  bank_code: '',
  city: '',
  state: '',
  postal_code: '',
  street_line: '',
  branch: '',
  account: '',
  bank_type: '',
  instant_payment: '',
  instant_payment_type: '',
  country: '',
  asset: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
}

export default function Payout () {
  const { getAccessTokenSilently } = useAuth0();
  const [currentStep, setCurrentStep] = useState<1 | 2 | 3 | 4>(1) // 1: Recipient, 2: Currency, 3: Request
  const [searchRecipient, setSearchRecipient] = useState('')
  const [recipient, setRecipient] = useState<TSubAccountDetails>(recipientEmpty)
  const [currency, setCurrency] = useState<TAsset>(assetsEmpty)
  const [assetsByType, setAssetsByType] = useState<TAsset[]>([]); // fiat, crypto
  const [assets, setAssets] = useState<Asset[]>([]);
  const [bankAccount, setBankAccount] = useState<TBank>(bankEmpty)
  const [bankError, setBankError] = useState(false)
  const [registerBank, setRegisterBank] = useState<boolean>(false);
  const [confirmSelectedBank, setConfirmSelectedBank] = useState<boolean>(false);

  const handleGetSubAccountFind = async () => {
    if (!searchRecipient) return
    await getAccessTokenSilently().then(async (token) => {
      await getSubAccountByTaxIdNumber(token, searchRecipient).then(({ data }) => {
        setRecipient(data)
      });
    });
  };

  const getAssets = async () => {
    await getAccessTokenSilently().then(async (token) => {
      await getAssetByType(token, assetType).then(({ data }) => {
        const resp = data.assets.map((asset: TAsset) => {
          if (!asset.active) return
          return ({
            id: asset.symbol,
            value: asset.name
          }
          )
        })
        resp.unshift({ id: '', value: 'Choose one' })
        setAssets(resp)
        setAssetsByType(data.assets)
      });
    });
  }

  useEffect(() => {
    getAssets()
  }, [])

  useEffect(() => {
    if (currency.name === '') return
    getAccessTokenSilently().then(async (token) => {
      await getSubAccountBankByAccountID(token, recipient.id, currency.symbol).then(({ data }) => {
        if (!data.list) {
          setBankError(true)
          return
        }
        setBankAccount(data.list[0])
        setBankError(false)
      });
    });
  }, [currency])

  return (
    <BasicTemplate>
      <div className='payout'>
        <h1>Request Payout</h1>
        <div>
          <PayoutStep currentStep={currentStep} />
        </div>
        {currentStep === 1 ? (
          <div className='payout__recipient'>
            <h2 className='payout__currency__title'>Search for Recipient</h2>
            {recipient.id.length && recipient.id !== emptyID ? (
              <PayoutRecipientDetails recipient={recipient} onContinue={() => setCurrentStep(2)} />
            ) : (
              <div className='payout__recipient__grid'>
                <div className='payout__recipient__grid__input'>
                  <Input
                    type="text"
                    id="recipient"
                    label={'Search for recipient details'}
                    value={searchRecipient}
                    onChange={(value) => setSearchRecipient(value)}
                    message="Insert the recipient's full name."
                    error={recipient.id === emptyID}
                    errorMessage='New Recipient. Please click below register their details.'
                  />
                  {recipient.id === emptyID ? (
                    <>
                      <Link to="/sub-account/register" className='payout__recipient__grid__input--register'>
                        Click here to register the new recipient
                        <img src={IconPurpleArrowRight} alt="icon to register details" />
                      </Link>

                      <button
                        onClick={() => {
                          setSearchRecipient('')
                          setRecipient(recipientEmpty)
                        }}
                        className='payout__recipient__grid__input--search'>Clear</button>
                    </>
                  ) : (
                    <button onClick={handleGetSubAccountFind} className='payout__recipient__grid__input--search'>Search</button>
                  )}
                </div>
                <div className='payout__recipient__grid__info'>
                  <div className='payout__recipient__grid__info__card'>
                    <h2 className='payout__recipient__grid__info__card--title'>Onboarding</h2>
                    <p className='payout__recipient__grid__info__card--info'>{"Inform the recipient's e-mail address"}</p>
                    <span className='payout__recipient__grid__info__card--text'>Beta is required by law to perform KYC in all recipients before processing transactions</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : currentStep === 2 ? (
          <div className='payout__currency'>
            {bankAccount.id && confirmSelectedBank ? (
              <PayoutBankDetails
                bank={bankAccount}
                subAccountId={recipient.id}
                onContinue={() => setCurrentStep(3)}
                onDelete={() => {
                  setConfirmSelectedBank(false)
                  setRegisterBank(true)
                }}
              />
            ) : registerBank ? (
              <PayoutCreateBank
                subAccountId={recipient.id}
                onContinue={() => {
                  setSearchRecipient('')
                  setBankError(false)
                  getAssets()
                  setCurrency(assetsEmpty)
                  setRegisterBank(false)
                }}
              />
            ) : (
              <>
                <h2 className='payout__currency__title'>Choose Currency</h2>
                <Select
                  id="currency"
                  label={'Currency'}
                  placeholder='Choose the preferred currency for this Payout'
                  value={currency.name}
                  onChange={(v) => {
                    const resp = assetsByType.find((asset) => asset.name === v)
                    if (!resp) return
                    setCurrency(resp)
                  }}
                  options={assets}
                  error={bankError}
                  errorMessage="This Sub Account doesn't have a bank account registered for this currency."
                />
                <div className='payout__currency__actions'>
                  {bankError && (
                    <button onClick={() => setRegisterBank(true)} className='payout__currency__actions--register'>
                      Click here to register the withdrawal details
                      <img src={IconPurpleArrowRight} alt="icon to register details" />
                    </button>
                  )}
                  <div className='payout__currency__actions__grid'>
                    <button onClick={() => setCurrentStep(1)} className='payout__currency__actions__grid--back'>Back</button>
                    <button
                      className='payout__currency__actions__grid--continue'
                      disabled={bankAccount.id.length === 0 || bankError}
                      onClick={() => setConfirmSelectedBank(true)}
                    >Continue</button>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : currentStep === 3 ? (
          <PayoutCreateRequest subAccountID={recipient.id} targetAsset={currency.symbol} instantPaymentType={bankAccount.instant_payment_type} />
        ) : (
          <></>
        )}
      </div >
    </BasicTemplate >
  )
}