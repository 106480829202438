import React, { useState } from 'react';
import FAQ from '../../components/FAQ/FAQ';
import BasicTemplate from '../BasicTemplate';
import Questions from './questions'
import { createCustomerSupport } from '../../api/gateway';
import { useAuth0 } from '@auth0/auth0-react';
import { CustomerSupport, TBannerLocal } from '../../helpers/types';
import MenuBar from '../../components/MenuBar';
import Banner from '../../components/Banner';

import './CustomerService.scss';

const initialShowBanner: TBannerLocal = { show: false, type: 'success', title: '', text: '' }


const CustomerService = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [message, setMessage] = useState('');
  const [currentTab, setCurrentTab] = useState<string>('Contact');
  const [showBanner, setShowBanner] = useState<TBannerLocal>(initialShowBanner);

  const handleCreateCustomerSupport = async () => {
    await getAccessTokenSilently().then(async (token) => {
      const data: CustomerSupport = {
        body: message
      }
      await createCustomerSupport(token, data).then(() => {
        setShowBanner({ type: 'success', title: 'Success', text: 'Your message has been sent.', show: true })
        setMessage('')
      }).catch((e) => {
        setShowBanner({ type: 'error', title: 'Error', text: 'Your message has`t been sent.', show: true })
        console.error(e)
      })
    })
  }

  return (
    <BasicTemplate>
      <div className="customerService">
        <div className="customerService__header"><h1>Help</h1></div>
        <MenuBar selectedOption={setCurrentTab} currentTabSelected={currentTab} options={['Contact', 'FAQ']} />

        {currentTab === 'Contact' && (
          <>
            <div className="customerService__message">
              <div className="customerService__message__header">Contact Form</div>
              <label className="customerService__message-box">
                If you still have questions, please leave us a message:
              </label>
              <textarea
                value={message}
                placeholder={'Type your message.'}
                rows={7}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <button disabled={!message} onClick={() => handleCreateCustomerSupport()}>Create Request</button>
            </div>
            <div style={{ maxWidth: 372 }}>
              <Banner
                type={showBanner.type}
                title={showBanner.title}
                text={showBanner.text}
                show={showBanner.show}
                onClose={() => setShowBanner(initialShowBanner)}
              />
            </div>
          </>
        )}

        {currentTab === 'FAQ' && (
          <div className="customerService__container">
            {Questions.map((v, index) => {
              return (
                <FAQ key={index} question={v.question} answer={v.answer} />
              )
            })}
          </div>
        )}
      </div>
    </BasicTemplate>
  );
};

export default CustomerService;
