import React, { FC, useEffect, useState } from 'react'
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';
import Banner from '../../components/Banner';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserAccountBalancePool, postOrderExchange, postOrderWithdrawal } from '../../api/gateway';
import { TBalances, TBannerLocal, TOrderExchange, TOrderWithdrawal } from '../../helpers/types';

import './PayoutCreateRequest.scss'

interface registerInterface {
  sourceAsset: string;
  sourceAmount: number;
  targetAsset: string;
  targetAmount: number;
}

const initialRegisterState: registerInterface = {
  sourceAsset: '',
  sourceAmount: 0.0,
  targetAsset: '',
  targetAmount: 0.0,
}

type Props = {
  targetAsset: string;
  subAccountID: string;
  instantPaymentType: string
}

const initialShowBanner: TBannerLocal = { show: false, type: 'success', title: '', text: '' }

const PayoutCreateRequest: FC<Props> = ({ targetAsset, subAccountID, instantPaymentType }: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [register, setRegister] = useState<registerInterface>(initialRegisterState);
  const [showBanner, setShowBanner] = useState<TBannerLocal>(initialShowBanner);
  const [balances, setBalances] = useState<TBalances[]>([])
  const [balancesTotal, setBalancesTotal] = useState<number>(0)

  const updateValue = (key: string, value: string | number | undefined) => {
    setRegister((bank) => ({
      ...bank,
      [key]: value,
    }));
  };

  const handleGetUserAccountBalancePool = async () => {
    await getAccessTokenSilently().then(async (token) => {
      await getUserAccountBalancePool(token).then(({ data }) => {
        setBalances(data.balances)

        let sum = 0
        for (let i = 0; i < data.balances.length; i++) {
          sum += data.balances[i].amount
        }
        setBalancesTotal(sum)
      })
    })
  }

  useEffect(() => {
    handleGetUserAccountBalancePool()
  }, [])

  const formatAmount = (amount: number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
  }

  const handleSourceAsset = () => {
    const sources = balances.map((balance) => {
      return {
        id: balance.asset,
        value: balance.asset
      }
    })

    sources.unshift({ id: '', value: 'Choose one asset' })

    return sources
  }

  const handleCreatePayout = async () => {
    let network = ''

    switch (register.sourceAsset) {
      case 'USDT':
        network = 'tron'
        break;
      case 'USDRIF':
        network = 'rsk'
        break;
      default:
        break;
    }

    const payloadOrderExchange: TOrderExchange = {
      source_asset: register.sourceAsset,
      source_amount: Number(register.sourceAmount),
      target_asset: targetAsset,
      target_amount: Number(register.targetAmount),
      pool_balance: true,
      network: network
    }

    await getAccessTokenSilently().then(async (token) => {
      await postOrderExchange(token, subAccountID, payloadOrderExchange).then(async ({ data }) => {
        handleGetUserAccountBalancePool()

        const payloadOrderWithdrawal: TOrderWithdrawal = {
          target_asset: targetAsset,
          target_amount: data.amount,
          target_destination: "",
          instant_payment_type: instantPaymentType,
          description: "Withdrawal operation"
        }

        await postOrderWithdrawal(token, subAccountID, payloadOrderWithdrawal)
        setShowBanner({ type: 'success', title: 'Success', text: 'Order Created', show: true })
        setRegister(initialRegisterState)
      }).catch(() => {
        setShowBanner({ type: 'error', title: 'Error', text: 'Error creating order', show: true })
      })
    })
  }

  return (
    <section className='payoutCreateRequest'>
      <div className='payoutCreateRequest__form'>
        <Select
          id='sourceAsset'
          value={register.sourceAsset}
          label='Source Asset'
          options={handleSourceAsset()}
          onChange={(value) => updateValue('sourceAsset', value)}
          placeholder='Choose your preferred currency to initiate the payout'
        />
        <Input
          type="number"
          id="sourceAmount"
          label={'Source Amount'}
          message="Leave it in blank If you prefer to inform the Target Amount."
          value={register.sourceAmount}
          onChange={(value) => updateValue('sourceAmount', value)}
        />
        <Input
          disabled={true}
          type="text"
          id="targetAsset"
          label={'Target Asset'}
          value={targetAsset}
          onChange={(value) => updateValue('targetAsset', value)}
        />
        <Input
          type="number"
          id="targetAmount"
          label={'Target Amount'}
          message="Leave it in blank If you prefer to inform the Source Amount."
          value={register.targetAmount}
          onChange={(value) => updateValue('targetAmount', value)}
        />
        <div className='payoutCreateRequest__form__action'>
          <button
            onClick={handleCreatePayout}
            disabled={register.sourceAsset === '' || (!register.sourceAmount && !register.targetAmount)}
            className='payoutCreateRequest__form__action--register'>
            Create Request
          </button>
        </div>
        <div className='payoutCreateRequest__form__message'>
          <Banner
            type={showBanner.type}
            title={showBanner.title}
            text={showBanner.text}
            show={showBanner.show}
            onClose={() => setShowBanner(initialShowBanner)}
          />
        </div>
      </div>
      <div className='payoutCreateRequest__balances'>
        <div className='payoutCreateRequest__balances__grid'>
          <h6>Balances</h6>
          <span className='payoutCreateRequest__balances__grid--total'>{formatAmount(balancesTotal)}</span>
        </div>
        <div className='payoutCreateRequest__balances__divide'></div>
        {balances.map((balance, index) => {
          return (
            <div key={index} className='payoutCreateRequest__balances__grid'>
              <span className='payoutCreateRequest__balances__grid--value'>{balance.asset}</span>
              <span className='payoutCreateRequest__balances__grid--value'>{balance.amount}</span>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default PayoutCreateRequest