import React, { FC, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLoader } from '../../components/PageLoader/PageLoader';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './Login.scss';

const Login: FC = () => {
  const { loginWithRedirect, isLoading, getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();
  const [activeLoading, setActiveLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const auth0Error = searchParams.get('error')
  const auth0ErrorDescription = searchParams.get('error_description')
  const emailRedirect = searchParams.get('email_redirect')

  const handlerLogin = async () => {
    await loginWithRedirect().then(() => {
      setActiveLoading(true);
    });
  };

  const handlerSingUp = async () => {
    await loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    }).then(() => {
      setActiveLoading(true);
    });
  };

  const description = () => {
    if (auth0Error) {
      switch (auth0ErrorDescription) {
        case 'emailSend':
          return 'Please click on the link that has just been sent to your email address to continue the registration process.'
        case 'emailVerification':
          return 'Please click on the link that has just been sent to your email address to continue the registration process.'
        default:
          return <div>We had an internal error to send your verification e-mail. Please reach out to <a href='mailto:support@betaapp.com'>support@betaapp.com</a>.</div>;
      }
    }
    if (emailRedirect && emailRedirect === 'emailConfirmed') return <div> Your email has been confirmed. <br /> You can now log in to your account.</div>

    return 'You have to authenticate to continue'
  }

  const title = () => {
    if (auth0Error) {
      switch (auth0ErrorDescription) {
        case 'emailSend':
          return 'Verify your email'
        case 'emailVerification':
          return 'Verify your email'
      }
    }

    return 'Welcome to Beta'
  }

  useEffect(() => {
    (async () => {
      await getAccessTokenSilently()
        .then(() => {
          navigate('/home');
        }).catch(console.log)
    })();
  }, []);

  return (
    <div className="login">
      <div className="login__image"></div>
      <div className="login__type">
        <div className="login__type__header">
          <div className='login__type__header--img' />
          <div className="header__language"></div>
        </div>
        <h3>{title()}</h3>
        <p>{description()}</p>
        {emailRedirect && emailRedirect === 'emailConfirmed' ? (
          <div className="login__type__actions">
            <button onClick={handlerLogin}>Continue</button>
          </div>
        ) : (activeLoading || isLoading) ? (
          <div className="login__type__loading">
            <PageLoader />
          </div>
        ) : (
          <div className="login__type__actions">
            {!auth0Error && !auth0ErrorDescription && (
              <>
                <button onClick={handlerLogin}>Sign In</button>
                <button onClick={handlerSingUp}>Create an account</button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
