import React, { useEffect, useState } from 'react'
import BasicTemplate from '../BasicTemplate'
import { useAuth0 } from '@auth0/auth0-react';
import { TAsset, TBalances, TBannerLocal, TOrderExchange, TQuoteV2 } from '../../helpers/types';
import { getAsset, getUserAccountBalancePool, getQuote, postOrderExchange } from '../../api/gateway';
import Switch from '../../components/Switch';
import Tooltip from '../../components/Tooltip';
import { Input } from '../../components/Input';
import Banner from '../../components/Banner';

import './index.scss'

const initialShowBanner: TBannerLocal = { show: false, type: 'success', title: '', text: '' }

type originType = 'source' | 'target'

export default function Exchange () {
  const { getAccessTokenSilently } = useAuth0();
  const [sourceAsset, setSourceAsset] = useState<string>('')
  const [targetAsset, setTargetAsset] = useState<string>('')
  const [sourceAmount, setSourceAmount] = useState<string>('0.0')
  const [targetAmount, setTargetAmount] = useState<string>('0.0')
  const [subAccountId, setSubAccountId] = useState<string>('')
  const [assets, setAssets] = useState<TAsset[]>([]);
  const [checked, setChecked] = useState(false)
  const [showBanner, setShowBanner] = useState<TBannerLocal>(initialShowBanner);
  const [balances, setBalances] = useState<TBalances[]>([])
  const [quote, setQuote] = useState<string>('0.0')
  const [isDisableButton, setIsDisableButton] = useState<boolean>(true)
  const [currentNetwork, setCurrentNetwork] = useState<string>('')
  const [currentOrigin, setCurrentOrigin] = useState<originType | null>(null)

  const handleGetAsset = () => {
    getAccessTokenSilently().then(async (token) => {
      getAsset(token).then(({ data }) => {
        data.assets.unshift({ id: '', value: 'Choose one network' })
        setAssets(data.assets)
      })
    })
  }

  const handleGetPoolBalance = () => {
    getAccessTokenSilently().then(async (token) => {
      getUserAccountBalancePool(token).then(({ data }) => {
        setBalances(data.balances)
      })
    })
  }

  const filterBalanceByAsset = (asset: string) => {
    const amount = balances.find((balance) => balance.asset === asset)?.amount
    return new Intl.NumberFormat('en-US').format(amount || 0)
  }

  const filterSourceAsset = () => {
    if (!sourceAsset && !targetAsset) return assets
    const type = assets.find((value) => value.symbol === targetAsset)?.type

    return assets.filter((value) => value.type !== type)
  }

  const filterTargetAsset = () => {
    if (!targetAsset && !sourceAsset) return assets
    const type = assets.find((value) => value.symbol === sourceAsset)?.type

    return assets.filter((value) => value.type !== type)
  }

  const handleGetQuote = () => {
    setIsDisableButton(true)
    if (!sourceAsset || !targetAsset && (sourceAmount.length === 0 || targetAmount.length === 0)) return
    const origin = isSource() ? 'source' : 'target'
    setCurrentOrigin(origin)
    const payload: TQuoteV2 = {
      network: currentNetwork,
      product: 'exchange',
      source_amount: Number(sourceAmount),
      source_asset: sourceAsset,
      target_amount: Number(targetAmount),
      target_asset: targetAsset,
    }

    getAccessTokenSilently().then(async (token) => {
      getQuote(token, payload).then(({ data }) => {
        if (origin === 'source') {
          setTargetAmount(String(data.target_amount_estimate))
        } else {
          setSourceAmount(String(data.source_amount_estimate))
        }
        setQuote(data.price_reference)
        setIsDisableButton(false)
      }).catch((error) => {
        setShowBanner({ type: 'error', title: 'Error', text: error.response.data.message, show: true })
        setIsDisableButton(true)
      })
    })
  }

  const filterNetworkByAsset = (origin: originType) => {
    let sourceNetwork = ''
    let targetNetwork = ''

    const sourceNetworks = assets.find((value) => value.symbol === sourceAsset)?.networks
    if (!sourceNetworks) {
      sourceNetwork = ''
    } else {
      sourceNetwork = sourceNetworks[0].name
    }

    const targetNetworks = assets.find((value) => value.symbol === targetAsset)?.networks
    if (!targetNetworks) {
      targetNetwork = ''
    } else {
      targetNetwork = targetNetworks[0].name
    }

    const network = origin === 'source' ? sourceNetwork === '' ? targetNetwork : sourceNetwork : targetNetwork === '' ? sourceNetwork : targetNetwork

    setCurrentNetwork(network)
  }

  useEffect(() => {
    handleGetAsset()
    handleGetPoolBalance()
  }, [])

  useEffect(() => {
    filterNetworkByAsset('source')
  }, [sourceAsset])

  useEffect(() => {
    filterNetworkByAsset('target')
  }, [targetAsset])

  const handleCreateOrder = () => {
    const payload: TOrderExchange = {
      source_asset: sourceAsset,
      source_amount: currentOrigin === 'source' ? Number(sourceAmount) : 0,
      target_asset: targetAsset,
      target_amount: currentOrigin === 'target' ? Number(targetAmount) : 0,
      network: currentNetwork,
      pool_balance: checked,
    }
    getAccessTokenSilently().then(async (token) => {
      postOrderExchange(token, subAccountId, payload).then(() => {
        setShowBanner({ type: 'success', title: 'Success', text: 'Your Order has been created.', show: true })
      }).catch((error) => {
        setShowBanner({ type: 'error', title: 'Error', text: error.response.data.message, show: true })
      })
    })
  }

  const isSource = () => {
    return sourceAsset && !(Number(sourceAmount) === 0)
  }

  const isDisableCompareButton = () => {
    if (!sourceAsset || !sourceAmount || !targetAsset) {
      return true
    } else if (!targetAsset || !targetAmount || !sourceAsset) {
      return true
    }
    return false
  }

  return (
    <BasicTemplate>
      <div className='exchange'>
        <div className='exchange__card'>
          <h1>Exchange</h1>
          <div className='exchange__card__asset'>
            <div className='exchange__card__asset--style'>
              <h2>Source Asset</h2>
              <div className='exchange__card__asset__content'>
                <input
                  type='number'
                  id='source_amount'
                  min={0}
                  value={sourceAmount}
                  onChange={({ target }) => {
                    setSourceAmount(target.value)
                    setTargetAmount('0.0')
                  }}
                />
                <select
                  value={sourceAsset}
                  onChange={({ target }) => {
                    setShowBanner(initialShowBanner)
                    setSourceAsset(target.value)
                  }}
                >
                  {filterSourceAsset().map((asset) => (
                    <option key={`source-${asset.symbol}`} value={asset.symbol}>
                      {asset.symbol}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <p>Available {filterBalanceByAsset(sourceAsset)}</p>
          </div>
          <div className='exchange__card__asset'>
            <div className='exchange__card__asset--style'>
              <h2>Target Asset</h2>
              <div className='exchange__card__asset__content'>
                <input
                  type='number'
                  id='target_amount'
                  min={0}
                  value={targetAmount}
                  onChange={({ target }) => {
                    setTargetAmount(target.value)
                    setSourceAmount('0.0')
                  }}
                />
                <select
                  value={targetAsset}
                  onChange={({ target }) => {
                    setShowBanner(initialShowBanner)
                    setTargetAsset(target.value)
                  }}
                >
                  {filterTargetAsset().map((asset) => (
                    <option key={`target-${asset.symbol}`} value={asset.symbol}>
                      {asset.symbol}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <p>Quote {quote}</p>
          </div>
          <button
            disabled={isDisableCompareButton()}
            className='exchange__card__action'
            onClick={handleGetQuote}
          >Compare Prices</button>
          <div className='exchange__card__switch'>
            <Switch checked={checked} onChange={(value) => setChecked(value)} />
            <span>Pool Balance Deposit</span>
            <Tooltip text={'When enabled, Beta will display the deposit details of your Master Account.'} />
          </div>
          {!checked && (
            <Input
              type="text"
              id="branch"
              label={'Sub Account ID'}
              message='Inform the Sub Account IDs'
              value={subAccountId}
              onChange={(value) => setSubAccountId(value)}
            />
          )}
          <button
            disabled={isDisableButton || subAccountId === ''}
            className='exchange__card__action'
            onClick={handleCreateOrder}
          >Create Order</button>
          <Banner
            type={showBanner.type}
            title={showBanner.title}
            text={showBanner.text}
            show={showBanner.show}
            onClose={() => setShowBanner(initialShowBanner)}
          />
        </div>
      </div>
    </BasicTemplate>
  )
}