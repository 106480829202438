import React, { FC, useState } from 'react'
import './index.scss'

interface Values {
  id: string | number;
  name: string;
  value: string;
}

type Props = {
  id: string;
  value: string;
  title: string;
  onChange: (value: string) => void;
  error?: boolean;
  required?: boolean;
  errorMessage?: string;
  options: Values[];
}

const SelectFilter: FC<Props> = (props) => {
  const [value, setValue] = useState(props.value);

  return (
    <div className="selectFilter">
      <select
        id={props.id}
        value={value}
        onChange={({ target }) => {
          console.log("aqui:", target.value);
          const selected = props.options.find((v) => v.name === target.value);
          if (!selected) return;
          setValue(selected.value);
          props.onChange(selected.value);
        }}
      >
        <option className="title">{props.title}</option>
        {props.options.map((v) => {
          return <option key={v.id}>{v.name}</option>
        })}
      </select>
    </div>
  )
}

export default SelectFilter;