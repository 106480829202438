import React, { FC, useEffect, useState } from 'react'
import { TLocalQuote, TQuoteV2Response } from '../../helpers/types'
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth0 } from '@auth0/auth0-react';
import { getQuote } from '../../api/gateway';

type Props = {
  currentQuote: TLocalQuote
  currentTab: number
}

const emptyQuote: TQuoteV2Response = {
  source_amount_estimate: "-",
  target_amount_estimate: "-",
  source_asset: '',
  source_amount: 0,
  target_asset: '',
  target_amount: 0,
  asset: {
    name: '',
    symbol: '',
    type: '',
    small_image_url: '',
    medium_image_url: '',
    large_image_url: '',
    id: '',
    active: false,
    networks: undefined
  }
}

const HomeCurrency: FC<Props> = ({ currentQuote, currentTab }: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [buy, setBuy] = useState<TQuoteV2Response | null>(null);
  const [sell, setSell] = useState<TQuoteV2Response | null>(null);

  const handle = async () => {
    getAccessTokenSilently().then(async (token) => {
      if (currentQuote.buyPayload) {
        const buyResp = await getQuote(token, currentQuote.buyPayload);
        setBuy(buyResp.data);
      } else {
        setBuy(emptyQuote);
      }
      if (currentQuote.sellPayload) {
        const sellResp = await getQuote(token, currentQuote.sellPayload);
        setSell(sellResp.data);
      } else {
        setSell(emptyQuote);
      }
    })
  };

  useEffect(() => {
    setBuy(null)
    setSell(null)
    handle()
  }, [currentTab])

  return (
    <div className='home__card__item'>
      <div className='home__card__item__logo'>
        <img src={currentQuote.image} alt={'loading'} />
        <span>{currentQuote.name}</span>
      </div>
      <div className='home__card__item__divider'></div>
      <div className='home__card__item__content'>
        <div className='home__card__item__content__amount'>
          <span className='home__card__item__content__amount__title'>Buy</span>
          {!buy ? <CircularProgress size={15} /> : (
            <span className='home__card__item__content__amount__value'>{typeof buy.source_amount_estimate === 'number' ? buy.source_amount_estimate.toFixed(2) : buy.source_amount_estimate}</span>
          )}
        </div>
        <div className='home__card__item__content__amount'>
          <span className='home__card__item__content__amount__title'>Sell</span>
          {!sell ? <CircularProgress size={15} /> : (
            <span className='home__card__item__content__amount__value'>{typeof sell.target_amount_estimate === 'number' ? sell.target_amount_estimate.toFixed(2) : sell.target_amount_estimate}</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default HomeCurrency