export default (status: string) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return '#FFB381';
      case 'canceled':
        return '#EAEAEA';
      case 'confirmed':
        return '#F5F529';
      default:
        return '#F9F9F9'; 
  }
}