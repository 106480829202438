import React, { FC, useState } from 'react'
import {ReactComponent as ArrowDown} from '../../assets/down-arrow.svg'

import './FAQ.scss'

type Props = {
  question: string;
  answer: string;
}

const FAQ:FC<Props> = ({ question, answer }:Props) => {
  const [active, setActive] = useState(false)
  return (
    <div className={`faq ${active && 'active'}`}>
      <div className="faq__question" onClick={() => setActive(!active)}>
        {question} <ArrowDown />
      </div>
      {active && <p>{answer}</p>}
    </div>
  )
}

export default FAQ
