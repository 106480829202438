import React, { useState, useEffect } from 'react'
import BasicTemplate from '../BasicTemplate'
import { useParams } from 'react-router-dom';
import { IOrderDetails } from '../../helpers/types';
import { useAuth0 } from '@auth0/auth0-react';
import { getOrdersDetails } from '../../api/gateway';
import dayjs from 'dayjs';
import OrderIcon from '../../assets/order-icon.png'
import TagStatus from '../../components/TagStatus';
import IconPositive from '../../assets/icon-positive.png'

import './index.scss'

const emptyOrder: IOrderDetails = {
  id: '',
  target_asset: '',
  target_amount: 0,
  source_amount: 0,
  source_asset: '',
  total_fee: 0,
  description: '',
  message: '',
  recipient_email: '',
  status: '',
  settlement_status: '',
  created_at: '',
  timelines: [],
};

export default function OrderDetails () {
  const [order, setOrder] = useState<IOrderDetails>(emptyOrder);
  const { id } = useParams();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      if (!id) return;
      await getAccessTokenSilently()
        .then(async (token) => {
          await getOrdersDetails(token, id).then(({ data }) => {
            setOrder(data);
          });
        })
        .catch(console.error);
    })();

    return () => {
      setOrder(emptyOrder);
    };
  }, []);

  const formatDate = (date: string) => {
    return (
      dayjs(date).format('MMMM D, YYYY') +
      ', ' +
      dayjs(date).format('hh:mm A')
    );
  };

  const status = () => {
    switch (order.status) {
      case 'open':
        return 'info'
      case 'pending':
        return 'warning'
      case 'completed':
        return 'positive'
      case 'canceled':
        return 'negative'
      default:
        return 'neutral';
    }
  }

  const settlementStatus = () => {
    switch (order.settlement_status) {
      case 'not settled':
        return 'info'
      case 'settled':
        return 'positive'
      default:
        return 'neutral';
    }
  }

  return (
    <BasicTemplate>
      <div className='orderDetails'>
        <div className='orderDetails__card'>
          <div className='orderDetails__card__title'>
            <img src={OrderIcon} alt="order icon" />
            <h1>Order</h1>
            <span>{order.id}</span>
          </div>
          <div className='orderDetails__card__review'>
            <span className='targetAmount'>{order.target_amount.toFixed(2)}</span>
            <span className='targetAsset'>{order.target_asset}</span>
            <TagStatus name={order.status} status={status()} showIcon={true} />
          </div>
          <div className='orderDetails__card__resume'>
            <div className='orderDetails__card__resume--grid'>
              <span className='orderDetails__card__resume--title'>Date</span>
              <span className='orderDetails__card__resume--text'>{formatDate(order.created_at)}</span>
            </div>
            <div className='orderDetails__card__resume--divide'></div>
            <div className='orderDetails__card__resume--grid'>
              <span className='orderDetails__card__resume--title'>Customer</span>
              <span className='orderDetails__card__resume--text'>{order.recipient_email}</span>
            </div>
            <div className='orderDetails__card__resume--divide'></div>
            <div className='orderDetails__card__resume--grid'>
              <span className='orderDetails__card__resume--title'>Source Asset</span>
              <span className='orderDetails__card__resume--text'>{order.source_asset}</span>
            </div>
            <div className='orderDetails__card__resume--divide'></div>
            <div className='orderDetails__card__resume--grid'>
              <span className='orderDetails__card__resume--title'>Target Asset</span>
              <span className='orderDetails__card__resume--text'>{order.target_asset}</span>
            </div>
            <div className='orderDetails__card__resume--divide'></div>
            <div className='orderDetails__card__resume--grid'>
              <span className='orderDetails__card__resume--title'>Settlement Status</span>
              {order.settlement_status && (
                <TagStatus name={order.settlement_status} status={settlementStatus()} showIcon={false} />
              )}
            </div>
          </div>
          <div className='orderDetails__card__timeline'>
            <h2>Timeline</h2>
            <div className='orderDetails__divideHorizontal'></div>
            {order.timelines.map((timeline, index) => {
              return (
                timeline.executed &&
                <div key={index} className='orderDetails__card__timeline__item'>
                  <div className='orderDetails__card__timeline__item__title'>
                    <img src={IconPositive} alt="icon positive" />
                    <span>{timeline.order_step}</span>
                  </div>
                  <span className='orderDetails__card__timeline__item__date'>{formatDate(timeline.executed_at)}</span>
                  {index !== 5 && (
                    <div className='orderDetails__divideHorizontal'></div>
                  )}
                </div>
              )
            })}
          </div>
          <div className='orderDetails__card__details'>
            <h2>Payment Details</h2>
            <div className='orderDetails__divideHorizontal'></div>
            <div className='orderDetails__card__details__content'>
              <div className='orderDetails__card__details__content__grid'>
                <span>Source Asset</span>
                <span>{order.source_asset}</span>
              </div>
              <div className='orderDetails__card__details__content__grid'>
                <span>Source Amount</span>
                <span>{order.source_amount}</span>
              </div>
              <div className='orderDetails__card__details__content__grid'>
                <span>Target Asset</span>
                <span>{order.target_asset}</span>
              </div>
              <div className='orderDetails__card__details__content__grid'>
                <span>Target Amount</span>
                <span>{order.target_amount.toFixed(2)}</span>
              </div>
              <div className='orderDetails__card__details__content__grid'>
                <span>Fee</span>
                <span>{order.total_fee}</span>
              </div>
              <div className='orderDetails__card__details__content__grid'>
                <span>Status</span>
                <span>{order.status}</span>
              </div>
              <div className='orderDetails__card__details__content__grid'>
                <span>Description</span>
                <span>{order.description}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasicTemplate>
  )
}