import React, { FC, useEffect, useState } from 'react';
import { User, useAuth0 } from '@auth0/auth0-react';
import {
  Auth0UpdateUser,
  SnackbarType,
  UserAccountDocument,
  UserAccountDocumentType,
} from '../../helpers/types';
import {
  getUser,
  updateUserEmail,
  updateUserPassword,
  getUserAccountDocument,
  getUserAccountDocumentType,
  getDocumentURL,
} from '../../api/gateway';

const emptyUpdateUser: Auth0UpdateUser = {
  email: '',
  password: '',
};

type optionsUpdate = {
  email: boolean;
  password: boolean;
};

const emptyOptions: optionsUpdate = {
  email: false,
  password: false,
};

type Props = {
  handleSnackBar: (value: SnackbarType) => void;
  accountName: string;
  accountTypeName: string;
};

const AccountSettings: FC<Props> = ({
  handleSnackBar,
  accountName,
  accountTypeName,
}: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [updateUser, setUpdateUser] =
    useState<Auth0UpdateUser>(emptyUpdateUser);
  const [user, setUser] = useState<User>();
  const [options, setOptions] = useState<optionsUpdate>(emptyOptions);
  const [brandLogo, setBrandLogo] = useState<string>('');

  const updateOption = (key: string, value: boolean) => {
    setOptions((o) => ({
      ...o,
      [key]: value,
    }));
  };

  const handleGetUser = async () => {
    await getAccessTokenSilently().then(async (token) => {
      await getUser(token).then(({ data }) => {
        setUser(data);
      });
    });
  };

  const updatePassword = async () => {
    await getAccessTokenSilently().then(async (token) => {
      if (!updateUser.password) return;
      updateUserPassword(token, { password: updateUser.password })
        .then(({ data }) => {
          handleSnackBar({
            open: true,
            title: data.message,
            severity: 'success',
          });
          updateOption('password', false);
        })
        .catch(({ response }) => {
          handleSnackBar({
            open: true,
            title: response.data.message,
            severity: 'error',
          });
        });
    });
  };

  const updateEmail = async () => {
    await getAccessTokenSilently().then(async (token) => {
      if (!updateUser.email) return;
      updateUserEmail(token, { email: updateUser.email })
        .then(({ data }) => {
          handleSnackBar({
            open: true,
            title: data.message,
            severity: 'success',
          });
          updateOption('email', false);
        })
        .catch(({ response }) => {
          handleSnackBar({
            open: true,
            title: response.data.message,
            severity: 'error',
          });
        });
    });
  };

  const handleGetUserAccountDocument = async () => {
    await getAccessTokenSilently().then(async (token) => {
      const {
        data: { list: documentTypes },
      }: { data: { list: UserAccountDocumentType[] } } =
        await getUserAccountDocumentType();
      const {
        data: { list: documents },
      }: { data: { list: UserAccountDocument[] } } =
        await getUserAccountDocument(token);

      const documentTypeLogoId = documentTypes.find(({ name }) => name.toLowerCase() === "brand logo")?.id

      if (documentTypeLogoId) {
        const id = documents.find(({ account_documents_type_id }) => account_documents_type_id === documentTypeLogoId)?.id

        if (id) await getDocumentURL(token, id).then(({ data }) => {
          setBrandLogo(data.file_url)
        })
      }
    });
  };

  useEffect(() => {
    handleGetUser();
    if (accountTypeName.toLowerCase() === 'business')
      handleGetUserAccountDocument();
  }, []);

  useEffect(() => {
    if (accountTypeName.toLowerCase() === 'business')
      handleGetUserAccountDocument();
  }, [accountTypeName]);

  return (
    <>
      <div className="settings__container">
        <h3 style={{ paddingTop: 24 }}>Account Settings</h3>
        <div className='settings__container__line'></div>
        <div className="settings__container__info">
          <label htmlFor="account-name">Account Name</label>
          <span>{accountName}</span>
        </div>
        <div className="settings__container__option">
          <label htmlFor="email">E-mail</label>
          {!options.email ? (
            <div className="settings__container__option--info">
              <span>{user?.email}</span>
              <button
                onClick={() => {
                  updateOption('email', true);
                }}
              >
                Edit
              </button>
            </div>
          ) : (
            <div className="settings__container__option--change">
              <input
                value={updateUser.email}
                onChange={({ target }) =>
                  setUpdateUser({ email: target.value })
                }
                type="email"
                id="email"
              />
              <button onClick={updateEmail}>OK</button>
            </div>
          )}
        </div>
        <div className="settings__container__option">
          <label htmlFor="password">Password</label>
          {!options.password ? (
            <div className="settings__container__option--info">
              <span>********</span>
              <button
                onClick={() => {
                  updateOption('password', true);
                }}
              >
                Edit
              </button>
            </div>
          ) : (
            <div className="settings__container__option--change">
              <input
                value={updateUser.password}
                onChange={({ target }) =>
                  setUpdateUser({ password: target.value })
                }
                type="password"
                id="password"
              />
              <button onClick={updatePassword}>OK</button>
            </div>
          )}
        </div>
        {accountTypeName.toLowerCase() === 'business' && brandLogo && (
          <div className="settings__container__info">
            <label htmlFor="account-name">Brand logo:</label>
            <img
              src={brandLogo}
              alt="logo"
            />
            <div className="settings__container__info--brand">
              Please contact your Account Manager If you want to change your
              logo
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AccountSettings;
