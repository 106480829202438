import Axios from 'axios';
import urlJoin from 'url-join';

import {
  CreateOrder,
  CreateUpdateAccountBank,
  CreateUpdateAccountWallet,
  CustomerSupport,
  OrderBy,
  PatchUserAccount,
  TCallback,
  UploadFile,
  UserAccount,
  TQuote,
  TBank,
  TQuoteV2,
  TOrderExchange,
  TOrderWithdrawal,
  TSubAccountCreate,
  TSubAccountBankAccountExternal,
  TSubAccountWalletAddressExternal,
} from '../helpers/types';

const { REACT_APP_BASE_URI } = process.env;

const gatewayUrl = REACT_APP_BASE_URI as string;

export const postUser = (token: string) => {
  const url = urlJoin(gatewayUrl, 'v1/user');

  return Axios.post(url, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const singInUser = (token: string) => {
  const url = urlJoin(gatewayUrl, 'v1/user/singin');

  return Axios.post(url, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUser = (token: string) => {
  const url = urlJoin(gatewayUrl, 'v1/user');

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserAccount = (token: string) => {
  const url = urlJoin(gatewayUrl, 'v1/user/account');

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserAccountType = () => {
  const url = urlJoin(gatewayUrl, 'v1/user/account/type');

  return Axios.get(url);
};

export const getUserAccountDocumentType = () => {
  const url = urlJoin(gatewayUrl, 'v1/user/account/document/type');

  return Axios.get(url);
};

export const postUserAccount = (data: UserAccount) => {
  const url = urlJoin(gatewayUrl, 'v1/user/account');

  return Axios.post(url, data);
};

export const postUserAccountDocument = (data: UploadFile) => {
  const url = urlJoin(gatewayUrl, 'v1/user/account/document');

  return Axios.post(url, data);
};

export const patchUserAccount = (
  token: string,
  id: string,
  data: PatchUserAccount,
) => {
  const url = urlJoin(gatewayUrl, `v1/user/account/${id}`);

  return Axios.patch(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getOrders = (
  token: string,
  accountId?: string,
  orderBy?: OrderBy,
  asc?: boolean,
  status?: string,
  search?: string,
  page?: number,
  size?: number,
) => {
  if (!orderBy) orderBy = 'created_at';

  const url = urlJoin(gatewayUrl, `v1/order`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-sub-account-id': accountId || '',
    },
    params: {
      account_id: accountId,
      order_by: orderBy,
      asc: asc,
      search: search,
      status: status,
      page: page,
      size: size,
    },
  });
};

export const getOrdersDetails = (token: string, id: string) => {
  const url = urlJoin(gatewayUrl, `v1/order/details/${id}`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createOrder = (token: string, data: CreateOrder) => {
  const url = urlJoin(gatewayUrl, 'v1/order');

  return Axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateUserPassword = (
  token: string,
  body: { password: string },
) => {
  const url = urlJoin(gatewayUrl, 'v1/user/password');

  return Axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateUserEmail = (token: string, body: { email: string }) => {
  const url = urlJoin(gatewayUrl, 'v1/user/email');

  return Axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserAccountFee = (token: string) => {
  const url = urlJoin(gatewayUrl, 'v1/user/account/fee');

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createUserAccountBank = (
  token: string,
  data: CreateUpdateAccountBank,
) => {
  const url = urlJoin(gatewayUrl, 'v1/user/account/bank');

  return Axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateUserAccountBank = (
  token: string,
  id: string,
  data: CreateUpdateAccountBank,
) => {
  const url = urlJoin(gatewayUrl, `v1/user/account/bank/${id}`);

  return Axios.patch(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserAccountBank = (token: string) => {
  const url = urlJoin(gatewayUrl, 'v1/user/account/bank');

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createUserAccountWallet = (
  token: string,
  data: CreateUpdateAccountWallet,
) => {
  const url = urlJoin(gatewayUrl, 'v1/user/account/wallet');

  return Axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateUserAccountWallet = (
  token: string,
  id: string,
  data: CreateUpdateAccountWallet,
) => {
  const url = urlJoin(gatewayUrl, `v1/user/account/wallet/${id}`);

  return Axios.patch(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserAccountWallet = (token: string) => {
  const url = urlJoin(gatewayUrl, 'v1/user/account/wallet');

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserAccountDocument = (token: string) => {
  const url = urlJoin(gatewayUrl, `v1/user/account/document`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDocumentURL = (token: string, documentId: string) => {
  const url = urlJoin(gatewayUrl, `v1/user/account/document/url/${documentId}`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createCustomerSupport = (token: string, data: CustomerSupport) => {
  const url = urlJoin(gatewayUrl, 'v1/notification/customer-support');

  return Axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getOrderBalance = (
  token: string,
  accountId: string,
  asset: string,
) => {
  const url = urlJoin(gatewayUrl, `v1/order/balance`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      account_id: accountId,
      asset,
    },
  });
};

export const getSettlementAvailableOrder = (token: string, asset: string) => {
  const url = urlJoin(gatewayUrl, `v1/settlement/available/order`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      asset,
    },
  });
};

export const getSettlementDetails = (token: string, settlementId: string) => {
  const url = urlJoin(gatewayUrl, `v1/settlement/id/${settlementId}`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postSettlement = (token: string, asset: string) => {
  const url = urlJoin(gatewayUrl, 'v1/settlement');

  return Axios.post(
    url,
    { asset },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getSettlement = (token: string) => {
  const url = urlJoin(gatewayUrl, 'v1/settlement');

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSettlementOrderBalance = (
  token: string,
  settlementId: string,
) => {
  const url = urlJoin(
    gatewayUrl,
    'v1/settlement/id',
    settlementId,
    '/order-balance',
  );

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAuthCredentials = (token: string) => {
  const url = urlJoin(gatewayUrl, 'v1/auth/credentials');

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createAuthCredentials = (token: string) => {
  const url = urlJoin(gatewayUrl, 'v1/auth/credentials');

  return Axios.post(url, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCallback = (token: string) => {
  const url = urlJoin(gatewayUrl, 'v1/callback');

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postCallback = (token: string, payload: TCallback) => {
  const url = urlJoin(gatewayUrl, 'v1/callback');

  return Axios.post(
    url,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const putCallback = (token: string, payload: TCallback) => {
  const url = urlJoin(gatewayUrl, 'v1/callback');

  return Axios.put(
    url,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const postQuote = (token: string, payload: TQuote) => {
  const url = urlJoin(gatewayUrl, 'v1/callback');

  return Axios.post(
    url,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getSubAccounts = (token: string, page?: number, size?: number) => {
  const url = urlJoin(gatewayUrl, `v1/subaccounts`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: page,
      size: size,
    },
  });
};

export const getSubAccountDetail = (token: string, id: string) => {
  const url = urlJoin(gatewayUrl, `v1/subaccount`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-sub-account-id': id,
    },
  });
};

export const getSubAccountByTaxIdNumber = (
  token: string,
  taxIdNumber: string,
) => {
  const url = urlJoin(gatewayUrl, `v1/subaccount/find`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      tax_id_number: taxIdNumber,
    },
  });
};

export const getAssetByType = (token: string, type: string) => {
  const url = urlJoin(gatewayUrl, `v1/asset`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      type: type,
    },
  });
};

export const getSubAccountBankByAccountID = (
  token: string,
  subAccountId: string,
  asset: string,
) => {
  const url = urlJoin(gatewayUrl, `v1/subaccount/bank-account`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-sub-account-id': subAccountId,
    },
    params: {
      asset: asset,
      bank_type: 'external',
    },
  });
};

export const postSubAccountBankExternal = (
  token: string,
  subAccountId: string,
  payload: TSubAccountBankAccountExternal,
) => {
  const url = urlJoin(gatewayUrl, 'v1/subaccount/bank-account/external');

  return Axios.post(
    url,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-sub-account-id': subAccountId,
      },
    },
  );
};

export const postSubAccountWalletAddressExternal = (
  token: string,
  subAccountId: string,
  payload: TSubAccountWalletAddressExternal,
) => {
  const url = urlJoin(gatewayUrl, 'v1/subaccount/wallet-address/external');

  return Axios.post(
    url,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-sub-account-id': subAccountId,
      },
    },
  );
};

export const deleteSubAccountBankExternal = (
  token: string,
  subAccountId: string,
  id: string,
) => {
  const url = urlJoin(gatewayUrl, `v1/subaccount/bank-account/id/${id}`);

  return Axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-sub-account-id': subAccountId,
    },
  });
};

export const deleteSubAccountWalletAddressExternal = (
  token: string,
  subAccountId: string,
  id: string,
) => {
  const url = urlJoin(gatewayUrl, `v1/subaccount/wallet-address/id/${id}`);

  return Axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-sub-account-id': subAccountId,
    },
  });
};

export const getUserAccountBalancePool = (token: string) => {
  const url = urlJoin(gatewayUrl, `v1/user/account/balance/pool`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getQuote = (token: string, payload: TQuoteV2) => {
  const url = urlJoin(gatewayUrl, `v1/quote`);

  return Axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postOrderExchange = (
  token: string,
  subAccountId: string,
  payload: TOrderExchange,
) => {
  const url = urlJoin(gatewayUrl, `v1/order/exchange`);

  return Axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-sub-account-id': subAccountId,
    },
  });
};

export const postOrderWithdrawal = (
  token: string,
  subAccountId: string,
  payload: TOrderWithdrawal,
) => {
  const url = urlJoin(gatewayUrl, `v1/order/withdrawal`);

  return Axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-sub-account-id': subAccountId,
    },
  });
};

export const postSubAccount = (token: string, payload: TSubAccountCreate) => {
  const url = urlJoin(gatewayUrl, `v1/subaccount`);

  return Axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBalanceAssets = (token: string, subAccountId: string) => {
  const url = urlJoin(gatewayUrl, `v1/balance/assets`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-sub-account-id': subAccountId,
    },
  });
};

export const getNetworkByAssetSymbol = (token: string, symbol: string) => {
  const url = urlJoin(gatewayUrl, `v1/network/asset/${symbol}`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSubAccountBankAccount = (
  token: string,
  subAccountId: string,
  asset: string,
  bankType: 'shared' | 'external',
) => {
  const url = urlJoin(gatewayUrl, `v1/subaccount/bank-account`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-sub-account-id': subAccountId,
    },
    params: {
      asset,
      bank_type: bankType,
    },
  });
};

export const getSubAccountWalletAddress = (
  token: string,
  subAccountId: string,
  asset: string,
  bankType: 'shared' | 'external',
) => {
  const url = urlJoin(gatewayUrl, `v1/subaccount/wallet-address`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-sub-account-id': subAccountId,
    },
    params: {
      asset,
      bank_type: bankType,
    },
  });
};

export const postSubAccountBankAccountShared = (
  token: string,
  subAccountId: string,
  asset: string,
) => {
  const url = urlJoin(gatewayUrl, `v1/subaccount/bank-account/shared`);

  return Axios.post(
    url,
    { asset },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-sub-account-id': subAccountId,
      },
    },
  );
};

export const postSubAccountWalletAddressShared = (
  token: string,
  subAccountId: string,
  asset: string,
  network: string,
) => {
  const url = urlJoin(gatewayUrl, `v1/subaccount/wallet-address/shared`);

  return Axios.post(
    url,
    { asset, network },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-sub-account-id': subAccountId,
      },
    },
  );
};

export const patchSubAccountInactive = (
  token: string,
  subAccountId: string,
) => {
  const url = urlJoin(gatewayUrl, `v1/subaccount/inactive`);

  return Axios.patch(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-sub-account-id': subAccountId,
      },
    },
  );
};

export const getAccountBalancePoolAddressByAsset = (
  token: string,
  asset: string,
) => {
  const url = urlJoin(
    gatewayUrl,
    `v1/user/account/balance/pool/address/${asset}`,
  );

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAsset = (token: string) => {
  const url = urlJoin(gatewayUrl, `v1/asset`);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPoolBalance = (token: string) => {
  const url = urlJoin(gatewayUrl, `v1/user/account/balance/pool `);

  return Axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
