import React, { FC } from 'react';
import './ProgressLine.scss';

type Props = {
  actual: number;
};

const ProgressLine: FC<Props> = ({ actual }: Props) => {
  return (
    <div className="progressLine">
      <div
        className={`progressLine__ball ${
          actual == 1 || actual > 0
            ? 'progressLine__ball--blue'
            : 'progressLine__ball--gray'
        }`}
      ></div>
      <div className="progressLine__line"></div>
      <div
        className={`progressLine__ball ${
          actual == 2 || actual > 1
            ? 'progressLine__ball--blue'
            : 'progressLine__ball--gray'
        }`}
      ></div>
      <div className="progressLine__line"></div>
      <div
        className={`progressLine__ball ${
          actual == 3 || actual > 2
            ? 'progressLine__ball--blue'
            : 'progressLine__ball--gray'
        }`}
      ></div>
    </div>
  );
};

export default ProgressLine;
