import React, { FC } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

type Props = {
  rows: any;
  columnsMobile: GridColDef[];
  columnsDesktop: GridColDef[];
  routeToDetails?: string;
}

const BoxGrid: FC<Props> = ({ rows, columnsMobile, columnsDesktop, routeToDetails }: Props) => {
  const matches = useMediaQuery('(max-width:1023px)');

  const navigate = useNavigate();

  const handleEvent: GridEventListener<'rowClick'> = (params) => {
    if (!matches || !routeToDetails) return;
    navigate(`${routeToDetails}${params.id}`);
  };

  return (
    <Box
      sx={{
        height: 520,
        width: '100%',
        '& .MuiDataGrid-cellContent': {
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '16px',
        },
        '& .super-app.pending .MuiDataGrid-cellContent': {
          padding: '7px 5px',
          borderRadius: '4px',
          width: '100%',
          textAlign: 'center',
          background: '#FFB381',
        },
        '& .super-app.canceled .MuiDataGrid-cellContent': {
          padding: '7px 5px',
          borderRadius: '4px',
          width: '100%',
          textAlign: 'center',
          background: '#EAEAEA',
        },
        '& .super-app.confirmed .MuiDataGrid-cellContent': {
          padding: '7px 5px',
          borderRadius: '4px',
          width: '100%',
          textAlign: 'center',
          background: '#F5F529',
        },
        '& .super-app.open .MuiDataGrid-cellContent': {
          padding: '7px 5px',
          borderRadius: '4px',
          width: '100%',
          textAlign: 'center',
          background: '#F9F9F9',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={matches ? columnsMobile : columnsDesktop}
        rowHeight={58}
        disableColumnMenu={true}
        onRowClick={handleEvent}
      />
    </Box>
  );
};

export default BoxGrid;
